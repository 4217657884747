import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";

import { loginUser } from "../../store/slices/AuthSlice";
import { getCart } from "../../store/slices/CardSlice";
import { Link } from "../../utils/util";
import { loginValidationSchema } from "../../validations/authValidations";
import Form from "../form/Form";

import "./login.css";

const UserLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const handleLoginSubmit = async (values) => {
    try {
      await dispatch(loginUser(values)).unwrap();
      dispatch(getCart());
      navigate("/");
    } catch (err) {
      toast.error("Invalid Username or Email and Password");
      console.error(err);
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const fields = [
    { name: "email", type: "email", label: "Email" },
    { name: "password", type: "password", label: "Password" },
  ];

  return (
    <div
      className="container-fluid d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="container d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <div className="login-box">
          <h1 className="text-center">
            <Link to="/">
              <img
                src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                style={{ width: "100px" }}
                alt="Company Logo"
              />
            </Link>
          </h1>

          <Form
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            fields={fields}
            onSubmitHandler={handleLoginSubmit}
            formClasses="signUpForm mb-3"
            loading={loading}
            submitText="Login"
            submittingText="Logging In"
          >
            <Link to="/forgot-password" className="forgot-password pt-4">
              Forgot Your Password?
            </Link>
          </Form>
          <div>
            <div className="text-center">Don't have an account?</div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <Link to="/SignUp" className="forSignup mt-4 p-2 border rounded">
                Create an account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
