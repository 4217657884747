import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function Footer() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="footer">
        <div className="container pb-3">
          <div className="row">
            
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="foot-logo pt-2">
                <div className="logos">
                  <Link to="/">
                    <img
                      src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                      style={{ width: "100px" }}
                      alt=""
                    />
                  </Link>
                </div>
                <a href="https://www.instagram.com/" target="_blank">
                  <div >
                   
                    <p>
                    Find us in social media as <br />
                      <a href="">
                        <b>@flooringdeals.ca</b>
                      </a>
                    </p>
                  </div>
                </a>
                <div className="social-media-icons">
      <a href="https://www.facebook.com/flooringdeals.ca" target="_blank" rel="noopener noreferrer">
        <i className="bi bi-facebook text-dark" style={{ marginRight: "10px" }}></i>
      </a>
      <a href="https://twitter.com/flooringdeals.ca" target="_blank" rel="noopener noreferrer">
        <i className="bi bi-twitter text-dark" style={{ marginRight: "10px" }}></i>
      </a>
      <a href="https://www.linkedin.com/company/flooringdeals.ca" target="_blank" rel="noopener noreferrer">
        <i className="bi bi-linkedin text-dark"></i>
      </a>
      
      <i style={{marginLeft:"10px"}} className="bi bi-instagram text-dark"></i>
     
    </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="row">
                <div className="col-sm-6 col-md-3 col-lg-3" style={windowWidth > 768 ? { marginLeft: '-75px' } : null}>
                  <ul className="inner-row">
                    <li className="pb-1">
                      <strong>Quick Links</strong>
                    </li>
                    <li className="time">
                      <Link
                        to="/"
                        onClick={(e) => {
                          scrollToTop();
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="time">
                      <Link
                        onClick={(e) => {
                          scrollToTop();
                        }}
                        to="/testimonials"
                      >
                        Testimonials
                      </Link>
                    </li>
                    <li className="time">
                      {" "}
                      <Link
                        className="text-start"
                        to="/costestimationInstallationPage"
                        onClick={(e) => {
                          scrollToTop();
                        }}
                      >
                        Installation Cost Calculator
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                  <ul className="inner-row">
                    <li className="pb-1">
                      <strong>Support</strong>
                    </li>
                    <li className="time">
                      <Link
                        to="/faq"
                        onClick={(e) => {
                          scrollToTop();
                        }}
                      >
                        FAQ
                      </Link>
                    </li>
                    <li className="time">
                      <Link
                        onClick={(e) => {
                          scrollToTop();
                        }}
                        to="/terms"
                      >
                        Terms of Service
                      </Link>
                    </li>
                    <li className="time">
                      {" "}
                      <Link
                        onClick={(e) => {
                          scrollToTop();
                        }}
                        to="/privacy"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                  <ul className="inner-row">
                    <li className="pb-1">
                      <strong>Company</strong>
                    </li>
                    <li className="time">
                      <a
                        href="/about"
                        onClick={(e) => {
                          scrollToTop();
                        }}
                      >
                        About us
                      </a>
                    </li>
                    <li className="time">
                      <Link
                        to="/contactUs"
                        onClick={(e) => {
                          scrollToTop();
                        }}
                      >
                        Contact us
                      </Link>
                    </li>
                    {/* <li className="time">
                      <a href="#!">Sitemap</a>
                    </li>
                    <li className="time">
                      <a href="#!">Virtual Showroom</a>
                    </li> */}
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                  <ul className="inner-row">
                    <li className="pb-1">
                      <strong>Contact</strong>
                    </li>
                    <li className="time">
                      {" "}
                      {/* <Link
                        to="/contact"
                        onClick={(e) => {
                          scrollToTop();
                        }}
                      >
                        Contact us
                      </Link> */}
                      <span>
                        <b>Phone:</b>(905)277-2227 <br />
                        <b>Fax:</b>(905)277-0227 <br />
                        <p>
                      customercare@flooringdeals.ca
                    </p>
                        
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3" >
              <div className="list inner-row">
                <ul>
                  <li className="pb-1">
                    <strong>Availability</strong>
                  </li>
                  {/* <li>
                    {" "}
                    <p>
                      Phone: (905) 277-2227 <br />
                      Fax : (905) 277-0227 <br />
                      Email: info@squarefootflooring.com
                    </p>
                  </li> */}
                  <li className="time">Mon to Friday: (09:00 AM - 06:00 PM)</li>
                  <li className="time">Sat: (10:00 AM - 05:00 PM)</li>
                  <li className="time"> Sunday: (Closed)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <div className="container pt-3 pb-3">
          <p className="text-center text-dark m-0 ">
            Copyright © 2024 Flooring Deals. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
