import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function SavedInstallation() {
  const { id } = useParams();
  console.log(id);
  const [remove_dis, setremove_dis] = useState([]);
  const [CostData, setCostData] = useState();
  const [FloorPrepration, setFloorPrepration] = useState([]);
  const [inData, setinData] = useState([]);
  const [removalTotal, setRemovalTotal] = useState(0);
  const [floorPrepTotal, setfloorPrepTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [showFootTotal, setShowFootTotal] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [totalsqfeet, setTotalsqfeet] = useState("");
  const uniqueIdentifier = localStorage.getItem("uniqueId");
  const [installSingleArr, setInstallSingleArr] = useState();
  const [installTotal, setInstallTotal] = useState(0);
  const [singleOrder, setSingleOrder] = useState([]);
  const [removeSingleData, setRemoveSingleData] = useState([]);
  const [floorPreSingleData, setfloorPreSingleData] = useState([]);
  const [installArr, setInstallArr] = useState([]);
  const [showRemove_Dis, setshowRemove_Dis] = useState(false);
  const [installationDropdown, setinstallationDropdown] = useState(false);
  const [removeDisIndex, setremoveDisIndex] = useState(0);
  const [FloorPremIndex, setFloorPremIndex] = useState(0);
  const [instalationIndex, setinstalationIndex] = useState(0);
  const [remove, setremove] = useState("");
  const [FloorPre, setFloorPre] = useState("");
  const [instalation, setinstalation] = useState("");
  const [IdforDeleteEstimateion, setIdforDeleteEstimateion] = useState();
  const [data, setData] = useState({
    identifier: uniqueIdentifier,
    firstName: "",
    lastName: "",
    city: "",
    orderItems: [],
    postalCode: "",
    mNo: "",
    email: "",
    totalPrice: "",
    totalSquareFeet: "",
    isComplete: false,
  });
  useEffect(() => {
    axios
      .get("https://allinonetables.com/api/cost/get")
      .then((res) => {
        const response = res.data;
        setCostData(response);
        const rem_dis = response.filter(
          (item) => item.name == "Removal & Disposal"
        );
        setremove_dis(rem_dis);
        console.log(16, rem_dis);
        const Floor_Prepration = response.filter(
          (item) => item.name == "Floor Prepration"
        );
        setFloorPrepration(Floor_Prepration);
        const installData = response.filter(
          (item) =>
            item.name != "Floor Prepration" && item.name != "Removal & Disposal"
        );
        setinData(installData);
        // console.log(installData)
      })
      .catch((err) => console.log(err));

    // generateUniqueIdentifier();
  }, []);
  const formatNumber = (value) => {
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) {
      // console.error("Invalid number");
      return null;
    }
    const hasDecimal = floatValue % 1 !== 0;
    const formattedValue = hasDecimal
      ? floatValue.toString()
      : floatValue.toFixed(2);
    return formattedValue;
  };
  useEffect(() => {
    axios
      .get(`https://allinonetables.com/api/InstallationOrder/user/${id}`)
      .then((res) => {
        const response = res.data[0];
        setIdforDeleteEstimateion(response._id)
        if (response) {
          setRemoveSingleData(response.orderItems[0].Removal_Disposal)
          setfloorPreSingleData(response.orderItems[1].floorPreparation)
          setInstallArr(response.orderItems[2].installation)
          
        }else{
          setShowFootTotal(true)
        }
       
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    let removeArrTotal = 0;
    let floorprepArrTotal = 0;
    let installArrTotal = 0;
    floorPreSingleData?.forEach((item) => {
      floorprepArrTotal += item.totalPrice;
    });
    removeSingleData?.forEach((item) => {
      removeArrTotal += item.totalPrice;
    });
    installArr?.forEach((item) => {
      installArrTotal += item.total;
    });
    setTotal(
      formatNumber(removeArrTotal + floorprepArrTotal + installArrTotal)
    );
    setData({
      ...data,
      totalPrice: removeArrTotal + floorprepArrTotal,
    });
  }, [floorPreSingleData, removeSingleData, installArr]);

  const getSingleCosts = (id) => {
    axios
      .get(`https://allinonetables.com/api/cost/get/single/${id}`)
      .then((res) => {
        const response = res.data;
        if (response[0].parentName === "Removal & Disposal") {
          setremove("")
          let isObjectPresent = removeSingleData.includes(response[0]);
          if (isObjectPresent) {
            toast.error("Alredy Present..");
            console.log(162, "present");
          } else {
            setRemoveSingleData((prevData) => [
              ...prevData,
              { ...response[0], totalPrice: response[0].price * totalsqfeet },
            ]);
          }
        } else {
          setfloorPreSingleData((prevData) => [
            ...prevData,
            { ...response[0], totalPrice: response[0].price * totalsqfeet },
          ]);
          setFloorPre("")
        }
      })
      .catch((err) => console.log(err));
  };

  const getSingleinstallCosts = (id) => {
    axios
      .get(`https://allinonetables.com/api/cost/get/${id}`)
      .then((res) => {
        const response = res.data;
        setInstallArr((prevData) => [
          ...prevData,
          {
            data: response,
            name: response[0].parentName,
            total: 0,
            singleprice: 0,
          }, // Corrected line
        ]);
      })
      .catch((err) => console.log(err));
  };

 
  const UpdateInstallObject = (id, totalsqft, parentIndex) => {
    const OneChield = installArr[parentIndex];
    const filter = OneChield.data.find((item) => item._id === id);
    const updatedArr = {
      ...OneChield,
      singleprice: filter.price,
      total: filter.price * totalsqft,
      selctedChield: filter.name,
    };
    const newArray = [...installArr];
    newArray[parentIndex] = updatedArr;
    setInstallArr(newArray);
  };
  const removeObj = (type, index) => {
    if (type == "Removal_Disposal") {
      const AllArr = [...removeSingleData];
      AllArr.splice(index, 1);
      setRemoveSingleData(AllArr);
    }
    if (type == "floorPrepration") {
      const AllArr = [...floorPreSingleData];
      AllArr.splice(index, 1);
      setfloorPreSingleData(AllArr);
    }
    if (type == "installation") {
      const AllArr = [...installArr];
      AllArr.splice(index, 1);
      setInstallArr(AllArr);
    }
  };
  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }


  const PlaceOrder = () => {
    const obj = {
      data: {
        ...data,
        orderItems: [...data.orderItems, ...orderItems],
      },
    };

    axios
      .post("https://allinonetables.com/api/InstallationOrder/create", obj.data)
      .then(() => {
        toast.success("Order Place Successfully...");
        Navigate("/Installation");
      })
      .catch((err) => console.log(err));
  
  };

const DeleteEstimation=(id)=>{
  console.log(242,id)
  axios
  .delete(`https://allinonetables.com/api/InstallationOrder/${id}`)
  .then(() => {
    toast.success("Order Deleted Successfully...");
    Navigate("/Installation");
  })
  .catch((err) => console.log(err));
}
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
            <div className="back-option pb-3">
              <a href="/costestimation">
                <i class="fa fa-arrow-left"></i> <u>Back</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="quote-summery-box-name-details"></div>
          <div className="quote-summery-heading">
            <h4>
              <strong>Saved Installation Cost Estimation</strong>
            </h4>
          </div>
        </div>
      </div>
      {/* =============================================================================================================================== */}
    
      <div className="row add-labour-items justify-content-center">
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-removal-disposal w-100 text-dark"
                  onChange={(e) => {
                    getSingleCosts(e.target.value);
                    setShowFootTotal(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Removal & Disposal</option>
                  {remove_dis[0]?.children.map((item) => {
                    return (
                      <>
                        <option value={item._id}>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-floor-preparation w-100"
                  onChange={(e) => {
                    getSingleCosts(e.target.value);
                    setShowFootTotal(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Floor Preparation</option>
                  {FloorPrepration[0]?.children.map((item) => {
                    return (
                      <>
                        <option value={item._id}>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-installation w-100"
                  onChange={(e) => {
                    getSingleinstallCosts(e.target.value);
                    setShowFootTotal(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Installation</option>
                  {inData?.map((item) => {
                    return (
                      <>
                        <option value={item._id}>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>

            {removeSingleData?.map((item, index) => {
              return (
                <>
                  <div className="row my-4">
                    <div className=" col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 col-lg-2 text-center text-md-start add-selection-name">
                          <p>Removal & Disposal</p>
                        </div>
                        <div className="col-md-5 col-lg-5 name-removal-disposal">
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.price} / sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.totalPrice)}</strong>
                          </p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="row justify-content-center">
                            <button
                              className="col-7 col-md-9 my-3 my-md-0"
                              onClick={(e) => {
                                setremoveDisIndex(index);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal1"
                              style={{ color: `inherit` }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {floorPreSingleData?.map((item, index) => {
              return (
                <>
                  <div className="row my-4">
                    <div className="col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 text-center text-md-start add-selection-name">
                          <p>Floor Preparation</p>
                        </div>
                        <div className="col-md-5 col-lg-5 name-removal-disposal">
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.price}/ sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.totalPrice)}</strong>
                          </p>
                        </div>
                        <div className="col-md-2 ">
                          <div className="row justify-content-center">
                            <button
                              className="col-7 col-md-9 my-3 my-md-0"
                              onClick={(e) => {
                                setFloorPremIndex(index);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal2"
                              style={{ color: `inherit` }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {installArr?.map((item, index) => {
              console.log(738, item);
              return (
                <>
                  <div className="row my-4">
                    <div className="col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 col-lg-2 text-center text-md-start add-selection-name">
                          <p>Installation</p>
                        </div>
                        <div className="col-md-3 col-lg-3  text-center text-md-start">
                          <p className="product-title-name">
                            {item.name}
                            {"   "}
                            {item.selctedChield ? item.selctedChield : ""}
                          </p>
                        </div>

                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center add-installation-product">
                            <select
                              className="w-100 w-sm-75 "
                              onChange={(e) => {
                                const selected = e.target.value;
                                UpdateInstallObject(
                                  selected,
                                  totalsqfeet,
                                  index
                                );
                              }}
                            >
                              {item.data?.map((item, indexx) => {
                                return (
                                  <>
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.singleprice}/ sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.total)}</strong>
                          </p>
                        </div>

                        <div className="col-md-2 col-lg-2">
                          <div className="row justify-content-center">
                            <button
                              className="col-7 col-md-9 my-3 my-md-0"
                              onClick={(e) => {
                                setinstalationIndex(index);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal3"
                              style={{ color: `inherit` }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {showFootTotal ? (
              <>
                <p class="text-center pt-5 pb-5 ">
                  No labour item added yet. Please Add from Above Option
                </p>
              </>
            ) : (
              <>
                <div className="row my-4">
                  <div className="col-11 col-md-10 m-auto">
                    <div className="row dislamer-total-price">
                      <div className="col-md-8 product-disclamer">
                        <p>
                          <strong>Disclamer:</strong>&nbsp;All prices estimated
                          here are subject to change without prior notice. While
                          we make every effort to provide you with the most
                          accurate and up-to-date information, it may be
                          possible that one or more items may be incorrectly
                          priced.
                        </p>
                      </div>
                      <div className="col-1 d-none d-md-block"></div>
                      <div className="col-md-1 total-price-cost-estimation-name">
                        <p>
                          <strong>Total</strong>
                        </p>
                      </div>
                      <div className="col-md-2 total-price-cost-estimation">
                        <p>
                          <strong>${formatPrice(parseInt(total))}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

      <div className="container">
        <div className="row justify-content-center align-items-center m-auto py-5">
          <div className="col-sm-3  my-2 my-md-0 text-center text-md-end">
            <div className="row justify-content-center">
              <div className=" prev-next-button m-auto justify-content-md-end">
                <button
                  className="col-11 m-auto bg-white savedinstallation-l"
               onClick={(e)=>{
DeleteEstimation(IdforDeleteEstimateion)
               }}
                >
                  Delete Your Estimation
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-2 my-md-0">
            <div className=" prev-next-button justify-content-center justify-content-md-start position-relative">
              <button
                className="col-11 text-white savedinstallation-r"
                onClick={(e) => {
                  PlaceOrder();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavedInstallation;
