import React, { useEffect, useState, useMemo } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LeftArrow from "../slicksliderAssets/left-arrow.svg";
import RightArrow from "../slicksliderAssets/right-arrow.svg";
import axios from "axios";
import url from "../Url";
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/util";

const BrandSlider = React.memo(({ brands }) => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img src={LeftArrow} alt="prevArrow" {...props} />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img src={RightArrow} alt="nextArrow" {...props} />
  );

  // Slider
  const settings = ({
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });

  return (
    <div className="flooring-products brands-for-small-screen catagory text-center">
      <div className="container">
        <h1 className="text-center">Brands</h1>
        <div className="text-center">
          <Link to="/brand" className="text-center brand-button">See All Brands</Link>
        </div>
        <div>
          <Slider {...settings} className="py-5 pe-3 ps-3">
            {brands && brands.map((brand) => {
              return (
                <div key={brand._id}>
                  <Link onClick={scrollToTop} to={`/brandProductPage/${brand._id}`}>
                    <div className="brand-logo">
                      <div className="layer1"></div>
                      <div className="layer2">
                        <img src={brand.brandLogo} alt={brand.brand_name} />
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
});

export default BrandSlider;
