import React, { useEffect } from "react";
import Home from "../components/Home";
import Abuotus from "../components/Abuotus";
import Contact from "../components/Contact";
import Product from "../components/flooring/Product";
import Faq from "../components/Faq";
import Brand from "../components/flooring/Brand";
import Favorite from "../components/flooring/Favorite";
import InstallationCostEstimation from "../components/Installation/InstallationCostEstimation";
import CostEstimation from "../components/flooring/CostEstimation";
import QuoteSummery from "../components/QuoteSummery";
import NewQuoteSummary from "../components/flooring/NewQuoteSummary";
import ProductDetail from "../components/flooring/ProductDetail";
import QuoteFinal from "../components/flooring/QuoteFinal";
import ThankYou from "../components/flooring/ThankYou";
import SavedInstallation from "../components/flooring/SavedInstallation";
import BrandProductPage from "../components/flooring/BrandProductPage";
import Testimonials from "../components/Testimonials";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";
import Ship from "../components/Ship";
import Guide from "../components/Guide";
import NoQuoteSummary from "../components/flooring/NoQuoteSummary";
import NoProductPage from "../components/flooring/NoProductPage";
import Profile from "../components/MyProfile/Profile";
import DiscountProductPage from "../components/flooring/DiscountProductPage";
import ClearenceProductPage from "../components/flooring/ClearenceProductPage";
import MonthSpecialProductPage from "../components/flooring/MonthSpecialProductPage";
import { Route, Routes } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../components/Footer";
import CheckoutContact from "../components/forCheckoutAndPayment/CheckoutContact";
import PaymentSuccess from "../components/forCheckoutAndPayment/PaymentSuccess";
import PaymentCancel from "../components/forCheckoutAndPayment/PaymentCancel";
import { useDispatch } from "react-redux";
import { getCart } from "../store/slices/CardSlice";
import { getFev } from "../store/slices/FavSlices";
import ContactUs from "../components/flooring/extraInfoPages/ContactUs";
import GetSamples from "../components/flooring/extraInfoPages/GetSamples";
import Moneybackguarantee from "../components/flooring/extraInfoPages/Moneybackguarantee";
import NotFound404 from "../components/NotFound404";
import OrderDetails from "../components/MyProfile/OrderDetails";

function LayoutRoutes() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCart());
    dispatch(getFev());
  }, []);
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<Abuotus />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/vinylwood" element={<Product />} />
        <Route exact path="/category/:id" element={<Product />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/brand" element={<Brand />} />
        <Route exact path="/favorite" element={<Favorite />} />
        <Route
          exact
          path="/costestimationInstallationPage"
          element={<InstallationCostEstimation />}
        />
        <Route exact path="/costestimation" element={<CostEstimation />} />
        <Route exact path="/quotesummary" element={<QuoteSummery />} />
        <Route exact path="/quotesummary/:id" element={<QuoteSummery />} />
        <Route exact path="/newQuoteSummary" element={<NewQuoteSummary />} />
        <Route exact path="/productdetail" element={<ProductDetail />} />
        <Route exact path="/QueatNumber" element={<QuoteFinal />} />
        <Route exact path="/Installation" element={<ThankYou />} />
        <Route
          exact
          path="/SavedInstallation/:id"
          element={<SavedInstallation />}
        />
        <Route
          exact
          path="/brandProductPage/:id"
          element={<BrandProductPage />}
        />
        <Route exact path="/productdetail/:slug" element={<ProductDetail />} />
        <Route exact path="/testimonials" element={<Testimonials />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/ship" element={<Ship />} />
        <Route exact path="/guide" element={<Guide />} />
        <Route exact path="/NoCard" element={<NoQuoteSummary />} />
        <Route exact path="/NoProduct" element={<NoProductPage />} />
        <Route exact path="/discountPage" element={<DiscountProductPage />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/profile/:id" element={<Profile />} />
        <Route exact path="/Checkout" element={<CheckoutContact />} />
        <Route exact path="/success" element={<PaymentSuccess />} />
        <Route exact path="/cancel" element={<PaymentCancel />} />
        <Route exact path="/clearencePage" element={<ClearenceProductPage />} />
        <Route
          exact
          path="/MonthSpecialPage"
          element={<MonthSpecialProductPage />}
        />
        <Route exact path="/contactUs" element={<ContactUs />} />
        <Route exact path="/getsamples" element={<GetSamples />} />
        <Route exact path="/getsamples" element={<GetSamples />} />
        <Route exact path="/moneyBack" element={<Moneybackguarantee />} />
        <Route exact path="/orderdetail" element={<OrderDetails />} />

        {}
        <Route path="*" element={<NotFound404 />} />
      </Routes>

      <Footer />
    </>
  );
}

export default LayoutRoutes;
