import React, { useState } from 'react';

const Pagination01 = ({ productLength,onNextPage,onPreviousPge,currentPage }) => {
    console.log(productLength,"quantity...")
    console.log(currentPage,"currentPage...")




  return (
    <div className="d-flex align-items-center justify-content-center my-3">
      <button
        className="pagination-btn page-link border p-2 px-3"
        style={{
          color: currentPage === 1 ? "gray" : "white",
          backgroundColor: currentPage === 1 ? "#cccccc" : "#e67929",
          borderRadius: "10px 0 0 10px",
          cursor: currentPage === 1 ? "not-allowed" : "pointer",
        }}
        onClick={onPreviousPge}
        disabled={currentPage === 1}
      >
        <span aria-hidden="true">Previous</span>
      </button>

      <div className="text-center mx-2" style={{ width: "3rem" }}>
        <span
          className="page-link num-click text-center"
          style={{
            color: "gray",
            border: "0",
            padding: "9px",
          }}
        >
          {`${currentPage}`}
        </span>
      </div>

      <button
        className="pagination-btn page-link border p-2 px-3"
        style={{
          color: productLength === 0 ? "gray" : "white",
          backgroundColor: productLength === 0 ? "#cccccc" : "#e67929",
          borderRadius: "0 10px 10px 0",
          cursor: productLength === 0 ? "not-allowed" : "pointer",
        }}
        onClick={onNextPage}
        disabled={productLength === 0}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination01;
