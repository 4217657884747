import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import AddQuoteSummaryBox from "./components/flooring/AddQuoteSummaryBox";
import ProductSearch from "./ProductSearch";
import "./components/Navbar.css";
import _ from "lodash";
import axios from "axios";
import "./productsearch.css";
import { useSelector } from "react-redux";
import Signup from "./components/LoginSignup/Signup";
import FreeSample from "./components/freeSample/FreeSample";
function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

function Navbar() {
  const triggerRef = useRef(null);
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const FevProduct = useSelector((state) => state.favroite);
  const cart = useSelector((state) => state.cart.data);
  const [cartNoUser, setCartNoUser] = useLocalStorage("cart", []); // Local storage cart for non-signed-in users
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const cartItems = userData ? cart : cartNoUser;
  // Throttle the handleChange function to be called at most once every 500 milliseconds
  const throttledHandleChange = _.throttle((inputValue) => {
    // Perform your logic here, like updating state or making API calls
    axios
      .get(
        `https://allinonetables.com/api/product/getBysearch?search=${inputValue}`
      )
      .then((res) => {
        if (inputValue === " ") {
          setData([]);
        } else {
          console.log(res?.data?.products, "searchdata");
          setData(res?.data?.products);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, 500);

  const handleChange = (event) => {
    setIsInputFocused(true);
    const inputValue = event.target.value;
    setValue(inputValue);
    // Call the throttled function
    if (inputValue === "") {
      setData([]);
    } else {
      throttledHandleChange(inputValue);
    }
  };
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };
  const handleRowClick = (slug) => {
    // Handle the click action, for example, navigate to the product detail page
    console.log(54, slug);
    navigate(`productdetail/${slug}`);
    // Hide the search suggestions
    setIsInputFocused(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [triggerRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.querySelector("body").addEventListener("click", handleInputFocus);

    return () => {
      document
        .querySelector("body")
        .removeEventListener("click", handleInputBlur);
    };
  }, []);
  // *************************************************
  useEffect(() => {
    // Enable Bootstrap tooltips
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach((tooltip) => {
      new window.bootstrap.Tooltip(tooltip);
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log("Received event:", event);
      if (event.detail === "cart") {
        const newCart = JSON.parse(localStorage.getItem("cart") || "[]");
        setCartNoUser(newCart);
        console.log("Cart updated from custom event:", newCart);
      }
    };

    window.addEventListener("onLocalStorageChange", handleStorageChange);
    return () =>
      window.removeEventListener("onLocalStorageChange", handleStorageChange);
  }, []);

  return (
    <div>
      {/* *********Top Header ***************/}
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="top">
                <p>
                  <Link to="/contactUs">
                    <i className="fa-sharp fa-solid fa-location-dot"></i>{" "}
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="phone">
                <p>
                  <a href="tel:905 277 2227">
                    <i className="fa-solid fa-phone"></i> (905) 277 2227
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**************** Second Header ***************/}

      <div className="second-header pt-2">
        <div className="container">
          <div className="row v-center pb-2 ">
            <div className="col-sm-12 col-md-1 col-lg-1">
              <div className="header-item item-left">
                <div className="logo">
                  <Link to="/">
                    <img
                      src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                      style={{ width: "100px" }}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-5 col-lg-5">
              <div className="header-item item-center py-1 py-md-1 ms-md-5 position-relative">
                <div className="searching">
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    name=""
                    ref={triggerRef}
                    value={value}
                    id=""
                    onChange={(e) => handleChange(e)}
                    placeholder="Search Your Product"
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                  />
                </div>
              </div>
              {isInputFocused && (
                <div className="container ms-md-5">
                  <div className="search-bar-for-search-option">
                    <div className="row">
                      <>
                        {data?.length > 0 ? (
                          <>
                            {data?.map((item) => {
                              return (
                                <>
                                  <div
                                    className="search-grid "
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      //  navigate(`productdetail/${item.item.slug}`);
                                      handleRowClick(item?.slug);
                                      //  setIsInputFocused(false);
                                    }}
                                  >
                                    <div className="imageSearchProduct mt-2">
                                      <img
                                        className="img-fluid rounded-4"
                                        src={item?.productPictures[0]}
                                        alt=""
                                      />
                                    </div>
                                    <div className=" d-flex align-item-center">
                                      <div className="discription-search mt-2">
                                        <p className="m-0 search-head">
                                          {item?.name}
                                        </p>
                                        {/* <p className="m-0 search-number">
                                          BYKRCHY50CA
                                        </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <p>No Product Match</p>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* ********************************************** */}

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="header-item item-right">
                {/* **************************** */}

                {/* ************************** */}
                {userData == undefined || userData === null ? (
                  <>
                    <Link
                      to="/login"
                      // data-bs-toggle="modal"
                      // data-bs-target="#loginSignupForm"
                      className="user"
                    >
                      <i className="bi bi-person"></i>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link className="userwithName" to="/profile">
                      <p className="m-0 ">
                        <i class="bi bi-person-fill-check pe-1"></i>
                        {userData.user.firstName}&nbsp;
                      </p>
                    </Link>
                  </>
                )}
                {/* ******************** After sign up *************** */}
                {/* ********************** */}
                <Link to="/profile/4">
                  <i className="bi bi-suit-heart"></i>
                  {/* <p> Favorites</p> */}
                  {FevProduct?.data?.length > 0 ? (
                    <>
                      <span>{FevProduct?.data?.length || 0}</span>
                    </>
                  ) : (
                    ""
                  )}
                </Link>

                {/* **************************** */}
                <Link to={`/quotesummary/${userData?.user?.email}`}>
                  <i class="bi bi-cart3"></i>
                  {/* <p>Quote Summary</p> */}
                  {cartItems?.length > 0 ? (
                    <>
                      <span>{cartItems?.length || 0}</span> <br />
                    </>
                  ) : (
                    ""
                  )}
                </Link>
                {/* <AddQuoteSummaryBox /> */}
                {/* <i class="bi bi-palette2"></i> */}
                {/* **************************** */}
                <Link to="/costestimationInstallationPage">
                  <i
                    className="bi bi-calculator"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="custom-tooltip"
                    title="Installation Calculator"
                  ></i>
                </Link>
                <Link
                  to=""
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <i
                    className="bi bi-columns"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="custom-tooltip"
                    title="Free Samples"
                  ></i>
                </Link>
                <FreeSample />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* **************************** Mune Nav  **************************/}
      <Header />
    </div>
  );
}
export default Navbar;
