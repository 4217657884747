import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./login.css";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [data, setData] = useState({
    email: "",
    newPassword: "",
    conformPass: "",
  });

  const [userData, setUserData] = useState(null); // Initialize userData as null

  useEffect(() => {
    const item = localStorage.getItem("user");
    const parsedUserData = JSON.parse(item);

    // Check if parsedUserData is not null before updating state
    if (parsedUserData && parsedUserData.user) {
      setUserData(parsedUserData);
    }
  }, []);

  const ChangePass = (e) => {
    e.preventDefault();
    console.log(data);
    if (data.newPassword != "" || data.conformPass != "")
      if (data.newPassword === data.conformPass) {
        axios
          .put(`https://allinonetables.com/api/user/updatePass`, data)
          .then((response) => {
            console.log(response.status, response.data);
            toast.success("Password Change Successfully....");
          })
          .catch((err) => {
            toast.error("Invalid Email or Password");
            console.log(err);
          });
      } else {
        toast.error("Password do not match");
      }
    else {
      toast.error("Please Fill All Fields");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <>
      <div className="forgetPassForm">
        <div className="form-container">
          <h1 className="heading-login text-center">
            <Link to="/">
              <img
                src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                style={{ width: "100px", margin: "20px auto" }}
                alt=""
              />
            </Link>
          </h1>
          <form className="forgot-password">
            <div className="text-center">
              <h3>Change Your Password</h3>
              <p>
                You can change your password for security reasons or reset it if
                you forget it.
              </p>
            </div>

            <label>Email Address</label>
            <input
              type="email"
              placeholder=""
              name="email"
              onChange={(e) => handleChange(e)}
              className="border LoginSignupInput"
            />

            <label>New password</label>
            <input
              type="password"
              placeholder=""
              name="newPassword"
              onChange={(e) => handleChange(e)}
              className="border LoginSignupInput"
            />
            <label>Confirm password</label>
            <input
              type="password"
              placeholder=""
              name="conformPass"
              onChange={(e) => handleChange(e)}
              className="border LoginSignupInput"
            />

            <button
              type="button"
              value="Submit"
              className="btn userSignupbtn w-100"
              onClick={(e) => {
                ChangePass(e);
              }}
            >
              Submit{" "}
            </button>

            <Link to="/login" className="forSignup mt-4">
              Back to Login
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
