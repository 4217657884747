import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import url from "../Url";
import "./Navbar.css";
import months from "../assets/images/collection/monthly.jpg";
import clear from "../assets/images/collection/clearance.jpg";
import discount from "../assets/images/collection/discounts.jpg";
import faqs from "../assets/images/icons/faq.png";
import shipping from "../assets/images/icons/delivery-truck.png";
import contact from "../assets/images/icons/telephone.png";
import about from "../assets/images/icons/shop.png";

import { useSelector } from "react-redux";
import ContactUs from "./flooring/extraInfoPages/ContactUs";
function Header() {
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [menuVisibility, setMenuVisibility] = useState([]);
  const [isInteractable, setIsInteractable] = useState(true); 
  const [submenuState, setSubmenuState] = useState({
    isVisible: false,
    canToggle: true,
  });
  const navigate = useNavigate();

  const handleMenuClick = (index) => {

    setMenuVisibility(prev => {
      const newState = {...prev}; // Spread into a new object
      Object.keys(newState).forEach(key => {
        newState[key] = false; // Close all menus
      });
      newState[index] = !prev[index]; // Toggle clicked menu
      return newState;
    });
  };

  


  
  

  let timeoutIds = {};
  let showTimeout;

  const handleMouseEnter = useCallback((index) => {
    // Optional: clear any hide timeout to keep submenu open if quickly re-entered
    clearTimeout(timeoutIds[index]);
  
    // Show submenu
    setMenuVisibility(prev => ({
        ...prev,
        [index]: true,
    }));
  }, []);


  const handleMouseLeave = useCallback((index) => {
    // Delay hiding submenu
    // const timeoutId = setTimeout(() => {
    //   setMenuVisibility(prev => ({
    //       ...prev,
    //       [index]: false,
    //   }));
    // }, 1000); // Delay in milliseconds
  
    // // Store timeoutId for potential clearTimeout on re-enter
    // timeoutIds[index] = timeoutId;
  }, []);


  // Defined outside of your component
  useEffect(() => {
    return () => {
      Object.values(timeoutIds).forEach(clearTimeout);
    };
  }, []);

  const menuRef = useRef(); // Create a ref for the menu

  useOutsideClick(menuRef, () => {
    setMenuVisibility({}); // Assuming this closes all menus, adjust as needed
  });
   // Hook to handle clicking on categories to close menuuseCatClick

  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, callback]);
  }

  // function useCatClick(ref, callback) {
  //   useEffect(() => {
  //     const handleCatClick = (event) => {
  //       if (event.target.closest('.category-link')) {
  //         callback();
  //       }
  //     };
  
  //     const menuElement = ref.current;
  //     menuElement.addEventListener('click', handleCatClick);
  
  //     return () => {
  //       menuElement.removeEventListener('click', handleCatClick);
  //     };
  //   }, [ref, callback]);
  // }
  
  
  

  

  const [mainCat, setMaincat] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/category/getCat`)
      .then((response) => {
        console.log(100, response.data.categoryList);
        console.log(7, mainCat);
        setMaincat(response.data.categoryList);
      })
      .catch((err) => {
        console.log(17, err);
      });
  }, []);

  const catNavigate = (id) => {

    console.log(17, "faq clicked try refresh");
    navigate(`/category/${id}`);
    
  };
  const homeNavigate = () => {
    setMenuVisibility({}); // This line hides all menus
    navigate(`/`);
  };
  const faqNavigate = () => {
    console.log(17, "faq clicked try refresh");
    navigate(`/faq`);
  };
  const shipNavigate = () => {

    navigate(`/ship`);
  };
  const contactNavigate = () => {

    navigate(`/contactUs`);
  };
  const aboutNavigate = () => {

    navigate(`/about`);
  };
  const brandNavigate = () => {
  
    navigate(`/brand`);
    window.location.reload();
  };
  const cartNavigate = () => {
    navigate(`/quotesummary/${userData?.user?.email}`, { replace: true });
  };
  const accountNavigate = () => {
    userData == undefined || userData === null ?
    navigate(`/login`):
    navigate(`/profile`)
  };
  const wishlistNavigate = () => {
    userData == undefined || userData === null ?
    navigate(`/login`):
    navigate(`/profile/4`);
  };
  const monthNavigate = () => {
 
    navigate(`/MonthSpecialPage`);
  };
  const clearNavigate = () => {

    navigate(`/clearencePage`);
  };
  const discountNavigate = () => {

    navigate(`/discoutPage`);
  };
  //   ************************************************ month name
  // Creating a date object
  var today = new Date();

  // Getting full month name (e.g. "June")
  var month = today.toLocaleString("default", { month: "long" });
  return (
    <>
      {/********************** Long screen navbar  ************************/}
      <header className="header">
        <div className="container">
          <div className="header-item item-center">
            <div className="menu-overlay"></div>

            <nav className="menu" ref={menuRef}>
              <ul className="menu-main">
                {mainCat.map((superCat, index) => (
                  <li key={superCat._id} className="menu-item-has-children"
                      >
                    <Link
                      onClick={(e) => handleMenuClick(index)}
                      
                    >
                      {superCat.name}
                    </Link>
                    <div
                     className={`sub-menu mega-menu mega-menu-column-4 ${menuVisibility[index] ? "visible" : "hidden"} `}>
                      {superCat.children.map((cat) => (
                        <div key={cat._id} className="list-item text-center">
                          <Link to={`/category/${cat._id}`}
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            catNavigate(cat._id); // Perform the navigation
                            setMenuVisibility({}); // Close all menus
                          }}
                          >
                            <div className="sub-menu-gradient">
                              <div className="gradient-overlay"></div>
                              <img
                                src={cat.categoryImage}
                                alt="Category"
                              />
                              <h4 className="title text-start">{cat.name}</h4>
                            </div>
                          </Link>

                          <ul className="subset">
                            {cat.children.map((subCat) => (
                              <li key={subCat._id}>
                    
                                <Link to={`/category/${subCat._id}`}
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent the default link behavior
                                  catNavigate(subCat._id); // Perform the navigation
                                  setMenuVisibility({}); // Close all menus
                                }}
                                >  
                                  {subCat.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </li>
                ))}

                <li className="menu-item-has-children"
                >
                  <Link onClick={() => handleMenuClick(mainCat.length)} >Promotions</Link>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${menuVisibility[3] ? "visible" : "hidden"}`}>
                    <div className="list-item text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        clearNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <div className="sub-menu-gradient">
                          <div className="gradient-overlay"></div>
                          <img src={clear} alt="new Product" />

                          <h4 className="title text-start">
                            Clearance <br /> Sale{" "}
                            <i className="bi bi-exclamation-circle-fill"></i>
                          </h4>
                        </div>
                      </Link>
                    </div>
                    <div className="list-item text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        discountNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <div className="sub-menu-gradient">
                          <div className="gradient-overlay"></div>
                          <img src={discount} alt="new Product" />
                          <h4 className="title text-start">
                            Discount <br /> Sale{" "}
                            <i className="bi bi-exclamation-circle-fill"></i>
                          </h4>
                        </div>
                      </Link>
                    </div>
                    <div className="list-item text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        monthNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <div className="sub-menu-gradient">
                          <div className="gradient-overlay"></div>
                          <img src={months} alt="new Product" />
                          <h4 className="title text-start">
                            {month} <br />
                            Special{" "}
                            <i className="bi bi-exclamation-circle-fill"></i>
                          </h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>

                <li className="menu-item-has-children"
                >
                  <Link onClick={() => handleMenuClick(mainCat.length + 1)}>Support</Link>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${menuVisibility[4] ? "visible" : "hidden"}`}>
                    <div className="list-item support-circle text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        faqNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <img className="small" src={faqs} alt="new Product" />
                        <h3 className="title text-center">FAQ</h3>
                      </Link>
                    </div>
                    <div className="list-item support-circle text-center">
                    <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        shipNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <img
                          className="small"
                          src={shipping}
                          alt="new Product"
                        />
                        <h3 className="title text-center">Shipping Info</h3>
                      </Link>
                    </div>
                    <div className="list-item support-circle text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        contactNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                      
                        <img
                          className="small"
                          src={contact}
                          alt="new Product"
                        />
                        <h3 className="title text-center">Contact Us</h3>
                      </Link>
                    </div>
                    <div className="list-item support-circle text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        aboutNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <img className="small" src={about} alt="new Product" />
                        <h3 className="title text-center">About US</h3>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <Link 
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default link behavior
                    brandNavigate(); // Navigation function for the clearance page
                    setMenuVisibility({}); // Close all menus
                  }}
                  >Brands</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      {/* {/ *********************************************************** Responsive Navbar  *************************************************************************/}
      <nav className="navbar navbar-dark responsive-nav-for-sm-screen bg-color1 mobile-navbar-spacing" >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
            aria-label="Toggle navigation"
            style={{ borderColor: 'white'}}
            
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="offcanvas offcanvas-start bg-color-for-canvas"
            style={{ width: '85%' }} 
            tabIndex="-1"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div className="offcanvas-header">
            <div className="logo">
                  <Link to="/">
                    <img
                      src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                      style={{ width: "100px" }}
                      alt=""
                    />
                  </Link>
                </div>
              <button
                type="button"
                className="btn-close btn-close-dark"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
             
            </div>
         <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
         <div style={{width:"97%",height:"2px",backgroundColor:"#D3D3D3"}}></div>
         </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-1">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/"
                    onClick={() => homeNavigate()}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Home
                  </Link>
                </li>

                {/* {/ **********************   Flooring ********************/}

                {mainCat.map((superCat, index) => (
                  <li key={superCat._id} className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={`/category/${superCat._id}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => handleMenuClick(index)}
                    >
                      {superCat.name}
                    </Link>
                    <ul className="dropdown-menu bg-color text-start" style={{ border: 'none' }}>
                      {superCat.children.map((cat) => (
                        <div key={cat._id}>
                          <h4>
                            <Link
                              className="text-dark ps-2"
                              to={`/category/${cat._id}`}
                              onClick={() => catNavigate(cat._id)}
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              <span style={{ fontSize: window.innerWidth < 768 ? '15px' : 'inherit', fontWeight: 'bold', marginLeft: '-7px' }}>{cat.name}</span>
                              {/* {cat.name} */}
                            </Link>
                          </h4>
                          {cat.children.map((subCat) => (
                            <li key={subCat._id} style={{ marginLeft: '-13px' }}>
                              <Link
                                // to={`/category/${subCat._id}`}
                                className="dropdown-item"
                                
                                onClick={() => catNavigate(subCat._id)}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                <span style={{ fontSize: window.innerWidth < 768 ? '14px' : 'inherit' }}>{subCat.name}</span>
                                {/* {subCat.name} */}
                              </Link>
                            </li>
                          ))}
                          <hr />
                        </div>
                      ))}
                    </ul>
                  </li>
                ))}

                {/* {/ ******************************  Promotions  **************************/}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Promotions
                  </a>
                  <ul className="dropdown-menu bg-color text-start ps-2" style={{ border: 'none' }}>
                    <li style={{ marginLeft: '-23px' }}>
                      <Link
                        to="/clearencePage"
                        className="dropdown-item"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => clearNavigate()}
                        style={{ fontSize: '15px', fontWeight: 'bold' }} 
                      >
                        Clearance Sale{" "}
                        <i className="bi bi-exclamation-circle-fill"></i>
                      </Link>
                    </li>
                    <hr />
                    <li style={{ marginLeft: '-23px' }}>
                      <Link
                        to="/discountPage"
                        className="dropdown-item"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => discountNavigate()}
                        style={{ fontSize: '15px', fontWeight: 'bold' }} 
                      >
                        Discount Sale{" "}
                        <i className="bi bi-exclamation-circle-fill"></i>
                      </Link>
                    </li>
                    <hr />
                    <li style={{ marginLeft: '-23px' }}>
                      <Link
                        to="/MonthSpecialPage"
                        className="dropdown-item"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => monthNavigate()}
                        style={{ fontSize: '15px', fontWeight: 'bold' }} 
                      >
                        {month} Special{" "}
                        <i className="bi bi-exclamation-circle-fill"></i>
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* {/ *********************************** Support **************************/}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Support
                  </a>
                  <ul className="dropdown-menu bg-color text-start ps-2" style={{ border: 'none' }}>
                    <li style={{ marginLeft: '-23px' }}>
                      <Link
                        to="/faq"
                        className="dropdown-item"
                        onClick={() => faqNavigate()}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        style={{ fontSize: '15px', fontWeight: 'bold' }} 
                      >
                        FAQ
                      </Link>
                    </li>

                    <li style={{ marginLeft: '-23px' }}>
                      <Link
                        to="/ship"
                        className="dropdown-item"
                        onClick={() => shipNavigate()}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        style={{ fontSize: '15px', fontWeight: 'bold' }} 
                      >
                        Shipping Info
                      </Link>
                    </li>

                    <li style={{ marginLeft: '-23px' }}>
                      <Link
                        to="/contactUs"
                        className="dropdown-item"
                        onClick={() => contactNavigate()}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        style={{ fontSize: '15px', fontWeight: 'bold' }} 
                      >
                        Contact Us
                      </Link>
                    </li>

                    <li style={{ marginLeft: '-23px' }}>
                      <Link
                        to="/about"
                        className="dropdown-item"
                        onClick={() => aboutNavigate()}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        style={{ fontSize: '15px', fontWeight: 'bold' }} 
                      >
                        About US
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* {/ ************************* Brand  ********************************/}
                <li className="nav-item">
                  <Link
                    to="/brand"
                    className="nav-link"
                    onClick={() => brandNavigate()}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Brand
                  </Link>
                </li>
                <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"5%"}}>
         <div style={{width:"97%",height:"2px",backgroundColor:"#D3D3D3"}}></div>
         </div>
         <li className="nav-item">
                  <Link
                  to={`/quotesummary/${userData?.user?.email}`}
                    className="nav-link"
                    onClick={() => cartNavigate()}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cart
                  </Link>
                  <Link
                    to="/profile"
                    className="nav-link"
                    onClick={() => accountNavigate()}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    My Account
                  </Link>
                </li>
                <Link
                    to="/profile/4"
                    className="nav-link"
                    onClick={() => wishlistNavigate()}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Wish List
                  </Link>
              </ul>
           
            </div>
         
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
