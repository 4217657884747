import React from "react";

function ContactUs() {
  return (
    <>
      {/* ************************ breadcrumb ******************** */}
      <br />
      <br />

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Contact Us</li>
          </ul>
        </div>
      </div>

      {/* ************************* */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-23 col-md-12 col-lg-6 col-xl-6 d-flex align-items-center">
            <div className="infoForBestPrice ">
              <div>
                <h1>
                  <b>Need extra help?</b>
                </h1>
                <p>
                  Feel free to contact Flooring Deals’s customer service team by email
                  or phone. We’d be happy to answer your questions or provide
                  any further information you might need.
                </p>
                <p className="fs-6">
                  <b>
                    Phone : <span className="myColor">905-277-2227</span>
                  </b>
                </p>
                <p className="fs-6">
                  <b>
                    Email :
                    <span className="myColor"> customercare@flooringdeals.ca</span>
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-23 col-md-12 col-lg-6 col-xl-6">
            <div className="extraHelpMap p-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.5664716747197!2d-79.60155462408675!3d43.59474565620994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b47a5c3aa890b%3A0x30a1107a89668824!2s700%20Dundas%20St%20E%2C%20Mississauga%2C%20ON%20L4Y%203Y5%2C%20Canada!5e0!3m2!1sen!2sin!4v1696328212205!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
