import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "../components/flooring/product.css";
import axios from "axios";
import url from "../Url";
import { toast } from "react-toastify";
import { getFev } from "../store/slices/FavSlices";
import { useDispatch, useSelector } from "react-redux";
import {
  //WOOD ALL

  menuItemsLaminateColor,
  menuItemsDensity,
  // menuItemsFinish,
  menuItemsInstallation,
  menuItemsMaterial,
  menuItemsOnces,
  menuItemsShape,
  // menuItemsSpecies,
  // menuItemsSurfaceTexture,
  menuItemsThickness,
  menuItemslength,
  menuItemswidth,
  menuItemsBrandTile,
  menuItemsBrandVinyl,
  menuItemsBrandWoodFloor,
  menuItemsBrandLaminate,
  // menuItemsFormatTile,
  menuItemsCollectionAll,
  menuItemsCollectionLaminate,
  menuItemsCollectionVynl,
  menuItemsCollectionTile,
  menuItemsCollectionWood,
  menuItemsWoodColor,
  menuItemsWoodSpecies,
  menuItemsWoodThickness,
  menuItemsWoodWidth,
  menuItemsWoodFinish,
  menuItemsWoodSurfaceTexture,
  menuItemsVinylProductType,
  menuItemsVinylColor,
  menuItemsVinylThickness,
  menuItemsVinylWearLayerThickness,
  menuItemsVinylLength,
  menuItemsVinylUnderpadAttached,
  menuItemsACDurabilityRating,
  menuItemsWaterResistance,
  menuItemsLaminateSurfaceTexture,
  menuItemsLaminateThickness,
  menuItemsLaminateWidth,
  menuItemsVinylWidth,
  menuItemsLaminateLength,
  menuItemsLaminateFinish,
  menuItemsWallTileSize,
  menuItemsTileShape,
  menuItemsTileLook,
  menuItemsTileFinish,
  menuItemsTileMaterial,
  menuItemsTilePEIRating,
  menuItemsTileEdgeType,
  menuItemsCarpetColor,
  menuItemsBrandCarpet,
  menuItemsCarpetSize,
  menuItemsCarpetMaterial,
  menuItemsCarpetThickness,
  menuItemsCarpetPileWeight,
  menuItemsBrandCorkFlooring,
  menuItemsUnderLayThicknessMM,
  menuItemsCarpetLinearDensity,
  menuItemsCarpetPileConstructionMethod,
  menuItemsCarpetOunces,
  menuItemsCarpetDensity,
  menuItemsBrandSportFlooring,
  menuItemsSportMaterial,
  menuItemsSportInstallation,
  menuItemsMembranesCoverage,
  menuItemsMembranesOnlyThicknessMM,
  menuItemsMembranesWaterproofVolume,
  menuItemsMembranesThicknessFraction,
  menuItemsMembranesOnyHeatType,
  menuItemsMembranesPlywoodFormat,
  menuItemsMembranesPlywoodThickness,
  menuItemsUnderLayCoverage,
  menuItemsUnderLayVaporBarrier,
  menuItemsUnderLayCarpetCoverageYard,
  menuItemsTileSettingMaterialVolume,
  menuItemsTileSettingMaterialWeight,
  menuItemsAccessoriesColor,
  menuItemsMoldingMaterial,
  menuItemsFloorVentMaterial,
  AccessoriesWallBaseLength,
  AccessoriesMoldingLength,
  AccessoriesWallBaseMaterial,
  menuItemsFloorPatchWeight,
  menuItemsFloorPatchVolume,
  menuItemsAdhesivesvolumeML,
  menuItemsAdhesivesvolume,
  menuItemsTileSettingMaterialColor,
  AccessoriesWallBaseHeight,
  AccessoriesMoldingHeightInch,
  menuItemsMoldingFinish,
  menuItemsFloorVentFormat,
} from "../FilterData/Data";

function SidebarFilters({
  onFilterChange,
  applyFunc,
  clearFunc,
  resetPrice,
  getMaxPrice,
  getMinPrice,
  maxPrice,
  minPrice,
  catDetails,
}) {
  console.log(catDetails, "Sese");
  const [selectedItems, setSelectedItems] = useState([]);
  const handleChange = (key, value) => {
    let index = selectedItems?.findIndex(
      (f) => f?.type === key && f?.value === value
    );
    if (index === -1) {
      let temp = [...selectedItems, { type: key, value }];
      setSelectedItems(temp);
      onFilterChange(
        key,
        temp
          ?.filter((f) => f?.type === key)
          ?.map((item) => item?.value)
          ?.join(",")
      );
    } else {
      let temp = selectedItems?.filter(
        (f) => f?.type === key && f?.value !== value
      );
      setSelectedItems(temp);
      onFilterChange(
        key,
        temp
          ?.filter((f) => f?.type === key)
          ?.map((item) => item?.value)
          ?.join(",")
      );
    }
  };
  const availCheck = (key, value) => {
    let index = selectedItems?.findIndex(
      (f) => f?.type === key && f?.value === value
    );
    if (index === -1) return false;
    else return true;
  };
  const { id } = useParams();
  const dispatch = useDispatch();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);

  const [Product, setProduct] = useState([]);
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [CatDetails, setCatDetails] = useState([]);
  const [SingleCat, setSingleCat] = useState([]);
  //States for Filter
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedSpecies, setSelectedSpecies] = useState([]);
  const [selectedInstallation, setSelectedInstallation] = useState([]);
  const [selectedThickness, setSelectedThickness] = useState([]);
  const [selectedWidth, setSelectedWidth] = useState([]);
  const [selectedLength, setSelectedLength] = useState([]);

  const [selectedFinish, setSelectedFinish] = useState([]);
  const [selectedLaminateFinish, setSelectedLaminateFinish] = useState([]);
  const [selectedVinylProductType, setSelectedVinylProductType] = useState([]);

  const [selectSurface_Texture, setSelectSurface_Texture] = useState([]);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [selectOnces, setSelectOnces] = useState([]);
  const [selectDensity, setSelectDensity] = useState([]);
  const [selectShape, setSelectShape] = useState([]);
  const [selectEdgeType, setSelectEdgeType] = useState([]);
  const [selectLook, setSelectLook] = useState([]);
  const [selectPEIRating, setSelectPEIRating] = useState([]);
  const [selectcovrage, setSelectCovrage] = useState([]);
  const [selectVolume, setSelectVolume] = useState([]);
  const [selectFormat, setSelectFormat] = useState([]);
  const [selectApproximate, setselectApproximate] = useState([]);
  const [selectAC, setselectAC] = useState([]);
  const [selectedSurfaceTexture, setSelectedSurfaceTexture] = useState([]);
  const [selectedWereLayerThickness, setSelectedWereLayerThicknes] = useState(
    []
  );

  const cardsPerPage = 20;

  const [showReset, setShowReset] = useState(true);
  const [ParamsCatName, setParamsCatName] = useState("");
  const [page, setPage] = useState(1);
  const [selectedSortingOption, setSelectedSortingOption] = useState("");

  //******************** What Cat is showing now **************************************************
  const [currentShownCategoryName, setCurrentShownCategoryName] = useState("");
  const [currentShownSubCategoryName, setCurrentShownSubCategoryName] =
    useState("");

  //******************** Api for Wood Filter Options **************************************************
  const [currentWoodBrandList, setCurrentWoodBrandList] =
    useState(menuItemsBrandTile);
  const [currentWoodSpeciesList, setCurrentWoodSpeciesList] =
    useState(menuItemsWoodSpecies);

  const [currentWoodThicknessList, setCurrentWoodThicknessList] = useState(
    menuItemsWoodThickness
  );
  const [currentWoodWidthList, setCurrentWoodWidthList] =
    useState(menuItemsWoodWidth);
  const [currentWoodFinishList, setCurrentWoodFinishList] = useState([]);
  // Add more as needed for other attributes
  //******************** Api for Vinyl Filter Options **************************************************
  const [currentVinylProductTypeList, setCurrentVinylProductTypeList] =
    useState(menuItemsVinylProductType);
  const [
    currentVinylWearLayerThicknessList,
    setCurrentVinylWearLayerThicknessList,
  ] = useState(menuItemsVinylWearLayerThickness);
  //******************** Laminate Filter Options **************************************************

  //******************** Tile Filter Options **************************************************
  const [currentTileSizeList, setCurrentTileSizeList] = useState([]);
  const [currentTileShapeList, setCurrentTileShapeList] = useState([]);
  const [currentTileLookList, setCurrentTileLookList] = useState([]);
  const [currentTileFinishList, setCurrentTileFinishList] = useState([]);
  const [currentTileMaterialList, setCurrentTileMaterialList] = useState([]);
  const [currentTilePEIRatingList, setCurrentTilePEIRatingList] = useState([]);
  const [currentTileEdgeTypeList, setCurrentTileEdgeTypeList] = useState([]);

  //******************** Api for brand name **************************************************
  const [Brand, setBrand] = useState([]);
  const [WoodSHoFilter, setWoodSHoFilter] = useState(false);
  const [VinylSHoFilter, setVinylSHoFilter] = useState(false);
  const [LaminateSHoFilter, setLaminateSHoFilter] = useState(false);
  const [CarpetSHoFilter, setCarpetSHoFilter] = useState(false);
  const [SportFloorSHoFilter, setSportFloorSHoFilter] = useState(false);

  const [tileSHoFilter, setTileSHoFilter] = useState(false);

  // ********************* Api for Collection name *********************************************
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const [getProdByCat, setgetProdByCat] = useState("");
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  // const [minPrice, setMinPrice] = useState("");
  // const [maxPrice, setMaxPrice] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [proLength, setproLength] = useState([]);

  //to make sure the count only fetch once
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  //start fetching
  const [colorFilterNameCounts, setColorFilterNameCounts] = useState({});
  const [woodSpeciesCounts, setWoodSpeciesCounts] = useState({});
  const [woodThicknessInchCounts, setWoodThicknessInchCounts] = useState({});
  const [woodWidthCounts, setWoodWidthCounts] = useState({});
  const [woodFinishCounts, setWoodFinishCounts] = useState({});
  const [woodSurfaceTextureCounts, setWoodSurfaceTextureCounts] = useState({});
  const [vinylInstallationMethodCounts, setVinylInstallationMethodCounts] =
    useState({});
  const [vinylThicknessCounts, setVinylThicknessCounts] = useState({});
  const [vinylWearLayerThicknessCounts, setVinylWearLayerThicknessCounts] =
    useState({});
  const [vinylWidthInchCounts, setVinylWidthInchCounts] = useState({});
  const [vinylLengthCounts, setVinylLengthCounts] = useState({});
  const [vinylUnderpadCounts, setVinylUnderpadCounts] = useState({});

  const [laminateACCounts, setLaminateACCounts] = useState({});
  const [laminateThicknessMMCounts, setLaminateThicknessMMCounts] = useState(
    {}
  );
  const [laminateWaterResisCounts, setLaminateWaterResisCounts] = useState({});
  const [laminateSurfaceTextureCounts, setLaminateSurfaceTextureCounts] =
    useState({});
  const [laminateWidthCounts, setLaminateWidthCounts] = useState({});
  const [laminateLengthCounts, setLaminateLengthCounts] = useState({});
  const [laminateFinishCounts, setLaminateFinishCounts] = useState({});

  const [tileSizeCounts, setTileSizeCounts] = useState({});
  const [tileShapeCounts, setTileShapeCounts] = useState({});
  const [tileLookCounts, setTileLookCounts] = useState({});
  const [tileFinishCounts, setTileFinishCounts] = useState({});
  const [tileMaterialCounts, setTileMaterialCounts] = useState({});
  const [tilePEICounts, setTilePEICounts] = useState({});
  const [tileEdgeTypeCounts, setTileEdgeTypeCounts] = useState({});
  const [corkFloorThicknessMMCounts, setCorkFloorThicknessMMCounts] = useState(
    {}
  );
  const [corkFloorWidthCounts, setCorkFloorWidthCounts] = useState({});
  const [corkFloorLengthCounts, setCorkFloorLengthCounts] = useState({});
  const [corkFloorSizeCounts, setCorkFloorSizeCounts] = useState({});
  const [carpetFiberMaterialCounts, setCarpetFiberMaterialCounts] = useState(
    {}
  );
  const [carpetThicknessCounts, setCarpetThicknessCounts] = useState({});
  const [carpetWeightCounts, setCarpetWeightCounts] = useState({});
  const [carpetLinearDensityCounts, setCarpetLinearDensityCounts] = useState(
    {}
  );
  const [carpetPileMethodCounts, setCarpetPileMethodCounts] = useState({});
  const [carpetOncesCounts, setCarpetOncesCounts] = useState({});
  const [carpetDensityCounts, setCarpetDensityCounts] = useState({});
  const [sportFloorSizeCounts, setSportFloorSizeCounts] = useState({});
  const [sportFloorThicknessCounts, setSportFloorThicknessCounts] = useState(
    {}
  );
  const [sportFloorMaterialCounts, setSportFloorMaterialCounts] = useState({});
  const [sportFloorOncesCounts, setSportFloorOncesCounts] = useState({});
  const [sportFloorDensityCounts, setSportFloorDensityCounts] = useState({});
  const [sportInstallationMethodCounts, setSportInstallationMethodCounts] =
    useState({});
  const [membranesCoverageCounts, setMembranesCoverageCounts] = useState({});
  const [membranesThicknessMMCounts, setMembranesThicknessMMCounts] = useState(
    {}
  );
  const [membranesThicknessInchCounts, setMembranesThicknessInchCounts] =
    useState({});
  const [membranesHeatCounts, setMembranesHeatCounts] = useState({});
  const [membranesVolumeLCounts, setMembranesVolumeLCounts] = useState({});
  const [membranesFormatCounts, setMembranesFormatCounts] = useState({});
  const [membranesThicknessInchCounts2, setMembranesThicknessInchCounts2] =
    useState({});
  const [underlaymentsCoverageCounts, setUnderlaymentsCoverageCounts] =
    useState({});
  const [underlayCoverageSqYdCounts, setUnderlayCoverageSqYdCounts] = useState(
    {}
  );
  const [underThicknessMMCounts, setUnderThicknessMMCounts] = useState({});
  const [underVaporCounts, setUnderVaporCounts] = useState({});
  const [settingMaterialWeightCounts, setSettingMaterialWeightCounts] =
    useState({});
  const [settingMaterialVolumeLCounts, setSettingMaterialVolumeLCounts] =
    useState({});
  const [settingMaterialWeightLbCounts, setSettingMaterialWeightLbCounts] =
    useState({});
  const [patchVolumeLCounts, setPatchVolumeLCounts] = useState({});
  const [adhesivesVolumeLCounts, setAdhesivesVolumeLCounts] = useState({});
  const [adhesivesVolumeMLCounts, setAdhesivesVolumeMLCounts] = useState({});
  const [accessoriesMaterialCounts, setAccessoriesMaterialCounts] = useState(
    {}
  );
  const [accessoriesLengthCounts, setAccessoriesLengthCounts] = useState({});
  const [accessoriesHeightCounts, setAccessoriesHeightCounts] = useState({});
  const [accessoriesFinishCounts, setAccessoriesFinishCounts] = useState({});
  const [accessoriesFormatCounts, setAccessoriesFormatCounts] = useState({});
  const [productCollectionCounts, setProductCollectionCounts] = useState({});

  const fevProduct = useSelector((state) => state.favroite.data);
  const currentAccessoryMaterialListLength = [];
  // Assuming allProducts is already populated with your products
  const [uniqueCollections, setUniqueCollections] = useState([]);

  // ********************* filter option check names match the dictionary name/mongodb name *********************************************
  const [filters, setFilters] = useState({
    name: "",
    cat: "",
    sub_cat: "",
    minprice: "",
    maxprice: "",
    color_name: "",
    BrandName: "",
    length_cm: "",
    Collection_Name: "",
    installation_method: "",
    thickness: "",
    widthinch: "",
    lengthinch: "",
    wearlayerthickness: "",
    Species: "",
    Finish: "",
    Surface_Texture: "",
    ApproximateSize: "",
    Material: "",
    Onces: "",
    Density: "",
    Shape: "",
    edgeType: "",
    PEI_Rating: "",
    covrage: "",
    volime: "",
    ac: "",
  });

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 200,
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       console.log("Fetching data with filters:", filters);

  //       // Fetch data for the current page
  //       const response = await axios.get(`${url}/api/product/getAll`, {
  //         params: {
  //           ...filters,
  //           page: currentPage,
  //           limit: pagination.limit,
  //         },
  //       });
  //       const products = response.data.data;
  //       console.log("Fetched products count:", products.length);
  //       const sortedProducts = products.sort(
  //         (a, b) => a.sale_price - b.sale_price
  //       );

  //       setProduct(products);
  //       setFetchedProduct(sortedProducts);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }

  //     try {
  //       // Fetch additional data (e.g., product length)
  //       const responseAllData = await axios.get(
  //         `https://allinonetables.com/api/product/getAll/productlength`,
  //         {
  //           params: {
  //             ...filters,
  //           },
  //         }
  //       );
  //       const resAllData = responseAllData.data.data;
  //       // Do something with the additional data (e.g., setProduct or log)
  //       console.log("Additional data:", resAllData);
  //       setproLength(resAllData);
  //       if (resAllData.length > 0) {
  //         const firstProduct = resAllData[0];
  //         // Check if the current category or subcategory is different from the fetched data
  //         if (
  //           firstProduct.SubCatName !== currentShownCategoryName ||
  //           firstProduct.CatName !== currentShownSubCategoryName
  //         ) {
  //           console.log("Category has changed. Resetting initial load.");
  //           setIsInitialLoad(true);
  //         }
  //       }

  //       if (isInitialLoad) {
  //         calculateCounts(resAllData);
  //         setIsInitialLoad(false); // This should be outside of calculateCounts to ensure it is executed right after counts are done
  //       }
  //     } catch (error) {
  //       console.error("Error fetching additional data:", error);
  //     }
  //   };

  //   // Call fetchData when filters, currentPage, or refresh change
  //   fetchData();
  // }, [filters, currentPage, pagination.limit, refresh]);

  const calculateCounts = (resAllData) => {
    const buildCount = (key, stateSetter) => {
      const counts = resAllData.reduce((acc, product) => {
        const value = product[key] || "Unknown";
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});
      stateSetter(counts);
    };

    // Build counts for each filter
    buildCount("color_name", setColorFilterNameCounts);
    buildCount("Species", setWoodSpeciesCounts);
    buildCount("thickness", setWoodThicknessInchCounts);
    buildCount("width", setWoodWidthCounts);
    buildCount("Finish", setWoodFinishCounts);
    buildCount("Surface_Texture", setWoodSurfaceTextureCounts);
    buildCount("installMethod", setVinylInstallationMethodCounts);
    buildCount("thickness", setVinylThicknessCounts); // If different, consider renaming the setter
    buildCount("wearlayerthickness", setVinylWearLayerThicknessCounts);
    buildCount("width", setVinylWidthInchCounts); // If different, consider renaming the setter
    buildCount("length", setVinylLengthCounts);
    buildCount("Material", setVinylUnderpadCounts);
    buildCount("ac", setLaminateACCounts);
    buildCount("thickness", setLaminateThicknessMMCounts); // Assuming thickness applies here as mm
    buildCount("Material", setLaminateWaterResisCounts);
    buildCount("Surface_Texture", setLaminateSurfaceTextureCounts);
    buildCount("width", setLaminateWidthCounts);
    buildCount("length", setLaminateLengthCounts);
    buildCount("Finish", setLaminateFinishCounts);

    buildCount("Approximate_sizeIN", setTileSizeCounts);
    buildCount("shape", setTileShapeCounts);
    buildCount("Species", setTileLookCounts);
    buildCount("Finish", setTileFinishCounts); // Note: Shared with laminate
    buildCount("Material", setTileMaterialCounts);
    buildCount("PEI_Rating", setTilePEICounts);
    buildCount("edgeType", setTileEdgeTypeCounts);
    buildCount("thickness", setCorkFloorThicknessMMCounts);
    buildCount("width", setCorkFloorWidthCounts);
    buildCount("length", setCorkFloorLengthCounts);
    buildCount("Approximate_sizeIN", setCorkFloorSizeCounts);
    buildCount("Material", setCarpetFiberMaterialCounts);
    buildCount("thickness", setCarpetThicknessCounts);
    buildCount("Species", setCarpetWeightCounts);
    buildCount("Surface_Texture", setCarpetLinearDensityCounts);
    buildCount("edgeType", setCarpetPileMethodCounts);
    buildCount("Onces", setCarpetOncesCounts);
    buildCount("Density", setCarpetDensityCounts);
    buildCount("Approximate_sizeIN", setSportFloorSizeCounts);
    buildCount("thickness", setSportFloorThicknessCounts);
    buildCount("Material", setSportFloorMaterialCounts);
    buildCount("Onces", setSportFloorOncesCounts);
    buildCount("Density", setSportFloorDensityCounts);
    buildCount("installMethod", setSportInstallationMethodCounts);
    buildCount("covrage", setMembranesCoverageCounts);
    buildCount("thickness", setMembranesThicknessMMCounts);
    buildCount("wearlayerthickness", setMembranesThicknessInchCounts);
    buildCount("edgeType", setMembranesHeatCounts);
    buildCount("volume", setMembranesVolumeLCounts);
    buildCount("format", setMembranesFormatCounts);
    buildCount("thickness", setMembranesThicknessInchCounts2);
    buildCount("covrage", setUnderlaymentsCoverageCounts);
    buildCount("format", setUnderlayCoverageSqYdCounts);
    buildCount("thickness", setUnderThicknessMMCounts);
    buildCount("edgeType", setUnderVaporCounts);
    buildCount("Species", setSettingMaterialWeightCounts);
    buildCount("volume", setSettingMaterialVolumeLCounts);
    buildCount("Species", setSettingMaterialWeightLbCounts);
    buildCount("volume", setPatchVolumeLCounts);
    buildCount("Species", setAdhesivesVolumeLCounts);
    buildCount("volume", setAdhesivesVolumeMLCounts);
    buildCount("Material", setAccessoriesMaterialCounts);
    buildCount("length", setAccessoriesLengthCounts);
    buildCount("thickness", setAccessoriesHeightCounts);
    buildCount("Finish", setAccessoriesFinishCounts);
    buildCount("Format", setAccessoriesFormatCounts);
    buildCount("collection", setProductCollectionCounts);
  };

  console.log(78, filters);
  const handleFilterChange = (filterName, value, isMultiValue = false) => {
    console.log(
      `Handling filter change. Filter: ${filterName}, Value: `,
      value,
      "IsMultiValue:",
      isMultiValue
    );
    setFilters((prevFilters) => {
      console.log(
        `Before updating filter: ${filterName}, filters:`,
        prevFilters
      );
      // For multi-value filters (e.g., checkboxes for colors, brands)
      if (isMultiValue) {
        const currentValues = prevFilters[filterName] || [];
        const updatedValues = currentValues.includes(value)
          ? currentValues.filter((item) => item !== value) // Remove value if it already exists
          : [...currentValues, value]; // Add value if it doesn't exist

        console.log(`Filters after updating multi ${filterName}:`, {
          ...prevFilters,
          [filterName]: updatedValues,
        });

        return { ...prevFilters, [filterName]: updatedValues };
      }
      // For single-value filters (e.g., dropdowns)
      console.log(`Filters after updating single value ${filterName}:`, {
        ...prevFilters,
        [filterName]: value,
      });
      return { ...prevFilters, [filterName]: value };
    });
    setCurrentPage(1); // Reset to the first page upon filter change
  };

  const handlePaginationChange = (newPage) => {
    setPagination((prevPagination) => ({ ...prevPagination, page: newPage }));
  };

  const [currentColorList, setCurrentColorList] = useState([]); // Default to menuItemsColor
  const [currentWidthList, setCurrentWidthList] = useState([]);
  const [currentLengthList, setCurrentLengthList] = useState([]);
  const [currentBrandList, setCurrentBrandList] = useState(menuItemsBrandTile);
  const [currentThicknessMMList, setCurrentThicknessMMList] = useState([]);
  const [currentSurfaceTextureList, setCurrentSurfaceTextureList] = useState(
    []
  );
  const [currentCollectionList, setCurrentCollectionList] = useState(
    menuItemsCollectionAll
  );

  // const [currentFormatList, setCurrentFormatList] = useState(menuItemsFormatTile);

  const getSUbCats = (id) => {
    axios
      .get(`https://allinonetables.com/api/category/getCat/${id}`)
      .then((response) => {
        console.log(150, response);
        setCatDetails(response.data);
      })
      .catch((err) => {
        console.log(17, err);
      });
  };

  useEffect(() => {
    axios
      .get(`${url}/api/getcategory/get/${id}`)
      .then((response) => {
        console.log(41, response.data[0]);
        setSingleCat(response.data);
        if (response.data[0].Type == "Cat") {
          getSUbCats(response.data[0]._id);
          setFilters({
            name: "",
            cat: response.data[0].name,
            sub_cat: "",
            minprice: "",
            maxprice: "",
            color_name: "",
            BrandName: "",
            length_cm: "",
            Collection_Name: "",
            installation_method: "",
            thickness: "",
            widthinch: "",
            lengthinch: "",
            wearlayerthickness: "",
            Species: "",
            Finish: "",
            Surface_Texture: "",
            ApproximateSize: "",
            Material: "",
            Onces: "",
            Density: "",
            Shape: "",
            edgeType: "",
            PEI_Rating: "",
            covrage: "",
            volime: "",
            ac: "",
          });
          setWoodSHoFilter(response.data[0].name == "Wood" ? true : false);
          setVinylSHoFilter(response.data[0].name == "Vinyl" ? true : false);
          setTileSHoFilter(
            response.data[0].name.includes("Tile") ? true : false
          );

          let categoryName = response.data[0].name;

          setCurrentShownCategoryName(categoryName);
          console.log("Current Shown Category Name:", categoryName);

          let selectedBrandList = [];
          let selectedCollectionList = []; // Default to an empty list

          // Assuming categoryName is the name of the category you're dealing with
          if (categoryName.includes("Laminate")) {
            selectedBrandList = menuItemsBrandLaminate;
            selectedCollectionList = menuItemsCollectionLaminate;
            setCurrentWidthList(menuItemsLaminateWidth);
            setCurrentLengthList(menuItemsLaminateLength);
            setCurrentColorList(menuItemsLaminateColor);
            setCurrentThicknessMMList(menuItemsLaminateThickness);
            setCurrentSurfaceTextureList(menuItemsLaminateSurfaceTexture);
          } else if (categoryName.includes("Vinyl")) {
            selectedBrandList = menuItemsBrandVinyl;
            selectedCollectionList = menuItemsCollectionVynl;
            setCurrentColorList(menuItemsVinylColor);
            setCurrentThicknessMMList(menuItemsVinylThickness);

            setCurrentWidthList(menuItemsVinylWidth);
            setCurrentLengthList(menuItemsVinylLength);
          } else if (categoryName.includes(" Tile")) {
            selectedBrandList = menuItemsBrandTile;
            selectedCollectionList = menuItemsCollectionTile;
            setCurrentColorList(menuItemsVinylColor);
            setCurrentThicknessMMList(menuItemsThickness);
            setCurrentWidthList(menuItemswidth);
            if (categoryName.includes("Wall")) {
              setCurrentTileSizeList(menuItemsWallTileSize);
              setCurrentTileShapeList(menuItemsTileShape);
              setCurrentTileLookList(menuItemsTileLook);
              setCurrentTileFinishList(menuItemsTileFinish);
              setCurrentTileMaterialList(menuItemsTileMaterial);
              setCurrentTilePEIRatingList(menuItemsTilePEIRating);
              setCurrentTileEdgeTypeList(menuItemsTileEdgeType);
            } else {
              setCurrentTileSizeList(menuItemsWallTileSize);
              setCurrentTileShapeList(menuItemsTileShape);
              setCurrentTileLookList(menuItemsTileLook);
              setCurrentTileFinishList(menuItemsTileFinish);
              setCurrentTileMaterialList(menuItemsTileMaterial);
              setCurrentTilePEIRatingList(menuItemsTilePEIRating);
              setCurrentTileEdgeTypeList(menuItemsTileEdgeType);
            }
          } else if (categoryName.includes("Wood")) {
            selectedBrandList = menuItemsBrandWoodFloor;
            selectedCollectionList = menuItemsCollectionWood;
            setCurrentColorList(menuItemsWoodColor);
            setCurrentWoodBrandList(menuItemsBrandWoodFloor);
            setCurrentWoodSpeciesList(menuItemsWoodSpecies);
            setCurrentSurfaceTextureList(menuItemsWoodSurfaceTexture);
            setCurrentWoodThicknessList(menuItemsWoodThickness);
            setCurrentWidthList(menuItemsWoodWidth);
            setCurrentWoodFinishList(menuItemsWoodFinish);
          } else if (categoryName.includes("Carpets")) {
            selectedBrandList = menuItemsBrandCarpet;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsCarpetColor);
            setCurrentTileSizeList(menuItemsCarpetSize);
            setCurrentTileMaterialList(menuItemsCarpetMaterial);
            setCurrentThicknessMMList(menuItemsCarpetThickness);
          } else if (categoryName.includes("Sport")) {
            selectedBrandList = menuItemsBrandSportFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsCarpetColor);
            setCurrentTileSizeList(menuItemsCarpetSize);
            setCurrentTileMaterialList(menuItemsSportMaterial);
            setCurrentThicknessMMList(menuItemsCarpetThickness);
          } else if (categoryName.includes("Cork Flooring")) {
            selectedBrandList = menuItemsBrandCorkFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsCarpetColor);
            setCurrentThicknessMMList(menuItemsUnderLayThicknessMM);
            setCurrentWidthList(menuItemsLaminateWidth);
            setCurrentLengthList(menuItemsLaminateLength);
          } else if (categoryName.includes("Setting Materials")) {
            selectedBrandList = menuItemsBrandCorkFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsTileSettingMaterialColor);
          } else if (categoryName.includes("Accessories")) {
            selectedBrandList = menuItemsBrandCorkFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsAccessoriesColor);
          } else {
            selectedCollectionList = menuItemsCollectionAll; // Default list if none of the above conditions are met
          }

          // Now, selectedCollectionList holds the appropriate collection list
          // You can then use this list for rendering or other logic
          setCurrentCollectionList(selectedCollectionList); // Assuming this function sets the state with the current collection list
          setCurrentBrandList(selectedBrandList);
          setLaminateSHoFilter(
            response.data[0].name == "Laminate" ? true : false
          );
          setCarpetSHoFilter(
            response.data[0].name == "Carpet & Rugs" ? true : false
          );
          setSportFloorSHoFilter(
            response.data[0].name == "Sport Flooring" ? true : false
          );
          setParamsCatName(response.data[0].name);
        } else {
          getSUbCats(response.data[0].parentId);
          handleFilterChange("sub_cat", response.data[0].name);
          setFilters({
            name: "",
            cat: "",
            sub_cat: response.data[0].name,
            minprice: "",
            maxprice: "",
            color_name: "",
            BrandName: "",

            Collection_Name: "",
            installation_method: "",
            thickness: "",
            widthinch: "",
            lengthinch: "",
            wearlayerthickness: "",
            Species: "",
            Finish: "",
            Surface_Texture: "",
            ApproximateSize: "",
            Material: "",
            Onces: "",
            Density: "",
            Shape: "",
            edgeType: "",
            PEI_Rating: "",
            covrage: "",
            volime: "",
            ac: "",
          });
          setParamsCatName(response.data[0].name);
          setCurrentShownSubCategoryName(response.data[0].name);
          setCurrentShownCategoryName(response.data[0].parentName);

          console.log(
            "Current Shown Sub Category Name:",
            response.data[0].name + ", Parent: " + response.data[0].parentName
          );

          let categoryName = response.data[0].parentName;

          let selectedBrandList = [];
          let selectedCollectionList = []; // Default to an empty list

          // Assuming categoryName is the name of the category you're dealing with
          if (categoryName.includes("Laminate")) {
            selectedBrandList = menuItemsBrandLaminate;
            selectedCollectionList = menuItemsCollectionLaminate;
            setCurrentWidthList(menuItemsLaminateWidth);
            setCurrentLengthList(menuItemsLaminateLength);
            setCurrentColorList(menuItemsLaminateColor);
            setCurrentThicknessMMList(menuItemsLaminateThickness);
            setCurrentSurfaceTextureList(menuItemsLaminateSurfaceTexture);
          } else if (categoryName.includes("Vinyl")) {
            selectedBrandList = menuItemsBrandVinyl;
            selectedCollectionList = menuItemsCollectionVynl;
            setCurrentColorList(menuItemsVinylColor);
            setCurrentThicknessMMList(menuItemsVinylThickness);

            setCurrentWidthList(menuItemsVinylWidth);
            setCurrentLengthList(menuItemsVinylLength);
          } else if (categoryName.includes(" Tile")) {
            selectedBrandList = menuItemsBrandTile;
            selectedCollectionList = menuItemsCollectionTile;
            setCurrentColorList(menuItemsVinylColor);
            setCurrentThicknessMMList(menuItemsThickness);
            setCurrentWidthList(menuItemswidth);
            if (categoryName.includes("Wall")) {
              setCurrentTileSizeList(menuItemsWallTileSize);
              setCurrentTileShapeList(menuItemsTileShape);
              setCurrentTileLookList(menuItemsTileLook);
              setCurrentTileFinishList(menuItemsTileFinish);
              setCurrentTileMaterialList(menuItemsTileMaterial);
              setCurrentTilePEIRatingList(menuItemsTilePEIRating);
              setCurrentTileEdgeTypeList(menuItemsTileEdgeType);
            } else {
              setCurrentTileSizeList(menuItemsWallTileSize);
              setCurrentTileShapeList(menuItemsTileShape);
              setCurrentTileLookList(menuItemsTileLook);
              setCurrentTileFinishList(menuItemsTileFinish);
              setCurrentTileMaterialList(menuItemsTileMaterial);
              setCurrentTilePEIRatingList(menuItemsTilePEIRating);
              setCurrentTileEdgeTypeList(menuItemsTileEdgeType);
            }
          } else if (categoryName.includes("Wood")) {
            selectedBrandList = menuItemsBrandWoodFloor;
            selectedCollectionList = menuItemsCollectionWood;
            setCurrentColorList(menuItemsWoodColor);
            setCurrentWoodBrandList(menuItemsBrandWoodFloor);
            setCurrentWoodSpeciesList(menuItemsWoodSpecies);
            setCurrentSurfaceTextureList(menuItemsWoodSurfaceTexture);
            setCurrentWoodThicknessList(menuItemsWoodThickness);
            setCurrentWidthList(menuItemsWoodWidth);
            setCurrentWoodFinishList(menuItemsWoodFinish);
          } else if (categoryName.includes("Carpets")) {
            selectedBrandList = menuItemsBrandCarpet;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsCarpetColor);
            setCurrentTileSizeList(menuItemsCarpetSize);
            setCurrentTileMaterialList(menuItemsCarpetMaterial);
            setCurrentThicknessMMList(menuItemsCarpetThickness);
          } else if (categoryName.includes("Sport")) {
            selectedBrandList = menuItemsBrandSportFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsCarpetColor);
            setCurrentTileSizeList(menuItemsCarpetSize);
            setCurrentTileMaterialList(menuItemsSportMaterial);
            setCurrentThicknessMMList(menuItemsCarpetThickness);
          } else if (categoryName.includes("Cork Flooring")) {
            selectedBrandList = menuItemsBrandCorkFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsCarpetColor);
            setCurrentThicknessMMList(menuItemsUnderLayThicknessMM);
            setCurrentWidthList(menuItemsLaminateWidth);
            setCurrentLengthList(menuItemsLaminateLength);
          } else if (categoryName.includes("Setting Materials")) {
            selectedBrandList = menuItemsBrandCorkFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsTileSettingMaterialColor);
          } else if (categoryName.includes("Accessories")) {
            selectedBrandList = menuItemsBrandCorkFlooring;
            selectedCollectionList = menuItemsCollectionAll;
            setCurrentColorList(menuItemsAccessoriesColor);
          } else {
            selectedCollectionList = menuItemsCollectionAll; // Default list if none of the above conditions are met
          }

          // Now, selectedCollectionList holds the appropriate collection list
          // You can then use this list for rendering or other logic
          setCurrentCollectionList(selectedCollectionList); // Assuming this function sets the state with the current collection list
          setCurrentBrandList(selectedBrandList);
        }
      })
      .catch((err) => {
        console.log(17, err);
      });
  }, [id]);
  //****************************************************************** */ All Brand Fetch*************************************
  // useEffect(() => {
  //   axios
  //     .get(`${url}/api/brand/getAll`)
  //     .then((response) => {
  //       setBrand(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   dispatch(getFev());
  // }, []);
  // ***********************************************************************************************************************************************
  function generateUniqueIdentifier() {
    let uniqueIdentifier = 0;
    const myStoredValue = localStorage.getItem("uniqueId");
    if (myStoredValue !== null) {
      uniqueIdentifier = localStorage.getItem("uniqueId");
    } else {
      const currentDatetime = new Date();
      const formattedDatetime = `${currentDatetime.getFullYear()}${(
        currentDatetime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getDate()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const randomCode = Math.floor(Math.random() * 90 + 10);
      uniqueIdentifier = `${formattedDatetime}${randomCode}`;
      localStorage.setItem("uniqueId", uniqueIdentifier);
    }

    return uniqueIdentifier;
  }

  // ********************************************************************************************
  const AddFavroites = (item) => {
    // Check if userData and userData.user are defined
    if (userData && userData.user && userData.user.email) {
      const userID = userData.user.email;
      const obj = {
        userId: userID,
        isFev: true,
        productId: item._id,
        product: item,
      };

      axios
        .post("https://allinonetables.com/api/fev/create", obj)
        .then((res) => {
          toast.success("Product Added to Favorites");
          dispatch(getFev());
        })
        .catch((err) => console.log(err));
    } else {
      // Handle the case when the user is not logged in
      window.location.href = "/login";
      // toast.error("Please log in to add favorites");
    }
  };

  const placeholders = Array.from({ length: 12 }, (_, index) => (
    <div className="col-lg-3 col-md-3 col-sm-6 col-12 " key={index}>
      <div className="product-wrap mb-35">
        <div className="product-img img-zoom">
          <span class="placeholder col-12" style={{ height: "12rem" }}></span>
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-12"></span>
          </h5>

          <div className="new-badge">
            <span></span>
          </div>
        </div>
        <div className="product-content">
          <h3></h3>
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-6"></span>
          </h5>
          <div className="product-price">
            <span className="new-price" class="placeholder col-6 mt-2">
              <sub class="placeholder col-6"></sub>
            </span>
          </div>
        </div>
      </div>
    </div>
  ));
  const removeFaverites = (id) => {
    // Again, check if userData and userData.user are defined
    if (userData && userData.user && userData.user.email) {
      console.log(446, id);
      if (id) {
        axios
          .delete(
            `https://allinonetables.com/api/fev/delete_by_productId/${id}`
          )
          .then((res) => {
            dispatch(getFev());
            setRefresh(!refresh);
            toast.error("Favorite Product Deleted From List.");
          })
          .catch((err) => console.log(err));
      }
    } else {
      // Handle the case when the user is not logged in
      toast.error("Please log in to manage favorites");
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = Product.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  //-------- SORTING PRODUCTS START ---------------------------
  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedSortingOption(selectedOption);

    // Update the Product array based on the selected sorting option
    let sortedProducts = [...Product];

    switch (selectedOption) {
      case "lowToHigh":
        sortedProducts = fetchedProduct.sort(
          (a, b) => a.sale_price - b.sale_price
        );
        break;
      case "highToLow":
        sortedProducts = fetchedProduct.sort(
          (a, b) => b.sale_price - a.sale_price
        );
        break;
      case "BestSeller":
        sortedProducts = sortedProducts.filter(
          (item) => item.BestSeller === true
        );
        break;
      case "MostView":
        sortedProducts = sortedProducts.filter(
          (item) => item.IsmostViewed === true
        );
        break;
      case "NewProduct":
        sortedProducts = sortedProducts.filter(
          (item) => item.newProduct === true
        );
        break;
      case "Default":
        {
          if (SingleCat[0]?.Type == "sub_cat") {
            setCurrentPage(1);
            setFilters({
              ...filters,
              sub_cat: ParamsCatName,
            });
            setCurrentPage(1);
          } else {
            setCurrentPage(1);
            setFilters({
              ...filters,
              cat: ParamsCatName,
            });
            setCurrentPage(1);
          }
        }
        break;
    }

    setProduct(sortedProducts);
  };
  //-------- SORTING PRODUCTS END ---------------------------

  // const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  let itemsPerPage = 20;
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Product.slice(indexOfFirstItem, indexOfLastItem);

    setCurrentItems(currentItems);
  }, [currentPage, itemsPerPage, Product]);

  const totalItems = Product.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const isLastPage = currentPage === totalPages;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 100);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    window.scrollTo(0, 100);
  };
  const CheckisFev = (id) => {
    // console.log(id);
    // console.log(433,fevProduct);
    if (fevProduct?.length >= 0) {
      const isUserIdPresent = fevProduct.some((item) => item.productId === id);

      // console.log(isUserIdPresent);
      return isUserIdPresent; // Output: true or false
    }
  };

  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }
  console.log(473, Product);

  /* ************************ SidebarFiltering Expand Shrink Func ******************** */

  const [displayedBrandsLimit, setDisplayedBrandsLimit] = useState(4);
  const [brandsExpanded, setBrandsExpanded] = useState(false);

  const [displayedCollectionsLimit, setDisplayedCollectionsLimit] = useState(4);
  const [collectionsExpanded, setCollectionsExpanded] = useState(false);

  const [displayedColorsLimit, setDisplayedColorsLimit] = useState(4);
  const [colorsExpanded, setColorsExpanded] = useState(false);

  const [displayedThicknessLimit, setDisplayedThicknessLimit] = useState(4);
  const [thicknessExpanded, setThicknessExpanded] = useState(false);

  const [displayedWidthLimit, setDisplayedWidthLimit] = useState(4);
  const [widthExpanded, setWidthExpanded] = useState(false);

  const [displayedLengthLimit, setDisplayedLengthLimit] = useState(4);
  const [lengthExpanded, setLengthExpanded] = useState(false);

  const [displayedInstallationLimit, setDisplayedInstallationLimit] =
    useState(4);
  const [installationExpanded, setInstallationExpanded] = useState(false);

  const [displayedFinishLimit, setDisplayedFinishLimit] = useState(4);
  const [finishExpanded, setFinishExpanded] = useState(false);

  const [displayedTextureLimit, setDisplayedTextureLimit] = useState(4);
  const [textureExpanded, setTextureExpanded] = useState(false);

  const [displayedSpeciesLimit, setDisplayedSpeciesLimit] = useState(4);
  const [speciesExpanded, setSpeciesExpanded] = useState(false);

  const [displayedWoodThicknessLimit, setDisplayedWoodThicknessLimit] =
    useState(4);
  const [woodThicknessExpanded, setWoodThicknessExpanded] = useState(false);

  const [displayedVinylProductTypeLimit, setDisplayedVinylProductTypeLimit] =
    useState(4);
  const [vinylProductTypeExpanded, setVinylProductTypeExpanded] =
    useState(false);

  const [
    displayedWearLayerThicknessLimit,
    setDisplayedWearLayerThicknessLimit,
  ] = useState(4);
  const [wearLayerThicknessExpanded, setWearLayerThicknessExpanded] =
    useState(false);
  const [selectedWearLayerThickness, setSelectedWearLayerThickness] = useState(
    []
  );

  const [vinylLengthExpanded, setVinylLengthExpanded] = useState(false);
  const [displayedVinylLengthLimit, setDisplayedVinylLengthLimit] = useState(4);

  const [selectedUnderpadAttached, setSelectedUnderpadAttached] = useState([]);
  const [underpadAttachedExpanded, setUnderpadAttachedExpanded] =
    useState(false);

  const [selectedLaminateProductType, setSelectedLaminateProductType] =
    useState([]);
  const [laminateProductTypeExpanded, setLaminateProductTypeExpanded] =
    useState(false);
  const [
    displayedLaminateProductTypeLimit,
    setDisplayedLaminateProductTypeLimit,
  ] = useState(4);

  const [selectedACRating, setSelectedACRating] = useState([]);
  const [displayedACRatingLimit, setDisplayedACRatingLimit] = useState(4); // Assuming all are displayed initially, adjust as needed
  const [acRatingExpanded, setACRatingExpanded] = useState(false);

  const [selectedWaterResistance, setSelectedWaterResistance] = useState([]);
  const [displayedWaterResistanceLimit, setDisplayedWaterResistanceLimit] =
    useState(4); // Since there are only two options
  const [waterResistanceExpanded, setWaterResistanceExpanded] = useState(false);

  const [surfaceTextureExpanded, setSurfaceTextureExpanded] = useState(false);

  const [selectedWallTileProductType, setSelectedWallTileProductType] =
    useState([]);
  const [
    displayedWallTileProductTypeLimit,
    setDisplayedWallTileProductTypeLimit,
  ] = useState(4);
  const [wallTileProductTypeExpanded, setWallTileProductTypeExpanded] =
    useState(false);

  const [selectedWallTileSize, setSelectedWallTileSize] = useState([]);
  const [displayedWallTileSizeLimit, setDisplayedWallTileSizeLimit] =
    useState(4);
  const [wallTileSizeExpanded, setWallTileSizeExpanded] = useState(false);

  const [selectedTileShape, setSelectedTileShape] = useState([]);
  const [displayedTileShapeLimit, setDisplayedTileShapeLimit] = useState(4); // Assuming we want to display all shapes without needing to expand/collapse
  const [tileShapeExpanded, setTileShapeExpanded] = useState(false); // Considering we display all by default, might not need this

  const [selectedTileLook, setSelectedTileLook] = useState([]);
  const [displayedTileLookLimit, setDisplayedTileLookLimit] = useState(4); // Initial display limit
  const [tileLookExpanded, setTileLookExpanded] = useState(false);

  const [selectedTileFinish, setSelectedTileFinish] = useState([]);
  const [displayedTileFinishLimit, setDisplayedTileFinishLimit] = useState(4); // Initial display limit
  const [tileFinishExpanded, setTileFinishExpanded] = useState(false);

  const [selectedTileMaterial, setSelectedTileMaterial] = useState([]);
  const [displayedTileMaterialLimit, setDisplayedTileMaterialLimit] =
    useState(4); // Initial display limit
  const [tileMaterialExpanded, setTileMaterialExpanded] = useState(false);

  const [selectedTilePEIRating, setSelectedTilePEIRating] = useState([]);
  const [displayedTilePEIRatingLimit, setDisplayedTilePEIRatingLimit] =
    useState(7); // All ratings are initially displayed
  const [tilePEIRatingExpanded, setTilePEIRatingExpanded] = useState(true); // Assuming we want to display all by default since the list is concise

  const [selectedTileEdgeType, setSelectedTileEdgeType] = useState([]);
  const [displayedTileEdgeTypeLimit, setDisplayedTileEdgeTypeLimit] =
    useState(4); // Initial limit
  const [tileEdgeTypeExpanded, setTileEdgeTypeExpanded] = useState(false);

  const [selectedCarpetsPileWeight, setSelectedCarpetsPileWeight] = useState(
    []
  );
  const [displayedCarpetsPileWeightLimit, setDisplayedCarpetsPileWeightLimit] =
    useState(4); // Initial limit
  const [carpetsPileWeightExpanded, setCarpetsPileWeightExpanded] =
    useState(false);

  const [selectedCarpetLinearDensity, setSelectedCarpetLinearDensity] =
    useState([]);
  const [
    displayedCarpetLinearDensityLimit,
    setDisplayedCarpetLinearDensityLimit,
  ] = useState(4); // Initial display limit
  const [carpetLinearDensityExpanded, setCarpetLinearDensityExpanded] =
    useState(false);

  const [
    selectedCarpetPileConstructionMethod,
    setSelectedCarpetPileConstructionMethod,
  ] = useState([]);
  const [
    displayedCarpetPileConstructionMethodLimit,
    setDisplayedCarpetPileConstructionMethodLimit,
  ] = useState(4); // Initial display limit
  const [
    carpetPileConstructionMethodExpanded,
    setCarpetPileConstructionMethodExpanded,
  ] = useState(false);

  const [selectedCarpetOunces, setSelectedCarpetOunces] = useState([]);
  const [displayedCarpetOuncesLimit, setDisplayedCarpetOuncesLimit] =
    useState(4); // Initial display limit
  const [carpetOuncesExpanded, setCarpetOuncesExpanded] = useState(false);

  const [selectedCarpetDensity, setSelectedCarpetDensity] = useState([]);
  const [displayedCarpetDensityLimit, setDisplayedCarpetDensityLimit] =
    useState(4); // Initial display limit
  const [carpetDensityExpanded, setCarpetDensityExpanded] = useState(false);

  const [selectedSportOunces, setSelectedSportOunces] = useState([]);
  const [displayedSportOuncesLimit, setDisplayedSportOuncesLimit] = useState(4); // Initial display limit
  const [sportOuncesExpanded, setSportOuncesExpanded] = useState(false);

  const [selectedSportDensity, setSelectedSportDensity] = useState([]);
  const [displayedSportDensityLimit, setDisplayedSportDensityLimit] =
    useState(4); // Initial display limit
  const [sportDensityExpanded, setSportDensityExpanded] = useState(false);

  const [selectedSportInstallationMethod, setSelectedSportInstallationMethod] =
    useState([]);
  const [
    displayedSportInstallationMethodLimit,
    setDisplayedSportInstallationMethodLimit,
  ] = useState(4); // Initial display limit
  const [sportInstallationMethodExpanded, setSportInstallationMethodExpanded] =
    useState(false);

  const [selectedMembranesCoverage, setSelectedMembranesCoverage] = useState(
    []
  );
  const [displayedMembranesCoverageLimit, setDisplayedMembranesCoverageLimit] =
    useState(4); // Initial display limit
  const [membranesCoverageExpanded, setMembranesCoverageExpanded] =
    useState(false);

  const [selectedMembranesThickness, setSelectedMembranesThickness] = useState(
    []
  );
  const [
    displayedMembranesThicknessLimit,
    setDisplayedMembranesThicknessLimit,
  ] = useState(4); // Initial display limit
  const [membranesThicknessExpanded, setMembranesThicknessExpanded] =
    useState(false);

  const [selectedMembranesVolume, setSelectedMembranesVolume] = useState([]);
  const [displayedMembranesVolumeLimit, setDisplayedMembranesVolumeLimit] =
    useState(4); // Initial display limit
  const [membranesVolumeExpanded, setMembranesVolumeExpanded] = useState(false);

  const [selectedMembranesThicknessInch, setSelectedMembranesThicknessInch] =
    useState([]);
  const [
    displayedMembranesThicknessInchLimit,
    setDisplayedMembranesThicknessInchLimit,
  ] = useState(4); // Initial display limit
  const [membranesThicknessInchExpanded, setMembranesThicknessInchExpanded] =
    useState(false);

  const [selectedMembranesOnlyHeatType, setSelectedMembranesOnlyHeatType] =
    useState([]);
  const [
    displayedMembranesOnlyHeatTypeLimit,
    setDisplayedMembranesOnlyHeatTypeLimit,
  ] = useState(4); // Initial display limit
  const [membranesOnlyHeatTypeExpanded, setMembranesOnlyHeatTypeExpanded] =
    useState(false);

  const [selectedMembranesFormat, setSelectedMembranesFormat] = useState([]);
  const [displayedMembranesFormatLimit, setDisplayedMembranesFormatLimit] =
    useState(4); // Initial display limit
  const [membranesFormatExpanded, setMembranesFormatExpanded] = useState(false);

  const [selectedUnderlayCoverage, setSelectedUnderlayCoverage] = useState([]);
  const [displayedUnderlayCoverageLimit, setDisplayedUnderlayCoverageLimit] =
    useState(4); // Initial display limit
  const [underlayCoverageExpanded, setUnderlayCoverageExpanded] =
    useState(false);

  const [selectedUnderlayThickness, setSelectedUnderlayThickness] = useState(
    []
  );
  const [displayedUnderlayThicknessLimit, setDisplayedUnderlayThicknessLimit] =
    useState(4); // Initial display limit
  const [underlayThicknessExpanded, setUnderlayThicknessExpanded] =
    useState(false);

  const [selectedUnderlayVaporBarrier, setSelectedUnderlayVaporBarrier] =
    useState([]);
  const [
    displayedUnderlayVaporBarrierLimit,
    setDisplayedUnderlayVaporBarrierLimit,
  ] = useState(4); // Initial display limit
  const [underlayVaporBarrierExpanded, setUnderlayVaporBarrierExpanded] =
    useState(false);

  const [selectedUnderlayCoverageYd, setSelectedUnderlayCoverageYd] = useState(
    []
  );
  const [
    displayedUnderlayCoverageYdLimit,
    setDisplayedUnderlayCoverageYdLimit,
  ] = useState(4); // Initial display limit
  const [underlayCoverageYdExpanded, setUnderlayCoverageYdExpanded] =
    useState(false);

  const [selectedSettingMaterialLb, setSelectedSettingMaterialLb] = useState(
    []
  );
  const [displayedSettingMaterialLbLimit, setDisplayedSettingMaterialLbLimit] =
    useState(4); // Initial display limit
  const [settingMaterialLbExpanded, setSettingMaterialLbExpanded] =
    useState(false);

  const [selectedSettingMaterialVolume, setSelectedSettingMaterialVolume] =
    useState([]);
  const [
    displayedSettingMaterialVolumeLimit,
    setDisplayedSettingMaterialVolumeLimit,
  ] = useState(4); // Initial display limit
  const [settingMaterialVolumeExpanded, setSettingMaterialVolumeExpanded] =
    useState(false);

  const [selectedPatchWeight, setSelectedPatchWeight] = useState([]);
  const [displayedPatchWeightLimit, setDisplayedPatchWeightLimit] = useState(4); // Initial display limit
  const [patchWeightExpanded, setPatchWeightExpanded] = useState(false);

  const [selectedPatchVolume, setSelectedPatchVolume] = useState([]);
  const [displayedPatchVolumeLimit, setDisplayedPatchVolumeLimit] = useState(4); // Initial display limit
  const [patchVolumeExpanded, setPatchVolumeExpanded] = useState(false);

  const [selectedAdhesivesVolumeL, setSelectedAdhesivesVolumeL] = useState([]);
  const [displayedAdhesivesVolumeLLimit, setDisplayedAdhesivesVolumeLLimit] =
    useState(4); // Initial display limit
  const [adhesivesVolumeLExpanded, setAdhesivesVolumeLExpanded] =
    useState(false);

  const [selectedAdhesivesVolumeML, setSelectedAdhesivesVolumeML] = useState(
    []
  );
  const [displayedAdhesivesVolumeMLLimit, setDisplayedAdhesivesVolumeMLLimit] =
    useState(4); // Initial display limit
  const [adhesivesVolumeMLExpanded, setAdhesivesVolumeMLExpanded] =
    useState(false);

  const [selectedAccessoryMaterial, setSelectedAccessoryMaterial] = useState(
    []
  );
  const [displayedAccessoryMaterialLimit, setDisplayedAccessoryMaterialLimit] =
    useState(4); // Initial display limit
  const [accessoryMaterialExpanded, setAccessoryMaterialExpanded] =
    useState(false);

  const [selectedAccessoryLength, setSelectedAccessoryLength] = useState([]);
  const [displayedAccessoryLengthLimit, setDisplayedAccessoryLengthLimit] =
    useState(4); // Initial display limit
  const [accessoryLengthExpanded, setAccessoryLengthExpanded] = useState(false);

  const [selectedAccessoryHeight, setSelectedAccessoryHeight] = useState([]);
  const [displayedAccessoryHeightLimit, setDisplayedAccessoryHeightLimit] =
    useState(4); // Initial display limit
  const [accessoryHeightExpanded, setAccessoryHeightExpanded] = useState(false);

  const [selectedAccessoryFinish, setSelectedAccessoryFinish] = useState([]);
  const [displayedAccessoryFinishLimit, setDisplayedAccessoryFinishLimit] =
    useState(4); // Initial display limit
  const [accessoryFinishExpanded, setAccessoryFinishExpanded] = useState(false);

  const [selectedFloorVentFormat, setSelectedFloorVentFormat] = useState([]);
  const [displayedFloorVentFormatLimit, setDisplayedFloorVentFormatLimit] =
    useState(4); // Initial display limit
  const [floorVentFormatExpanded, setFloorVentFormatExpanded] = useState(false);

  // Right before your conditional rendering
  console.log("Type of SingleCat[0]:", SingleCat[0]?.Type);

  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      {/* ************************ Show Filter Mobile view Button *************** */}
      <div className="container d-block d-lg-none">
        {/* <!-- Button trigger modal --> */}
        <button
          type="button"
          class="btn btn-primary show-filter"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Show Filters <i class="bi bi-funnel"></i>
        </button>

        {/* <!-- Modal --> */}
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  Filters <i class="bi bi-funnel"></i>
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="col-11 px-2">
                  {/* ************************************************************** Flooring / wood catagory ***********************************************/}
                  <div className="sidebar-wrapper pt-1">
                    {/* **********Flooring / wood catagory sub catagories ****************** */}
                    <div
                      className="sidebar-widget sidebar-widget-border"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="sidebar-widget-title mb-30">
                        <h3>Product Category</h3>
                        {/* {SingleCat[0]?.Type === "Sub_cat" && (
                          <h6 style={{ marginTop: "0.4em" }}>
                            {SingleCat[0]?.name}
                          </h6>
                        )} */}

                        <form action="">
                          {CatDetails?.map((item) => {
                            return (
                              <>
                                <div className="check-widget">
                                  <input
                                    type="checkbox"
                                    checked={availCheck("sub_cat", item.name)}
                                    onChange={() =>
                                      handleChange("sub_cat", item.name)
                                    }
                                  />{" "}
                                  <p>{item.name}</p>
                                </div>
                              </>
                            );
                          })}
                        </form>
                      </div>
                    </div>

                    {/* **********Flooring / wood catagory min - max ****************** */}
                    <div
                      className="sidebar-widget sidebar-widget-border"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="sidebar-widget-title">
                        <h3>Price</h3>

                        <form action="">
                          <div className="price-widget">
                            <input
                              type="number"
                              placeholder="Min"
                              value={minPrice}
                              onChange={(e) => getMinPrice(e.target.value)}
                            />

                            <input
                              type="number"
                              placeholder="Max"
                              value={maxPrice}
                              onChange={(e) => getMaxPrice(e.target.value)}
                            />
                            {resetPrice === false ? (
                              <>
                                <a
                                  className="aply"
                                  onClick={(e) => {
                                    applyFunc();
                                  }}
                                >
                                  Apply
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  className="aply"
                                  onClick={(e) => {
                                    clearFunc();
                                  }}
                                >
                                  Reset
                                </a>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* *********** ALL PRODUCT DYNAMIC FILTER STARTS HERE **************** */}

                    <div
                      className="sidebar-widget sidebar-widget-border"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="sidebar-widget-title mb-25"></div>

                      {/* ***************** ALL REGULAR MODE ***************** */}

                      <div className="sidebar-list-style">
                        <div class="accordion" id="accordionExample">
                          {/* ***************** ALL catagory brands ***************** */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Brands
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              class="accordion-collapse collapse show"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form>
                                  {currentBrandList
                                    .slice(0, displayedBrandsLimit)
                                    .map((item, index) => {
                                      const checkboxId = `brand-${index}`; // Unique ID for each checkbox
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={availCheck(
                                                "BrandName",
                                                item.BrandName
                                              )}
                                              onChange={() =>
                                                handleChange(
                                                  "BrandName",
                                                  item.BrandName
                                                )
                                              }
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.BrandName}
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentBrandList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !brandsExpanded;
                                        setBrandsExpanded(isExpanded);
                                        setDisplayedBrandsLimit(
                                          isExpanded
                                            ? currentBrandList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {brandsExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** ALL catagory color ********* */}
                          {!currentShownCategoryName.includes("Membranes") &&
                            !currentShownCategoryName.includes(
                              "Underlayments"
                            ) && (
                              <>
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      Color
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseThree"
                                    class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div class="accordion-body">
                                      <form action="">
                                        {currentColorList
                                          .slice(0, displayedColorsLimit)
                                          .map((item, index) => {
                                            const checkboxId = `color-${index}`; // Unique ID for each checkbox
                                            const count =
                                              colorFilterNameCounts[
                                                item.color_name
                                              ] || 0;
                                            return (
                                              <>
                                                <div className="check-widget">
                                                  <input
                                                    type="checkbox"
                                                    id={checkboxId}
                                                    checked={availCheck(
                                                      "color_name",
                                                      item.color_name
                                                    )}
                                                    onChange={() =>
                                                      handleChange(
                                                        "color_name",
                                                        item.color_name
                                                      )
                                                    }
                                                  />{" "}
                                                  <label htmlFor={checkboxId}>
                                                    {item.color_name} ({count})
                                                  </label>
                                                </div>
                                              </>
                                            );
                                          })}
                                        {currentColorList.length > 4 && ( // Only show button if more than 4 colors are available
                                          <button
                                            type="button"
                                            className="show-more-btn"
                                            onClick={() => {
                                              const isExpanded =
                                                !colorsExpanded; // Toggle the expanded state
                                              setColorsExpanded(isExpanded); // Update the expanded state
                                              setDisplayedColorsLimit(
                                                isExpanded
                                                  ? currentColorList.length
                                                  : 4
                                              );
                                            }}
                                          >
                                            {colorsExpanded
                                              ? "Show Less"
                                              : "Show More"}
                                          </button>
                                        )}
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                          {/* *************** Flooring WOOD Type ONLY ******* */}
                          {/* *************** Flooring / WOOD Shape Product Type  ******* */}
                          {/* ******************** Flooring / Wall Tile Product Type ********* */}
                          {/* ******************** Product Type will be used in the Sub Cat Previous Category is in Backup For future uses ********* */}
                          {currentShownCategoryName.includes("Wood") && (
                            <>
                              {/* ******************** Flooring / wood cspecies ********* ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSpecies"
                                    aria-expanded="false"
                                    aria-controls="collapseSpecies"
                                  >
                                    Species
                                  </button>
                                </h2>
                                <div
                                  id="collapseSpecies"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsWoodSpecies
                                        .slice(0, displayedSpeciesLimit)
                                        .map((item, index) => {
                                          const checkboxId = `species-${index}`;
                                          const count =
                                            woodSpeciesCounts[item.Species] ||
                                            0;
                                          return (
                                            <div className="check-widget">
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "Species",
                                                  item.Species
                                                )}
                                                onChange={() =>
                                                  handleChange(
                                                    "Species",
                                                    item.Species
                                                  )
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.Species} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {menuItemsWoodSpecies.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !speciesExpanded;
                                            setSpeciesExpanded(isExpanded);
                                            setDisplayedSpeciesLimit(
                                              isExpanded
                                                ? menuItemsWoodSpecies.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {speciesExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* ******************** Flooring / wood thickness ********* ********* */}

                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseWoodThickness"
                                    aria-expanded="false"
                                    aria-controls="collapseWoodThickness"
                                  >
                                    Thickness (in.)
                                  </button>
                                </h2>
                                <div
                                  id="collapseWoodThickness"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentWoodThicknessList
                                        .slice(0, displayedWoodThicknessLimit)
                                        .map((item, index) => {
                                          const checkboxId = `wood-thickness-${index}`;
                                          const count =
                                            woodThicknessInchCounts[
                                              item.thickness
                                            ] || 0;
                                          return (
                                            <div className="check-widget">
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "thickness",
                                                  item.thickness
                                                )}
                                                onChange={() =>
                                                  handleChange(
                                                    "thickness",
                                                    item.thickness
                                                  )
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.display} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentWoodThicknessList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !woodThicknessExpanded;
                                            setWoodThicknessExpanded(
                                              isExpanded
                                            );
                                            setDisplayedWoodThicknessLimit(
                                              isExpanded
                                                ? currentWoodThicknessList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {woodThicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *********************Flooring / wood catagory width (in .)*********  */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    Width (in.)
                                  </button>
                                </h2>

                                <div
                                  id="collapseSeven"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentWoodWidthList
                                        .slice(0, displayedWidthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `widthinch-${index}`; // Unique ID for each checkbox
                                          const count =
                                            woodWidthCounts[item.width] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "width",
                                                    item.width
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "width",
                                                      item.width
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.display} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentWoodWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !widthExpanded; // Toggle the expanded state
                                            setWidthExpanded(isExpanded); // Update the expanded state
                                            setDisplayedWidthLimit(
                                              isExpanded
                                                ? currentWoodWidthList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {widthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring / wood category Finish (Engineer no FInish) ********* */}
                              {currentShownSubCategoryName !== "Engineered" && (
                                <>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFinish"
                                        aria-expanded="false"
                                        aria-controls="collapseFinish"
                                      >
                                        Finish
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFinish"
                                      className="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <form action="">
                                          {currentWoodFinishList
                                            .slice(0, displayedFinishLimit)
                                            .map((item, index) => {
                                              const checkboxId = `finish-${index}`;
                                              const count =
                                                woodFinishCounts[item.Finish] ||
                                                0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={availCheck(
                                                        "Finish",
                                                        item.Finish
                                                      )}
                                                      onChange={() =>
                                                        handleChange(
                                                          "Finish",
                                                          item.Finish
                                                        )
                                                      }
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.Finish} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {currentWoodFinishList.length > 4 && (
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !finishExpanded;
                                                setFinishExpanded(isExpanded);
                                                setDisplayedFinishLimit(
                                                  isExpanded
                                                    ? currentWoodFinishList.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {finishExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* ******************** Flooring / wood category Surface Texture ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTexture"
                                    aria-expanded="false"
                                    aria-controls="collapseTexture"
                                  >
                                    Surface Texture
                                  </button>
                                </h2>
                                <div
                                  id="collapseTexture"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentSurfaceTextureList
                                        .slice(0, displayedTextureLimit)
                                        .map((item, index) => {
                                          const checkboxId = `texture-${index}`;
                                          const count =
                                            woodSurfaceTextureCounts[
                                              item.Surface_Texture
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "Surface_Texture",
                                                    item.Surface_Texture
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "Surface_Texture",
                                                      item.Surface_Texture
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Surface_Texture} (
                                                  {count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentSurfaceTextureList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !textureExpanded;
                                            setTextureExpanded(isExpanded);
                                            setDisplayedTextureLimit(
                                              isExpanded
                                                ? currentSurfaceTextureList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {textureExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* ******************** Wood Sub End ********* */}
                            </>
                          )}

                          {/* ******************** Flooring / WOOD ENDED ********* */}

                          {/* *************** Flooring / vinyl ALL ******* */}
                          {currentShownCategoryName.includes("Vinyl") && (
                            <>
                              {/* *************** Flooring / vinyl category installation method  ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#method"
                                    aria-expanded="false"
                                    aria-controls="method"
                                  >
                                    Installation Method
                                  </button>
                                </h2>

                                <div
                                  id="method"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsInstallation
                                        .slice(0, displayedInstallationLimit)
                                        .map((item, index) => {
                                          const checkboxId = `installation-${index}`; // Unique ID for each checkbox
                                          const count =
                                            vinylInstallationMethodCounts[
                                              item.installMethod
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "installMethod",
                                                    item.installMethod
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "installMethod",
                                                      item.installMethod
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.installMethod} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsInstallation.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !installationExpanded; // Toggle the expanded state
                                            setInstallationExpanded(isExpanded); // Update the expanded state
                                            setDisplayedInstallationLimit(
                                              isExpanded
                                                ? menuItemsInstallation.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {installationExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / vinyl category thickness ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    Thickness (mm)
                                  </button>
                                </h2>
                                <div
                                  id="collapseFive"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentThicknessMMList
                                        .slice(0, displayedThicknessLimit)
                                        .map((item, index) => {
                                          const checkboxId = `thickness-${index}`; // Unique ID for each checkbox
                                          const count =
                                            vinylThicknessCounts[
                                              item.thickness
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "thickness",
                                                    item.thickness
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "thickness",
                                                      item.thickness
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !thicknessExpanded; // Toggle the expanded state
                                            setThicknessExpanded(isExpanded); // Update the expanded state
                                            setDisplayedThicknessLimit(
                                              isExpanded
                                                ? currentThicknessMMList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {thicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************** Flooring / vinyl category Wear Layer  ******* */}

                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#wearLayerThickness"
                                    aria-expanded="false"
                                    aria-controls="wearLayerThickness"
                                  >
                                    Wear Layer Thickness (mm)
                                  </button>
                                </h2>

                                <div
                                  id="wearLayerThickness"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsVinylWearLayerThickness
                                        .slice(
                                          0,
                                          displayedWearLayerThicknessLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `wearLayerThickness-${index}`; // Unique ID for each checkbox
                                          const count =
                                            vinylWearLayerThicknessCounts[
                                              item.wearlayerthickness
                                            ] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "wearlayerthickness",
                                                  item.wearlayerthickness
                                                )}
                                                onChange={() =>
                                                  handleChange(
                                                    "wearlayerthickness",
                                                    item.wearlayerthickness
                                                  )
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.wearlayerthickness} (
                                                {count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {menuItemsVinylWearLayerThickness.length >
                                        4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !wearLayerThicknessExpanded;
                                            setWearLayerThicknessExpanded(
                                              isExpanded
                                            );
                                            setDisplayedWearLayerThicknessLimit(
                                              isExpanded
                                                ? menuItemsVinylWearLayerThickness.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {wearLayerThicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************** Flooring / vinyl category Width in ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    Width (in.)
                                  </button>
                                </h2>

                                <div
                                  id="collapseSeven"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentWidthList
                                        .slice(0, displayedWidthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `width-${index}`; // Unique ID for each checkbox
                                          const count =
                                            vinylWidthInchCounts[item.width] ||
                                            0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "width",
                                                    item.width
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "width",
                                                      item.width
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.width} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !widthExpanded; // Toggle the expanded state
                                            setWidthExpanded(isExpanded); // Update the expanded state
                                            setDisplayedWidthLimit(
                                              isExpanded
                                                ? currentWidthList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {widthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *********************Flooring / vinyl category Length (in .)*********  */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#vinylLength"
                                    aria-expanded="false"
                                    aria-controls="vinylLength"
                                  >
                                    Length (in.)
                                  </button>
                                </h2>

                                <div
                                  id="vinylLength"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentLengthList
                                        .slice(0, displayedVinylLengthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `length-${index}`; // Unique ID for each checkbox
                                          const count =
                                            vinylLengthCounts[item.length] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "length",
                                                  item.length
                                                )}
                                                onChange={() =>
                                                  handleChange(
                                                    "length",
                                                    item.length
                                                  )
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.length} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentLengthList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !vinylLengthExpanded;
                                            setVinylLengthExpanded(isExpanded);
                                            setDisplayedVinylLengthLimit(
                                              isExpanded
                                                ? currentLengthList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {vinylLengthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************Flooring / Vinyl UnhderPad Attached USE Material for now ******/}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#underpadAttached"
                                    aria-expanded="false"
                                    aria-controls="underpadAttached"
                                  >
                                    Underpad Attached
                                  </button>
                                </h2>

                                <div
                                  id="underpadAttached"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsVinylUnderpadAttached.map(
                                        (item, index) => {
                                          const checkboxId = `underpad-${index}`;
                                          const count =
                                            vinylUnderpadCounts[
                                              item.Material
                                            ] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "Material",
                                                  item.Material
                                                )}
                                                onChange={() =>
                                                  handleChange(
                                                    "Material",
                                                    item.Material
                                                  )
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.Material} ({count})
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                      {menuItemsVinylUnderpadAttached.length >
                                        4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !underpadAttachedExpanded;
                                            setUnderpadAttachedExpanded(
                                              isExpanded
                                            );
                                            // Assuming a similar limit and expansion behavior as other filters
                                          }}
                                        >
                                          {underpadAttachedExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************Flooring / Vinyl sub ENd ******/}
                            </>
                          )}
                          {/* *************Flooring / Vinyl  ENd ******/}

                          {/* *************Flooring / Laminate  Start ******/}

                          {/* ***********Flooring / Laminate  ALL START **** */}
                          {currentShownCategoryName.includes("Laminate") && (
                            <>
                              {/* ***********Flooring / Laminate  AC Durability **** */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#acDurabilityRating"
                                    aria-expanded="false"
                                    aria-controls="acDurabilityRating"
                                  >
                                    AC Durability Rating
                                  </button>
                                </h2>

                                <div
                                  id="acDurabilityRating"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsACDurabilityRating
                                        .slice(0, displayedACRatingLimit)
                                        .map((item, index) => {
                                          const checkboxId = `acRating-${index}`;
                                          const count =
                                            laminateACCounts[item.ac] || 0;
                                          return (
                                            <div
                                              key={item.id}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "ac",
                                                  item.ac
                                                )}
                                                onChange={() =>
                                                  handleChange("ac", item.ac)
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.ac} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {menuItemsACDurabilityRating.length >
                                        4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !acRatingExpanded;
                                            setACRatingExpanded(isExpanded);
                                            setDisplayedACRatingLimit(
                                              isExpanded
                                                ? menuItemsACDurabilityRating.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {acRatingExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring / Laminate Thickness mm ********* */}

                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    Thickness (mm)
                                  </button>
                                </h2>
                                <div
                                  id="collapseFive"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentThicknessMMList
                                        .slice(0, displayedThicknessLimit)
                                        .map((item, index) => {
                                          const checkboxId = `thickness-${index}`; // Unique ID for each checkbox
                                          const count =
                                            laminateThicknessMMCounts[
                                              item.thickness
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "thickness",
                                                    item.thickness
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "thickness",
                                                      item.thickness
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !thicknessExpanded; // Toggle the expanded state
                                            setThicknessExpanded(isExpanded); // Update the expanded state
                                            setDisplayedThicknessLimit(
                                              isExpanded
                                                ? currentThicknessMMList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {thicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *********************Flooring / Laminate Water Resistance use Material for now *********  */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#waterResistance"
                                    aria-expanded="false"
                                    aria-controls="waterResistance"
                                  >
                                    Water Resistance
                                  </button>
                                </h2>
                                <div
                                  id="waterResistance"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsWaterResistance.map(
                                        (item, index) => {
                                          const checkboxId = `waterResistance-${index}`;
                                          const count =
                                            laminateWaterResisCounts[
                                              item.Material
                                            ] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "Material",
                                                  item.Material
                                                )}
                                                onChange={() =>
                                                  handleChange(
                                                    "Material",
                                                    item.Material
                                                  )
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.Material} ({count})
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *********************Flooring / Laminate Surface Texture *********  */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTexture"
                                    aria-expanded="false"
                                    aria-controls="collapseTexture"
                                  >
                                    Surface Texture
                                  </button>
                                </h2>
                                <div
                                  id="collapseTexture"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentSurfaceTextureList
                                        .slice(0, displayedTextureLimit)
                                        .map((item, index) => {
                                          const checkboxId = `texture-${index}`;
                                          const count =
                                            laminateSurfaceTextureCounts[
                                              item.Surface_Texture
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "Surface_Texture",
                                                    item.Surface_Texture
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "Surface_Texture",
                                                      item.Surface_Texture
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Surface_Texture} (
                                                  {count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentSurfaceTextureList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !textureExpanded;
                                            setTextureExpanded(isExpanded);
                                            setDisplayedTextureLimit(
                                              isExpanded
                                                ? currentSurfaceTextureList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {textureExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / Laminate category Width in ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    Width (in.)
                                  </button>
                                </h2>

                                <div
                                  id="collapseSeven"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentWidthList
                                        .slice(0, displayedWidthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `width-${index}`; // Unique ID for each checkbox
                                          const count =
                                            laminateWidthCounts[item.width] ||
                                            0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "width",
                                                    item.width
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "width",
                                                      item.width
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.width} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !widthExpanded; // Toggle the expanded state
                                            setWidthExpanded(isExpanded); // Update the expanded state
                                            setDisplayedWidthLimit(
                                              isExpanded
                                                ? currentWidthList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {widthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *********************Flooring / Laminate category Length (in .)*********  */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#vinylLength"
                                    aria-expanded="false"
                                    aria-controls="vinylLength"
                                  >
                                    Length (in.)
                                  </button>
                                </h2>

                                <div
                                  id="vinylLength"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentLengthList
                                        .slice(0, displayedVinylLengthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `length-${index}`; // Unique ID for each checkbox
                                          const count =
                                            laminateLengthCounts[item.length] ||
                                            0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedLength.includes(
                                                  item.length
                                                )}
                                                onChange={() => {
                                                  const updatedLength =
                                                    selectedLength.includes(
                                                      item.length
                                                    )
                                                      ? selectedLength.filter(
                                                          (len) =>
                                                            len !== item.length
                                                        )
                                                      : [
                                                          ...selectedLength,
                                                          item.length,
                                                        ];

                                                  setSelectedLength(
                                                    updatedLength
                                                  );
                                                  // Assuming handleFilterChange can handle updates for vinyl length
                                                  handleFilterChange(
                                                    "length",
                                                    updatedLength
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.length} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentLengthList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !vinylLengthExpanded;
                                            setVinylLengthExpanded(isExpanded);
                                            setDisplayedVinylLengthLimit(
                                              isExpanded
                                                ? currentLengthList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {vinylLengthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* ******************** Flooring / Laminate Finish ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFinish"
                                    aria-expanded="false"
                                    aria-controls="collapseFinish"
                                  >
                                    Finish
                                  </button>
                                </h2>
                                <div
                                  id="collapseFinish"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsLaminateFinish
                                        .slice(0, displayedFinishLimit)
                                        .map((item, index) => {
                                          const checkboxId = `finish-${index}`; // Unique ID for each checkbox
                                          const count =
                                            laminateFinishCounts[item.Finish] ||
                                            0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedLaminateFinish.includes(
                                                    item.Finish
                                                  )}
                                                  onChange={() => {
                                                    const updatedFinish =
                                                      selectedLaminateFinish.includes(
                                                        item.Finish
                                                      )
                                                        ? selectedLaminateFinish.filter(
                                                            (finish) =>
                                                              finish !==
                                                              item.Finish
                                                          )
                                                        : [
                                                            ...selectedLaminateFinish,
                                                            item.Finish,
                                                          ];
                                                    setSelectedLaminateFinish(
                                                      updatedFinish
                                                    );
                                                    // Invoke your filtering logic here
                                                    handleFilterChange(
                                                      "Finish",
                                                      updatedFinish
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Finish} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsLaminateFinish.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !finishExpanded; // Toggle the expanded state
                                            setFinishExpanded(isExpanded); // Update the expanded state
                                            setDisplayedFinishLimit(
                                              isExpanded
                                                ? menuItemsLaminateFinish.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {finishExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring / Laminate Sub End ********* */}
                            </>
                          )}

                          {/* ******************** Flooring / Laminate ALL End ********* */}
                          {/* ******************** Tiles ALL START ********* */}
                          {(currentShownCategoryName.includes(" Tiles") ||
                            currentShownCategoryName.includes("Mosaic")) && (
                            <>
                              {/* ******************** Flooring / Wall Tile Size********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileSize"
                                    aria-expanded="false"
                                    aria-controls="collapseTileSize"
                                  >
                                    Approximate Size (in.)
                                  </button>
                                </h2>
                                <div
                                  id="collapseTileSize"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileSizeList
                                        .slice(0, displayedWallTileSizeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `size-${index}`;
                                          const count =
                                            tileSizeCounts[
                                              item.Approximate_sizeIN
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedWallTileSize.includes(
                                                    item.Approximate_sizeIN
                                                  )}
                                                  onChange={() => {
                                                    const updatedWallTileSize =
                                                      selectedWallTileSize.includes(
                                                        item.Approximate_sizeIN
                                                      )
                                                        ? selectedWallTileSize.filter(
                                                            (size) =>
                                                              size !==
                                                              item.Approximate_sizeIN
                                                          )
                                                        : [
                                                            ...selectedWallTileSize,
                                                            item.Approximate_sizeIN,
                                                          ];
                                                    setSelectedWallTileSize(
                                                      updatedWallTileSize
                                                    );
                                                    // Call your filtering logic with updated selections
                                                    handleFilterChange(
                                                      "Approximate_sizeIN",
                                                      updatedWallTileSize
                                                    );
                                                  }}
                                                />
                                                <label htmlFor={checkboxId}>
                                                  {item.display} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentTileSizeList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !wallTileSizeExpanded;
                                            setWallTileSizeExpanded(isExpanded);
                                            setDisplayedWallTileSizeLimit(
                                              isExpanded
                                                ? currentTileSizeList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {wallTileSizeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring /  Tile Shape ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileShape"
                                    aria-expanded="false"
                                    aria-controls="collapseTileShape"
                                  >
                                    Shape
                                  </button>
                                </h2>
                                <div
                                  id="collapseTileShape"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileShapeList
                                        .slice(0, displayedTileShapeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `shape-${index}`;
                                          const count =
                                            tileShapeCounts[item.shape] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedTileShape.includes(
                                                    item.shape
                                                  )}
                                                  onChange={() => {
                                                    const updatedTileShape =
                                                      selectedTileShape.includes(
                                                        item.shape
                                                      )
                                                        ? selectedTileShape.filter(
                                                            (sp) =>
                                                              sp !== item.shape
                                                          )
                                                        : [
                                                            ...selectedTileShape,
                                                            item.shape,
                                                          ];
                                                    setSelectedTileShape(
                                                      updatedTileShape
                                                    );
                                                    handleFilterChange(
                                                      "shape",
                                                      updatedTileShape
                                                    );
                                                  }}
                                                />
                                                <label htmlFor={checkboxId}>
                                                  {item.shape} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentTileShapeList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !tileShapeExpanded;
                                            setTileShapeExpanded(isExpanded);
                                            setDisplayedTileShapeLimit(
                                              isExpanded
                                                ? currentTileShapeList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {tileShapeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* ******************** Flooring /  Tile Look ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileLook"
                                    aria-expanded="false"
                                    aria-controls="collapseTileLook"
                                  >
                                    Look
                                  </button>
                                </h2>
                                <div
                                  id="collapseTileLook"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileLookList
                                        .slice(0, displayedTileLookLimit)
                                        .map((item, index) => {
                                          const checkboxId = `look-${item.Species}-${index}`;
                                          const count =
                                            tileLookCounts[item.Species] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedTileLook.includes(
                                                  item.Species
                                                )}
                                                onChange={() => {
                                                  const updatedTileLook =
                                                    selectedTileLook.includes(
                                                      item.Species
                                                    )
                                                      ? selectedTileLook.filter(
                                                          (look) =>
                                                            look !==
                                                            item.Species
                                                        )
                                                      : [
                                                          ...selectedTileLook,
                                                          item.Species,
                                                        ];
                                                  setSelectedTileLook(
                                                    updatedTileLook
                                                  );
                                                  handleFilterChange(
                                                    "Species",
                                                    updatedTileLook
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.display} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentTileLookList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !tileLookExpanded;
                                            setTileLookExpanded(isExpanded);
                                            setDisplayedTileLookLimit(
                                              isExpanded
                                                ? currentTileLookList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {tileLookExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring / Wall Tile Finish ********* */}

                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileFinish"
                                    aria-expanded="false"
                                    aria-controls="collapseTileFinish"
                                  >
                                    Finish
                                  </button>
                                </h2>
                                <div
                                  id="collapseTileFinish"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileFinishList
                                        .slice(0, displayedTileFinishLimit)
                                        .map((item, index) => {
                                          const checkboxId = `finish-${item.Finish}-${index}`;
                                          const count =
                                            tileFinishCounts[item.Finish] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedTileFinish.includes(
                                                  item.Finish
                                                )}
                                                onChange={() => {
                                                  const updatedTileFinish =
                                                    selectedTileFinish.includes(
                                                      item.Finish
                                                    )
                                                      ? selectedTileFinish.filter(
                                                          (finish) =>
                                                            finish !==
                                                            item.Finish
                                                        )
                                                      : [
                                                          ...selectedTileFinish,
                                                          item.Finish,
                                                        ];
                                                  setSelectedTileFinish(
                                                    updatedTileFinish
                                                  );
                                                  handleFilterChange(
                                                    "Finish",
                                                    updatedTileFinish
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.display} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentTileFinishList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !tileFinishExpanded;
                                            setTileFinishExpanded(isExpanded);
                                            setDisplayedTileFinishLimit(
                                              isExpanded
                                                ? currentTileFinishList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {tileFinishExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring / Wall Tile Material ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileMaterial"
                                    aria-expanded="false"
                                    aria-controls="collapseTileMaterial"
                                  >
                                    Material
                                  </button>
                                </h2>

                                <div
                                  id="collapseTileMaterial"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileMaterialList
                                        .slice(0, displayedTileMaterialLimit)
                                        .map((item, index) => {
                                          const checkboxId = `material-${item.Material}-${index}`;
                                          const count =
                                            tileMaterialCounts[item.Material] ||
                                            0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedTileMaterial.includes(
                                                  item.Material
                                                )}
                                                onChange={() => {
                                                  const updatedTileMaterial =
                                                    selectedTileMaterial.includes(
                                                      item.Material
                                                    )
                                                      ? selectedTileMaterial.filter(
                                                          (material) =>
                                                            material !==
                                                            item.Material
                                                        )
                                                      : [
                                                          ...selectedTileMaterial,
                                                          item.Material,
                                                        ];
                                                  setSelectedTileMaterial(
                                                    updatedTileMaterial
                                                  );
                                                  handleFilterChange(
                                                    "Material",
                                                    updatedTileMaterial
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.display} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentTileMaterialList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !tileMaterialExpanded;
                                            setTileMaterialExpanded(isExpanded);
                                            setDisplayedTileMaterialLimit(
                                              isExpanded
                                                ? currentTileMaterialList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {tileMaterialExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring / Wall Tile PEI Rating ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTilePEIRating"
                                    aria-expanded="false"
                                    aria-controls="collapseTilePEIRating"
                                  >
                                    PEI Rating
                                  </button>
                                </h2>

                                <div
                                  id="collapseTilePEIRating"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTilePEIRatingList
                                        .slice(0, displayedTilePEIRatingLimit)
                                        .map((item, index) => {
                                          const checkboxId = `pei-rating-${item.PEI_Rating}`;
                                          const count =
                                            tilePEICounts[item.PEI_Rating] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedTilePEIRating.includes(
                                                  item.PEI_Rating
                                                )}
                                                onChange={() => {
                                                  const updatedTilePEIRating =
                                                    selectedTilePEIRating.includes(
                                                      item.PEI_Rating
                                                    )
                                                      ? selectedTilePEIRating.filter(
                                                          (rating) =>
                                                            rating !==
                                                            item.PEI_Rating
                                                        )
                                                      : [
                                                          ...selectedTilePEIRating,
                                                          item.PEI_Rating,
                                                        ];
                                                  setSelectedTilePEIRating(
                                                    updatedTilePEIRating
                                                  );
                                                  handleFilterChange(
                                                    "PEI_Rating",
                                                    updatedTilePEIRating
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.PEI_Rating} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentTilePEIRatingList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !tilePEIRatingExpanded;
                                            setTilePEIRatingExpanded(
                                              isExpanded
                                            );
                                            setDisplayedTilePEIRatingLimit(
                                              isExpanded
                                                ? currentTilePEIRatingList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {tilePEIRatingExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Flooring / Wall Tile Edge Type ********* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileEdgeType"
                                    aria-expanded="false"
                                    aria-controls="collapseTileEdgeType"
                                  >
                                    Edge Type
                                  </button>
                                </h2>

                                <div
                                  id="collapseTileEdgeType"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileEdgeTypeList
                                        .slice(0, displayedTileEdgeTypeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `edge-type-${item.edgeType}`;
                                          const count =
                                            tileEdgeTypeCounts[item.edgeType] ||
                                            0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedTileEdgeType.includes(
                                                  item.edgeType
                                                )}
                                                onChange={() => {
                                                  const updatedTileEdgeType =
                                                    selectedTileEdgeType.includes(
                                                      item.edgeType
                                                    )
                                                      ? selectedTileEdgeType.filter(
                                                          (type) =>
                                                            type !==
                                                            item.edgeType
                                                        )
                                                      : [
                                                          ...selectedTileEdgeType,
                                                          item.edgeType,
                                                        ];
                                                  setSelectedTileEdgeType(
                                                    updatedTileEdgeType
                                                  );
                                                  handleFilterChange(
                                                    "edgeType",
                                                    updatedTileEdgeType
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.display} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentTileEdgeTypeList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !tileEdgeTypeExpanded;
                                            setTileEdgeTypeExpanded(isExpanded);
                                            setDisplayedTileEdgeTypeLimit(
                                              isExpanded
                                                ? currentTileEdgeTypeList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {tileEdgeTypeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* ******************** Tile Sub End ********* */}
                            </>
                          )}
                          {/* ******************** Tile END e********* */}

                          {/* ***********Flooring / Cork Flooring **** */}
                          {currentShownCategoryName.includes(
                            "Cork Flooring"
                          ) && (
                            <>
                              {/* *************** Flooring / Cork Flooring thickness ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    Thickness (mm)
                                  </button>
                                </h2>
                                <div
                                  id="collapseFive"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentThicknessMMList
                                        .slice(0, displayedThicknessLimit)
                                        .map((item, index) => {
                                          const checkboxId = `corkThickness-${index}`; // Unique ID for each checkbox
                                          const count =
                                            corkFloorThicknessMMCounts[
                                              item.thickness
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedThickness.includes(
                                                    item.thickness
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedThickness.includes(
                                                        item.thickness
                                                      )
                                                        ? selectedThickness.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.thickness
                                                          )
                                                        : [
                                                            ...selectedThickness,
                                                            item.thickness,
                                                          ];
                                                    setSelectedThickness(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "thickness",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !thicknessExpanded; // Toggle the expanded state
                                            setThicknessExpanded(isExpanded); // Update the expanded state
                                            setDisplayedThicknessLimit(
                                              isExpanded
                                                ? currentThicknessMMList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {thicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / Cork Flooring Width in ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    Width (in.)
                                  </button>
                                </h2>

                                <div
                                  id="collapseSeven"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentWidthList
                                        .slice(0, displayedWidthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `corkWidth-${index}`; // Unique ID for each checkbox
                                          const count =
                                            corkFloorWidthCounts[item.width] ||
                                            0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedWidth.includes(
                                                    item.width
                                                  )}
                                                  onChange={() => {
                                                    const updatedWidth =
                                                      selectedWidth.includes(
                                                        item.width
                                                      )
                                                        ? selectedWidth.filter(
                                                            (wt) =>
                                                              wt !== item.width
                                                          )
                                                        : [
                                                            ...selectedWidth,
                                                            item.width,
                                                          ];
                                                    setSelectedWidth(
                                                      updatedWidth
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "width",
                                                      updatedWidth
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.width} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !widthExpanded; // Toggle the expanded state
                                            setWidthExpanded(isExpanded); // Update the expanded state
                                            setDisplayedWidthLimit(
                                              isExpanded
                                                ? currentWidthList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {widthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *********************Flooring / Cork Flooring Length (in .)*********  */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#vinylLength"
                                    aria-expanded="false"
                                    aria-controls="vinylLength"
                                  >
                                    Length (in.)
                                  </button>
                                </h2>

                                <div
                                  id="vinylLength"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentLengthList
                                        .slice(0, displayedVinylLengthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `length-${index}`; // Unique ID for each checkbox
                                          const count =
                                            corkFloorLengthCounts[
                                              item.length
                                            ] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedLength.includes(
                                                  item.length
                                                )}
                                                onChange={() => {
                                                  const updatedLength =
                                                    selectedLength.includes(
                                                      item.length
                                                    )
                                                      ? selectedLength.filter(
                                                          (len) =>
                                                            len !== item.length
                                                        )
                                                      : [
                                                          ...selectedLength,
                                                          item.length,
                                                        ];

                                                  setSelectedLength(
                                                    updatedLength
                                                  );
                                                  // Assuming handleFilterChange can handle updates for vinyl length
                                                  handleFilterChange(
                                                    "length",
                                                    updatedLength
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.length} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {currentLengthList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !vinylLengthExpanded;
                                            setVinylLengthExpanded(isExpanded);
                                            setDisplayedVinylLengthLimit(
                                              isExpanded
                                                ? currentLengthList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {vinylLengthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* ***********Flooring / Carpet Flooring **** */}
                          {currentShownCategoryName.includes("Carpets") && (
                            <>
                              {/* *************** Flooring / Carpets Size ******* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileSize"
                                    aria-expanded="false"
                                    aria-controls="collapseTileSize"
                                  >
                                    Approximate Size (in.)
                                  </button>
                                </h2>
                                <div
                                  id="collapseTileSize"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileSizeList
                                        .slice(0, displayedWallTileSizeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `carpetSize-${index}`;
                                          const count =
                                            corkFloorSizeCounts[
                                              item.Approximate_sizeIN
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedWallTileSize.includes(
                                                    item.Approximate_sizeIN
                                                  )}
                                                  onChange={() => {
                                                    const updatedWallTileSize =
                                                      selectedWallTileSize.includes(
                                                        item.Approximate_sizeIN
                                                      )
                                                        ? selectedWallTileSize.filter(
                                                            (size) =>
                                                              size !==
                                                              item.Approximate_sizeIN
                                                          )
                                                        : [
                                                            ...selectedWallTileSize,
                                                            item.Approximate_sizeIN,
                                                          ];
                                                    setSelectedWallTileSize(
                                                      updatedWallTileSize
                                                    );
                                                    // Call your filtering logic with updated selections
                                                    handleFilterChange(
                                                      "Approximate_sizeIN",
                                                      updatedWallTileSize
                                                    );
                                                  }}
                                                />
                                                <label htmlFor={checkboxId}>
                                                  {item.display} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentTileSizeList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !wallTileSizeExpanded;
                                            setWallTileSizeExpanded(isExpanded);
                                            setDisplayedWallTileSizeLimit(
                                              isExpanded
                                                ? currentTileSizeList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {wallTileSizeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / Carpets Fiber Material ******* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTileSize"
                                    aria-expanded="false"
                                    aria-controls="collapseTileSize"
                                  >
                                    Fiber Material
                                  </button>
                                </h2>
                                <div
                                  id="collapseFiberMaterial"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetMaterial
                                        .slice(0, displayedWallTileSizeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `carpetMaterial-${index}`;
                                          const count =
                                            carpetFiberMaterialCounts[
                                              item.Material
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedWallTileSize.includes(
                                                    item.Material
                                                  )}
                                                  onChange={() => {
                                                    const updatedWallTileSize =
                                                      selectedWallTileSize.includes(
                                                        item.Material
                                                      )
                                                        ? selectedWallTileSize.filter(
                                                            (size) =>
                                                              size !==
                                                              item.Material
                                                          )
                                                        : [
                                                            ...selectedWallTileSize,
                                                            item.Material,
                                                          ];
                                                    setSelectedWallTileSize(
                                                      updatedWallTileSize
                                                    );
                                                    // Call your filtering logic with updated selections
                                                    handleFilterChange(
                                                      "Material",
                                                      updatedWallTileSize
                                                    );
                                                  }}
                                                />
                                                <label htmlFor={checkboxId}>
                                                  {item.Material} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsCarpetMaterial.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !wallTileSizeExpanded;
                                            setWallTileSizeExpanded(isExpanded);
                                            setDisplayedWallTileSizeLimit(
                                              isExpanded
                                                ? menuItemsCarpetMaterial.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {wallTileSizeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / Carpets Flooring thickness ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    Thickness (mm)
                                  </button>
                                </h2>
                                <div
                                  id="collapseFive"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentThicknessMMList
                                        .slice(0, displayedThicknessLimit)
                                        .map((item, index) => {
                                          const checkboxId = `carpetsThickness-${index}`; // Unique ID for each checkbox
                                          const count =
                                            carpetThicknessCounts[
                                              item.thickness
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedThickness.includes(
                                                    item.thickness
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedThickness.includes(
                                                        item.thickness
                                                      )
                                                        ? selectedThickness.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.thickness
                                                          )
                                                        : [
                                                            ...selectedThickness,
                                                            item.thickness,
                                                          ];
                                                    setSelectedThickness(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "thickness",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !thicknessExpanded; // Toggle the expanded state
                                            setThicknessExpanded(isExpanded); // Update the expanded state
                                            setDisplayedThicknessLimit(
                                              isExpanded
                                                ? currentThicknessMMList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {thicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *********************Flooring / Carpet Pile Weight *********  */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseCarpetWeight"
                                    aria-expanded="false"
                                    aria-controls="collapseCarpetWeight"
                                  >
                                    Pile Weight (oz/sqyd)
                                  </button>
                                </h2>
                                <div
                                  id="collapseCarpetWeight"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetPileWeight
                                        .slice(
                                          0,
                                          displayedCarpetsPileWeightLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `carpetsPileWeight-${index}`; // Unique ID for each checkbox
                                          const count =
                                            carpetWeightCounts[item.Species] ||
                                            0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedCarpetsPileWeight.includes(
                                                    item.Species
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedCarpetsPileWeight.includes(
                                                        item.Species
                                                      )
                                                        ? selectedCarpetsPileWeight.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.Species
                                                          )
                                                        : [
                                                            ...selectedCarpetsPileWeight,
                                                            item.Species,
                                                          ];
                                                    setSelectedCarpetsPileWeight(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Species",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.display} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}

                                      {menuItemsCarpetPileWeight.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !carpetsPileWeightExpanded; // Toggle the expanded state
                                            setCarpetsPileWeightExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedCarpetsPileWeightLimit(
                                              isExpanded
                                                ? menuItemsCarpetPileWeight.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {carpetsPileWeightExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************Flooring / Carpets Linear Density ******/}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseCarpetLinearDensity"
                                    aria-expanded="false"
                                    aria-controls="collapseCarpetLinearDensity"
                                  >
                                    Carpet Linear Density (ktex)
                                  </button>
                                </h2>
                                <div
                                  id="collapseCarpetLinearDensity"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetLinearDensity
                                        .slice(
                                          0,
                                          displayedCarpetLinearDensityLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `carpetsPileWeight-${index}`; // Unique ID for each checkbox
                                          const count =
                                            carpetLinearDensityCounts[
                                              item.Surface_Texture
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedCarpetLinearDensity.includes(
                                                    item.Surface_Texture
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedCarpetLinearDensity.includes(
                                                        item.Surface_Texture
                                                      )
                                                        ? selectedCarpetLinearDensity.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.Surface_Texture
                                                          )
                                                        : [
                                                            ...selectedCarpetLinearDensity,
                                                            item.Surface_Texture,
                                                          ];
                                                    setSelectedCarpetLinearDensity(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Surface_Texture",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Surface_Texture} (
                                                  {count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}

                                      {menuItemsCarpetLinearDensity.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !carpetLinearDensityExpanded; // Toggle the expanded state
                                            setCarpetLinearDensityExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedCarpetLinearDensityLimit(
                                              isExpanded
                                                ? menuItemsCarpetLinearDensity.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {carpetLinearDensityExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************Flooring / Carpets Pile Construction Method ******/}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseCarpetConstructionMethod"
                                    aria-expanded="false"
                                    aria-controls="collapseCarpetConstructionMethod"
                                  >
                                    Pile Construction Method
                                  </button>
                                </h2>
                                <div
                                  id="collapseCarpetConstructionMethod"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetPileConstructionMethod
                                        .slice(
                                          0,
                                          displayedCarpetPileConstructionMethodLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `carpetsPileConstructionMethod-${index}`; // Unique ID for each checkbox
                                          const count =
                                            carpetPileMethodCounts[
                                              item.edgeType
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedCarpetPileConstructionMethod.includes(
                                                    item.edgeType
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedCarpetPileConstructionMethod.includes(
                                                        item.edgeType
                                                      )
                                                        ? selectedCarpetPileConstructionMethod.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.edgeType
                                                          )
                                                        : [
                                                            ...selectedCarpetPileConstructionMethod,
                                                            item.edgeType,
                                                          ];
                                                    setSelectedCarpetPileConstructionMethod(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "edgeType",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.edgeType} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}

                                      {menuItemsCarpetPileConstructionMethod.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !carpetPileConstructionMethodExpanded; // Toggle the expanded state
                                            setCarpetPileConstructionMethodExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedCarpetPileConstructionMethodLimit(
                                              isExpanded
                                                ? menuItemsCarpetPileConstructionMethod.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {carpetPileConstructionMethodExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************Flooring / Carpets Onces ******/}

                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseCarpetOnces"
                                    aria-expanded="false"
                                    aria-controls="collapseCarpetOnces"
                                  >
                                    Onces
                                  </button>
                                </h2>
                                <div
                                  id="collapseCarpetOnces"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetOunces
                                        .slice(0, displayedCarpetOuncesLimit)
                                        .map((item, index) => {
                                          const checkboxId = `carpetsPileWeight-${index}`; // Unique ID for each checkbox
                                          const count =
                                            carpetOncesCounts[item.Onces] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedCarpetOunces.includes(
                                                    item.Onces
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedCarpetOunces.includes(
                                                        item.Onces
                                                      )
                                                        ? selectedCarpetOunces.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.Onces
                                                          )
                                                        : [
                                                            ...selectedCarpetOunces,
                                                            item.Onces,
                                                          ];
                                                    setSelectedCarpetOunces(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Onces",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Onces} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsCarpetOunces.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !carpetOuncesExpanded; // Toggle the expanded state
                                            setCarpetOuncesExpanded(isExpanded); // Update the expanded state
                                            setDisplayedCarpetOuncesLimit(
                                              isExpanded
                                                ? menuItemsCarpetOunces.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {carpetOuncesExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************Flooring / Density ******/}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseCarpetDensity"
                                    aria-expanded="false"
                                    aria-controls="collapseCarpetDensity"
                                  >
                                    Density
                                  </button>
                                </h2>
                                <div
                                  id="collapseCarpetDensity"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetDensity
                                        .slice(0, displayedCarpetDensityLimit)
                                        .map((item, index) => {
                                          const checkboxId = `carpetsDensity-${index}`; // Unique ID for each checkbox
                                          const count =
                                            carpetDensityCounts[item.Density] ||
                                            0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedCarpetDensity.includes(
                                                    item.Density
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedCarpetDensity.includes(
                                                        item.Density
                                                      )
                                                        ? selectedCarpetDensity.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.Density
                                                          )
                                                        : [
                                                            ...selectedCarpetDensity,
                                                            item.Density,
                                                          ];
                                                    setSelectedCarpetDensity(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Density",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Density} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsCarpetDensity.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !carpetDensityExpanded; // Toggle the expanded state
                                            setCarpetDensityExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedCarpetDensityLimit(
                                              isExpanded
                                                ? menuItemsCarpetDensity.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {carpetDensityExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************Flooring / Carpets sub End ******/}
                            </>
                          )}
                          {/* *************Flooring / Carpets ALL End ******/}
                          {/* *************Flooring / Sport Flooring ALL START ******/}
                          {currentShownCategoryName.includes("Sport") && (
                            <>
                              {/* *************** Flooring / Carpets Size ******* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSportSize"
                                    aria-expanded="false"
                                    aria-controls="collapseSportSize"
                                  >
                                    Approximate Size (in.)
                                  </button>
                                </h2>
                                <div
                                  id="collapseSportSize"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentTileSizeList
                                        .slice(0, displayedWallTileSizeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `sportSize-${index}`;
                                          const count =
                                            sportFloorSizeCounts[
                                              item.Approximate_sizeIN
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedWallTileSize.includes(
                                                    item.Approximate_sizeIN
                                                  )}
                                                  onChange={() => {
                                                    const updatedWallTileSize =
                                                      selectedWallTileSize.includes(
                                                        item.Approximate_sizeIN
                                                      )
                                                        ? selectedWallTileSize.filter(
                                                            (size) =>
                                                              size !==
                                                              item.Approximate_sizeIN
                                                          )
                                                        : [
                                                            ...selectedWallTileSize,
                                                            item.Approximate_sizeIN,
                                                          ];
                                                    setSelectedWallTileSize(
                                                      updatedWallTileSize
                                                    );
                                                    // Call your filtering logic with updated selections
                                                    handleFilterChange(
                                                      "Approximate_sizeIN",
                                                      updatedWallTileSize
                                                    );
                                                  }}
                                                />
                                                <label htmlFor={checkboxId}>
                                                  {item.display} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentTileSizeList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !wallTileSizeExpanded;
                                            setWallTileSizeExpanded(isExpanded);
                                            setDisplayedWallTileSizeLimit(
                                              isExpanded
                                                ? currentTileSizeList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {wallTileSizeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / Sport Flooring thickness ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSportThickness"
                                    aria-expanded="false"
                                    aria-controls="collapseSportThickness"
                                  >
                                    Thickness (mm)
                                  </button>
                                </h2>
                                <div
                                  id="collapseSportThickness"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {currentThicknessMMList
                                        .slice(0, displayedThicknessLimit)
                                        .map((item, index) => {
                                          const checkboxId = `sportThickness-${index}`; // Unique ID for each checkbox
                                          const count =
                                            sportFloorThicknessCounts[
                                              item.thickness
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedThickness.includes(
                                                    item.thickness
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedThickness.includes(
                                                        item.thickness
                                                      )
                                                        ? selectedThickness.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.thickness
                                                          )
                                                        : [
                                                            ...selectedThickness,
                                                            item.thickness,
                                                          ];
                                                    setSelectedThickness(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "thickness",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !thicknessExpanded; // Toggle the expanded state
                                            setThicknessExpanded(isExpanded); // Update the expanded state
                                            setDisplayedThicknessLimit(
                                              isExpanded
                                                ? currentThicknessMMList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {thicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / Sport Material ******* */}
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSportMaterial"
                                    aria-expanded="false"
                                    aria-controls="collapseSportMaterial"
                                  >
                                    Material
                                  </button>
                                </h2>
                                <div
                                  id="collapseSportMaterial"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsSportMaterial
                                        .slice(0, displayedWallTileSizeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `sportMaterial-${index}`;
                                          const count =
                                            sportFloorMaterialCounts[
                                              item.Material
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedWallTileSize.includes(
                                                    item.Material
                                                  )}
                                                  onChange={() => {
                                                    const updatedWallTileSize =
                                                      selectedWallTileSize.includes(
                                                        item.Material
                                                      )
                                                        ? selectedWallTileSize.filter(
                                                            (size) =>
                                                              size !==
                                                              item.Material
                                                          )
                                                        : [
                                                            ...selectedWallTileSize,
                                                            item.Material,
                                                          ];
                                                    setSelectedWallTileSize(
                                                      updatedWallTileSize
                                                    );
                                                    // Call your filtering logic with updated selections
                                                    handleFilterChange(
                                                      "Material",
                                                      updatedWallTileSize
                                                    );
                                                  }}
                                                />
                                                <label htmlFor={checkboxId}>
                                                  {item.Material} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsSportMaterial.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !wallTileSizeExpanded;
                                            setWallTileSizeExpanded(isExpanded);
                                            setDisplayedWallTileSizeLimit(
                                              isExpanded
                                                ? menuItemsSportMaterial.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {wallTileSizeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************Flooring / Sport Onces ******/}

                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSportOnces"
                                    aria-expanded="false"
                                    aria-controls="collapseSportOnces"
                                  >
                                    Onces
                                  </button>
                                </h2>
                                <div
                                  id="collapseSportOnces"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetOunces
                                        .slice(0, displayedSportOuncesLimit)
                                        .map((item, index) => {
                                          const checkboxId = `sportPileWeight-${index}`; // Unique ID for each checkbox
                                          const count =
                                            sportFloorOncesCounts[item.Onces] ||
                                            0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedSportOunces.includes(
                                                    item.Onces
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedSportOunces.includes(
                                                        item.Onces
                                                      )
                                                        ? selectedSportOunces.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.Onces
                                                          )
                                                        : [
                                                            ...selectedSportOunces,
                                                            item.Onces,
                                                          ];
                                                    setSelectedSportOunces(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Onces",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Onces} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsCarpetOunces.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !sportOuncesExpanded; // Toggle the expanded state
                                            setSportOuncesExpanded(isExpanded); // Update the expanded state
                                            setDisplayedSportOuncesLimit(
                                              isExpanded
                                                ? menuItemsCarpetOunces.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {sportOuncesExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************Flooring / Sport Density ******/}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSportDensity"
                                    aria-expanded="false"
                                    aria-controls="collapseSportDensity"
                                  >
                                    Density
                                  </button>
                                </h2>
                                <div
                                  id="collapseSportDensity"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsCarpetDensity
                                        .slice(0, displayedSportDensityLimit)
                                        .map((item, index) => {
                                          const checkboxId = `sportDensity-${index}`; // Unique ID for each checkbox
                                          const count =
                                            sportFloorDensityCounts[
                                              item.Density
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedSportDensity.includes(
                                                    item.Density
                                                  )}
                                                  onChange={() => {
                                                    const updatedThickness =
                                                      selectedSportDensity.includes(
                                                        item.Density
                                                      )
                                                        ? selectedSportDensity.filter(
                                                            (thick) =>
                                                              thick !==
                                                              item.Density
                                                          )
                                                        : [
                                                            ...selectedCarpetDensity,
                                                            item.Density,
                                                          ];
                                                    setSelectedSportDensity(
                                                      updatedThickness
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Density",
                                                      updatedThickness
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Density} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsCarpetDensity.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !sportDensityExpanded; // Toggle the expanded state
                                            setSportDensityExpanded(isExpanded); // Update the expanded state
                                            setDisplayedSportDensityLimit(
                                              isExpanded
                                                ? menuItemsCarpetDensity.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {sportDensityExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************** Flooring / Sport installation method  ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#sportInstallationMethod"
                                    aria-expanded="false"
                                    aria-controls="sportInstallationMethod"
                                  >
                                    Installation Method
                                  </button>
                                </h2>

                                <div
                                  id="sportInstallationMethod"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsSportInstallation
                                        .slice(
                                          0,
                                          displayedSportInstallationMethodLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `sportInstallation-${index}`; // Unique ID for each checkbox
                                          const count =
                                            sportInstallationMethodCounts[
                                              item.installMethod
                                            ] || 0;

                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedSportInstallationMethod.includes(
                                                    item.installMethod
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedSportInstallationMethod.includes(
                                                        item.installMethod
                                                      )
                                                        ? selectedSportInstallationMethod.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.installMethod
                                                          )
                                                        : [
                                                            ...selectedSportInstallationMethod,
                                                            item.installMethod,
                                                          ];

                                                    setSelectedSportInstallationMethod(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "installMethod",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.installMethod} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsSportInstallation.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !sportInstallationMethodExpanded; // Toggle the expanded state
                                            setSportInstallationMethodExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedSportInstallationMethodLimit(
                                              isExpanded
                                                ? menuItemsInstallation.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {sportInstallationMethodExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************** Flooring / Sport Sub End  ******* */}
                            </>
                          )}
                          {/* *************** Flooring / Sport All End  ******* */}
                          {/* ************* Membranes ALL START ******/}
                          {currentShownCategoryName.includes("Membranes") && (
                            <>
                              {/* *************** Membranes sub START  ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#membrancesCoverage"
                                    aria-expanded="false"
                                    aria-controls="membrancesCoverage"
                                  >
                                    Coverage (sqft)
                                  </button>
                                </h2>

                                <div
                                  id="membrancesCoverage"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsMembranesCoverage
                                        .slice(
                                          0,
                                          displayedMembranesCoverageLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `mbCoverage-${index}`; // Unique ID for each checkbox
                                          const count =
                                            membranesCoverageCounts[
                                              item.covrage
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedMembranesCoverage.includes(
                                                    item.covrage
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedMembranesCoverage.includes(
                                                        item.covrage
                                                      )
                                                        ? selectedMembranesCoverage.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.covrage
                                                          )
                                                        : [
                                                            ...selectedMembranesCoverage,
                                                            item.covrage,
                                                          ];

                                                    setSelectedMembranesCoverage(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "covrage",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.covrage} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsMembranesCoverage.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !membranesCoverageExpanded; // Toggle the expanded state
                                            setMembranesCoverageExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedMembranesCoverageLimit(
                                              isExpanded
                                                ? menuItemsMembranesCoverage.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {membranesCoverageExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************** MembranesCoverage Sub End  ******* */}
                              {/* *************** Membranes Thickness mm sub START  ******* */}
                              {currentShownSubCategoryName.includes(
                                "Heating"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#membrancesThickiness"
                                        aria-expanded="false"
                                        aria-controls="membrancesThickiness"
                                      >
                                        Thickness (mm)
                                      </button>
                                    </h2>

                                    <div
                                      id="membrancesThickiness"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsMembranesOnlyThicknessMM
                                            .slice(
                                              0,
                                              displayedMembranesThicknessLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `mbThick-${index}`; // Unique ID for each checkbox
                                              const count =
                                                membranesThicknessMMCounts[
                                                  item.thickness
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedMembranesThickness.includes(
                                                        item.thickness
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedMembranesThickness.includes(
                                                            item.thickness
                                                          )
                                                            ? selectedMembranesThickness.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.thickness
                                                              )
                                                            : [
                                                                ...selectedMembranesThickness,
                                                                item.thickness,
                                                              ];

                                                        setSelectedMembranesThickness(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "thickness",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.thickness} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsMembranesOnlyThicknessMM.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !membranesThicknessExpanded; // Toggle the expanded state
                                                setMembranesThicknessExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedMembranesThicknessLimit(
                                                  isExpanded
                                                    ? menuItemsMembranesOnlyThicknessMM.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {membranesThicknessExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* *************** Membranes Thickness Sub End  ******* */}
                              {/* *************** Membranes Thickness inch sub START  ******* */}
                              {currentShownSubCategoryName.includes(
                                "Heating"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#membrancesThickinessInch"
                                        aria-expanded="false"
                                        aria-controls="membrancesThickinessInch"
                                      >
                                        Thickness (inches in fraction)
                                      </button>
                                    </h2>

                                    <div
                                      id="membrancesThickinessInch"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsMembranesThicknessFraction
                                            .slice(
                                              0,
                                              displayedMembranesThicknessInchLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `mbThickInch-${index}`; // Unique ID for each checkbox
                                              const count =
                                                membranesThicknessInchCounts[
                                                  item.wearlayerthickness
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedMembranesThicknessInch.includes(
                                                        item.wearlayerthickness
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedMembranesThicknessInch.includes(
                                                            item.wearlayerthickness
                                                          )
                                                            ? selectedMembranesThicknessInch.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.wearlayerthickness
                                                              )
                                                            : [
                                                                ...selectedMembranesThicknessInch,
                                                                item.wearlayerthickness,
                                                              ];

                                                        setSelectedMembranesThicknessInch(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "wearlayerthickness",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.wearlayerthickness}{" "}
                                                      ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsMembranesThicknessFraction.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !membranesThicknessInchExpanded; // Toggle the expanded state
                                                setMembranesThicknessInchExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedMembranesThicknessInchLimit(
                                                  isExpanded
                                                    ? menuItemsMembranesThicknessFraction.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {membranesThicknessInchExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* *************** Membranes Thickness Inch Sub End  ******* */}
                              {/* *************** Membranes Heating Type sub START  ******* */}
                              {currentShownSubCategoryName.includes(
                                "Heating"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#membrancesHeatType"
                                        aria-expanded="false"
                                        aria-controls="membrancesHeatType"
                                      >
                                        Radiant Heat Type
                                      </button>
                                    </h2>

                                    <div
                                      id="membrancesHeatType"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsMembranesOnyHeatType
                                            .slice(
                                              0,
                                              displayedMembranesOnlyHeatTypeLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `mbHeatType-${index}`; // Unique ID for each checkbox
                                              const count =
                                                membranesHeatCounts[
                                                  item.edgeType
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedMembranesOnlyHeatType.includes(
                                                        item.edgeType
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedMembranesOnlyHeatType.includes(
                                                            item.edgeType
                                                          )
                                                            ? selectedMembranesOnlyHeatType.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.edgeType
                                                              )
                                                            : [
                                                                ...selectedMembranesOnlyHeatType,
                                                                item.edgeType,
                                                              ];

                                                        setSelectedMembranesOnlyHeatType(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "edgeType",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.edgeType} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsMembranesOnyHeatType.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !membranesOnlyHeatTypeExpanded; // Toggle the expanded state
                                                setMembranesOnlyHeatTypeExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedMembranesOnlyHeatTypeLimit(
                                                  isExpanded
                                                    ? menuItemsMembranesOnyHeatType.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {membranesOnlyHeatTypeExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* *************** Membranes Thickness Inch Sub End  ******* */}

                              {/* *************** Membranes VolumeL sub START  ******* */}
                              {(currentShownSubCategoryName.includes(
                                "Waterproofing"
                              ) ||
                                currentShownSubCategoryName.includes(
                                  "Crack"
                                )) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#membrancesVolume"
                                        aria-expanded="false"
                                        aria-controls="membrancesVolume"
                                      >
                                        Volume (L)
                                      </button>
                                    </h2>

                                    <div
                                      id="membrancesVolume"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsMembranesWaterproofVolume
                                            .slice(
                                              0,
                                              displayedMembranesVolumeLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `mbVol-${index}`; // Unique ID for each checkbox
                                              const count =
                                                membranesVolumeLCounts[
                                                  item.volume
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedMembranesVolume.includes(
                                                        item.volume
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedMembranesVolume.includes(
                                                            item.volume
                                                          )
                                                            ? selectedMembranesVolume.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.volume
                                                              )
                                                            : [
                                                                ...selectedMembranesVolume,
                                                                item.volume,
                                                              ];
                                                        setSelectedMembranesVolume(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "volume",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.volume} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsMembranesWaterproofVolume.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !membranesVolumeExpanded; // Toggle the expanded state
                                                setMembranesVolumeExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedMembranesVolumeLimit(
                                                  isExpanded
                                                    ? menuItemsMembranesWaterproofVolume.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {membranesVolumeExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* *************** MembranesvolumeL Sub End  ******* */}
                              {/* *************** Membranes Format sub START  ******* */}
                              {currentShownSubCategoryName.includes(
                                "Plywood"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#membrancesFormat"
                                        aria-expanded="false"
                                        aria-controls="membrancesFormat"
                                      >
                                        Format (ft.)
                                      </button>
                                    </h2>

                                    <div
                                      id="membrancesFormat"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsMembranesPlywoodFormat
                                            .slice(
                                              0,
                                              displayedMembranesFormatLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `mbFormat-${index}`; // Unique ID for each checkbox
                                              const count =
                                                membranesFormatCounts[
                                                  item.format
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedMembranesFormat.includes(
                                                        item.format
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedMembranesFormat.includes(
                                                            item.format
                                                          )
                                                            ? selectedMembranesFormat.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.format
                                                              )
                                                            : [
                                                                ...selectedMembranesFormat,
                                                                item.format,
                                                              ];
                                                        setSelectedMembranesFormat(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "format",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.format} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsMembranesPlywoodFormat.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !membranesFormatExpanded; // Toggle the expanded state
                                                setMembranesFormatExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedMembranesFormatLimit(
                                                  isExpanded
                                                    ? menuItemsMembranesPlywoodFormat.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {membranesFormatExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* *************** MembranesFormat Sub End  ******* */}
                              {/* *************** Membranes Thickness Inch sub START  ******* */}
                              {currentShownSubCategoryName.includes(
                                "Plywood"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#membrancesThicknessInch"
                                        aria-expanded="false"
                                        aria-controls="membrancesThicknessInch"
                                      >
                                        Thickness (in.)
                                      </button>
                                    </h2>

                                    <div
                                      id="membrancesThicknessInch"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsMembranesPlywoodThickness
                                            .slice(
                                              0,
                                              displayedMembranesFormatLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `mbThicknessIn-${index}`; // Unique ID for each checkbox
                                              const count =
                                                membranesThicknessInchCounts2[
                                                  item.thickness
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedMembranesThicknessInch.includes(
                                                        item.thickness
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedMembranesThicknessInch.includes(
                                                            item.thickness
                                                          )
                                                            ? selectedMembranesThicknessInch.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.thickness
                                                              )
                                                            : [
                                                                ...selectedMembranesThicknessInch,
                                                                item.thickness,
                                                              ];
                                                        setSelectedMembranesThicknessInch(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "thickness",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.thickness} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsMembranesPlywoodThickness.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !membranesThicknessInchExpanded; // Toggle the expanded state
                                                membranesThicknessInchExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedMembranesThicknessInchLimit(
                                                  isExpanded
                                                    ? menuItemsMembranesPlywoodThickness.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {membranesThicknessInchExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* *************** MembranesThickness Inch Sub End  ******* */}
                            </>
                          )}
                          {/* *************** Membranes END  ******* */}
                          {/* *************** Underlayments START  ******* */}
                          {currentShownCategoryName.includes(
                            "Underlayments"
                          ) && (
                            <>
                              {/* *************** Underlayments sub START  ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#underLayCoverage"
                                    aria-expanded="false"
                                    aria-controls="underLayCoverage"
                                  >
                                    Coverage (sqft)
                                  </button>
                                </h2>

                                <div
                                  id="underLayCoverage"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsUnderLayCoverage
                                        .slice(
                                          0,
                                          displayedUnderlayCoverageLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `mbCoverage-${index}`; // Unique ID for each checkbox
                                          const count =
                                            underlaymentsCoverageCounts[
                                              item.covrage
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedUnderlayCoverage.includes(
                                                    item.covrage
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedUnderlayCoverage.includes(
                                                        item.covrage
                                                      )
                                                        ? selectedUnderlayCoverage.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.covrage
                                                          )
                                                        : [
                                                            ...selectedUnderlayCoverage,
                                                            item.covrage,
                                                          ];

                                                    setSelectedUnderlayCoverage(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "covrage",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.covrage} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsUnderLayCoverage.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !underlayCoverageExpanded; // Toggle the expanded state
                                            setUnderlayCoverageExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedUnderlayCoverageLimit(
                                              isExpanded
                                                ? menuItemsUnderLayCoverage.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {underlayCoverageExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************** UnderlayCoverage Sub End  ******* */}
                              {/* *************** UnderlayCoverageSqYd Sub START  ******* */}
                              {currentShownSubCategoryName.includes(
                                "Carpet"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#underlayCoverageYd"
                                        aria-expanded="false"
                                        aria-controls="underlayCoverageYd"
                                      >
                                        Coverage (sqyd)
                                      </button>
                                    </h2>

                                    <div
                                      id="underlayCoverageYd"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsUnderLayCarpetCoverageYard
                                            .slice(
                                              0,
                                              displayedUnderlayCoverageYdLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `underLayCgYd-${index}`; // Unique ID for each checkbox
                                              const count =
                                                underlayCoverageSqYdCounts[
                                                  item.format
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedUnderlayCoverageYd.includes(
                                                        item.format
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedUnderlayCoverageYd.includes(
                                                            item.format
                                                          )
                                                            ? selectedUnderlayCoverageYd.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.format
                                                              )
                                                            : [
                                                                ...selectedUnderlayCoverageYd,
                                                                item.format,
                                                              ];

                                                        setSelectedUnderlayCoverageYd(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "format",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.format} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsUnderLayCarpetCoverageYard.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !underlayCoverageYdExpanded; // Toggle the expanded state
                                                setUnderlayCoverageYdExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedUnderlayCoverageYdLimit(
                                                  isExpanded
                                                    ? menuItemsUnderLayCarpetCoverageYard.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {underlayCoverageYdExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* *************** UnderlayCoverageSqYd Sub End  ******* */}
                              {/* *************** Underlay Thickness mm  Sub START  ******* */}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#underlayThickiness"
                                    aria-expanded="false"
                                    aria-controls="underlayThickiness"
                                  >
                                    Thickness (mm)
                                  </button>
                                </h2>

                                <div
                                  id="underlayThickiness"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsUnderLayThicknessMM
                                        .slice(
                                          0,
                                          displayedUnderlayThicknessLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `underLayThick-${index}`; // Unique ID for each checkbox
                                          const count =
                                            underThicknessMMCounts[
                                              item.thickness
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedUnderlayThickness.includes(
                                                    item.thickness
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedUnderlayThickness.includes(
                                                        item.thickness
                                                      )
                                                        ? selectedUnderlayThickness.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.thickness
                                                          )
                                                        : [
                                                            ...selectedUnderlayThickness,
                                                            item.thickness,
                                                          ];

                                                    setUnderlayThicknessExpanded(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "thickness",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsUnderLayThicknessMM.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !underlayThicknessExpanded; // Toggle the expanded state
                                            setSelectedUnderlayThickness(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedUnderlayThicknessLimit(
                                              isExpanded
                                                ? underlayThicknessExpanded.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {underlayThicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************** Underlay Thickness mm Sub END  ******* */}
                              {/* *************** Underlay Vapor Barrier  Sub START  ******* */}
                              {(currentShownSubCategoryName.includes(
                                "Laminate"
                              ) ||
                                currentShownSubCategoryName.includes(
                                  "Hardwoord"
                                )) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#underlayVaporB"
                                        aria-expanded="false"
                                        aria-controls="underlayVaporB"
                                      >
                                        Vapor Barrier
                                      </button>
                                    </h2>

                                    <div
                                      id="underlayVaporB"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsUnderLayVaporBarrier
                                            .slice(
                                              0,
                                              displayedUnderlayVaporBarrierLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `underLayVB-${index}`; // Unique ID for each checkbox
                                              const count =
                                                underVaporCounts[
                                                  item.edgeType
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedUnderlayVaporBarrier.includes(
                                                        item.edgeType
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedUnderlayVaporBarrier.includes(
                                                            item.edgeType
                                                          )
                                                            ? selectedUnderlayVaporBarrier.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.edgeType
                                                              )
                                                            : [
                                                                ...selectedUnderlayVaporBarrier,
                                                                item.edgeType,
                                                              ];

                                                        setSelectedUnderlayVaporBarrier(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "edgeType",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.edgeType} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsUnderLayVaporBarrier.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !underlayVaporBarrierExpanded; // Toggle the expanded state
                                                setUnderlayVaporBarrierExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedUnderlayVaporBarrierLimit(
                                                  isExpanded
                                                    ? menuItemsUnderLayVaporBarrier.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {underlayVaporBarrierExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {/* *************  UnderLay  ALL END ******/}
                          {/* *************  Setting Materials  ALL START ******/}
                          {currentShownCategoryName.includes(
                            "Setting Materials"
                          ) && (
                            <>
                              {/* *************  Setting Materials sub Weight START ******/}
                              {!currentShownSubCategoryName.includes(
                                "Sealants"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#settingMaterialLb"
                                        aria-expanded="false"
                                        aria-controls="settingMaterialLb"
                                      >
                                        Weight (lb)
                                      </button>
                                    </h2>

                                    <div
                                      id="settingMaterialLb"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsTileSettingMaterialWeight
                                            .slice(
                                              0,
                                              displayedSettingMaterialLbLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `settingMatLb-${index}`; // Unique ID for each checkbox
                                              const count =
                                                settingMaterialWeightCounts[
                                                  item.Species
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedSettingMaterialLb.includes(
                                                        item.Species
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedSettingMaterialLb.includes(
                                                            item.Species
                                                          )
                                                            ? selectedSettingMaterialLb.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.Species
                                                              )
                                                            : [
                                                                ...selectedSettingMaterialLb,
                                                                item.Species,
                                                              ];

                                                        setSelectedSettingMaterialLb(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "Species",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.Species} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsTileSettingMaterialWeight.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !settingMaterialLbExpanded; // Toggle the expanded state
                                                setSettingMaterialLbExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedSettingMaterialLbLimit(
                                                  isExpanded
                                                    ? menuItemsTileSettingMaterialWeight.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {settingMaterialLbExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* *************  Setting Materials sub Weight END ******/}
                              {/* *************  Setting Materials sub Volumn START ******/}
                              {currentShownSubCategoryName.includes(
                                "Sealants"
                              ) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#settingMaterialVol"
                                        aria-expanded="false"
                                        aria-controls="settingMaterialVol"
                                      >
                                        Volume (L)
                                      </button>
                                    </h2>

                                    <div
                                      id="settingMaterialVol"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {menuItemsTileSettingMaterialVolume
                                            .slice(
                                              0,
                                              displayedSettingMaterialVolumeLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `settingMatVol-${index}`; // Unique ID for each checkbox
                                              const count =
                                                settingMaterialVolumeLCounts[
                                                  item.volume
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedSettingMaterialVolume.includes(
                                                        item.volume
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedSettingMaterialVolume.includes(
                                                            item.volume
                                                          )
                                                            ? selectedSettingMaterialVolume.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.volume
                                                              )
                                                            : [
                                                                ...selectedSettingMaterialVolume,
                                                                item.volume,
                                                              ];

                                                        setSelectedSettingMaterialVolume(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "volume",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.volume} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {menuItemsTileSettingMaterialVolume.length >
                                            4 && ( // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !settingMaterialVolumeExpanded; // Toggle the expanded state
                                                setSettingMaterialVolumeExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedSettingMaterialVolumeLimit(
                                                  isExpanded
                                                    ? menuItemsTileSettingMaterialVolume.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {settingMaterialVolumeExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {/* *************  Setting Materials  ALL END ******/}
                          {/* ************* Floor Patch ALL START ******/}

                          {currentShownCategoryName.includes("Floor Patch") && (
                            <>
                              {/* *************  Floor Patch sub Weight START ******/}

                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#patchLb"
                                    aria-expanded="false"
                                    aria-controls="patchLb"
                                  >
                                    Weight (lb)
                                  </button>
                                </h2>

                                <div
                                  id="patchLb"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsFloorPatchWeight
                                        .slice(0, displayedPatchWeightLimit)
                                        .map((item, index) => {
                                          const checkboxId = `patchLb-${index}`; // Unique ID for each checkbox
                                          const count =
                                            settingMaterialWeightLbCounts[
                                              item.Species
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedPatchWeight.includes(
                                                    item.Species
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedPatchWeight.includes(
                                                        item.Species
                                                      )
                                                        ? selectedPatchWeight.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.Species
                                                          )
                                                        : [
                                                            ...selectedPatchWeight,
                                                            item.Species,
                                                          ];

                                                    setSelectedPatchWeight(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Species",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Species} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsFloorPatchWeight.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !patchWeightExpanded; // Toggle the expanded state
                                            setPatchWeightExpanded(isExpanded); // Update the expanded state
                                            setDisplayedPatchWeightLimit(
                                              isExpanded
                                                ? menuItemsFloorPatchWeight.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {patchWeightExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************  Patch sub Weight END ******/}
                              {/* *************  Patch sub Volumn START ******/}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#patchVol"
                                    aria-expanded="false"
                                    aria-controls="patchVol"
                                  >
                                    Volume (L)
                                  </button>
                                </h2>

                                <div
                                  id="patchVol"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsFloorPatchVolume
                                        .slice(0, displayedPatchVolumeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `patchVol-${index}`; // Unique ID for each checkbox
                                          const count =
                                            patchVolumeLCounts[item.volume] ||
                                            0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedPatchVolume.includes(
                                                    item.volume
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedPatchVolume.includes(
                                                        item.volume
                                                      )
                                                        ? selectedPatchVolume.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.volume
                                                          )
                                                        : [
                                                            ...selectedPatchVolume,
                                                            item.volume,
                                                          ];

                                                    setSelectedPatchVolume(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "volume",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.volume} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsFloorPatchVolume.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !patchVolumeExpanded; // Toggle the expanded state
                                            setPatchVolumeExpanded(isExpanded); // Update the expanded state
                                            setDisplayedPatchVolumeLimit(
                                              isExpanded
                                                ? menuItemsFloorPatchVolume.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {patchVolumeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* *************  Floor Patch ALL END ******/}
                          {/* ************* Adhesives ALL START ******/}

                          {currentShownCategoryName.includes("Adhesives") && (
                            <>
                              {/* *************  Adhesives sub Vol L START ******/}

                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#adVolumeL"
                                    aria-expanded="false"
                                    aria-controls="adVolumeL"
                                  >
                                    Volume (L)
                                  </button>
                                </h2>

                                <div
                                  id="adVolumeL"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsAdhesivesvolume
                                        .slice(
                                          0,
                                          displayedAdhesivesVolumeLLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `adVol-${index}`; // Unique ID for each checkbox
                                          const count =
                                            adhesivesVolumeLCounts[
                                              item.Species
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedAdhesivesVolumeL.includes(
                                                    item.Species
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedAdhesivesVolumeL.includes(
                                                        item.Species
                                                      )
                                                        ? selectedAdhesivesVolumeL.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.Species
                                                          )
                                                        : [
                                                            ...selectedAdhesivesVolumeL,
                                                            item.Species,
                                                          ];

                                                    setSelectedAdhesivesVolumeL(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Species",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Species} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsAdhesivesvolume.length > 4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !adhesivesVolumeLExpanded; // Toggle the expanded state
                                            setAdhesivesVolumeLExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedAdhesivesVolumeLLimit(
                                              isExpanded
                                                ? menuItemsAdhesivesvolume.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {adhesivesVolumeLExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>

                              {/* *************  Patch sub Weight END ******/}
                              {/* *************  Patch sub Volumn START ******/}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#adVolumeMl"
                                    aria-expanded="false"
                                    aria-controls="adVolumeMl"
                                  >
                                    Volume (ml)
                                  </button>
                                </h2>

                                <div
                                  id="adVolumeMl"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsAdhesivesvolumeML
                                        .slice(
                                          0,
                                          displayedAdhesivesVolumeMLLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `adVolumeMl-${index}`; // Unique ID for each checkbox
                                          const count =
                                            adhesivesVolumeMLCounts[
                                              item.volume
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedAdhesivesVolumeML.includes(
                                                    item.volume
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedAdhesivesVolumeML.includes(
                                                        item.volume
                                                      )
                                                        ? selectedAdhesivesVolumeML.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.volume
                                                          )
                                                        : [
                                                            ...selectedAdhesivesVolumeML,
                                                            item.volume,
                                                          ];

                                                    setSelectedAdhesivesVolumeML(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "volume",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.volume} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsAdhesivesvolumeML.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !adhesivesVolumeMLExpanded; // Toggle the expanded state
                                            setAdhesivesVolumeMLExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedAdhesivesVolumeMLLimit(
                                              isExpanded
                                                ? menuItemsAdhesivesvolumeML.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {adhesivesVolumeMLExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {/* ************* adhesives ALL END   ******/}
                          {/* ************* Accessories ALL START   ******/}
                          {currentShownCategoryName.includes("Accessories") && (
                            <>
                              {/* *************  Accessories sub Material START ******/}

                              {(currentShownSubCategoryName.includes(
                                "Wall Base"
                              ) ||
                                currentShownSubCategoryName.includes(
                                  "Moldings & Trims"
                                ) ||
                                currentShownSubCategoryName.includes(
                                  "Floor Vent"
                                )) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accessoryMaterial"
                                        aria-expanded="false"
                                        aria-controls="accessoryMaterial"
                                      >
                                        Material
                                      </button>
                                    </h2>

                                    <div
                                      id="accessoryMaterial"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {(currentShownSubCategoryName.includes(
                                            "Wall Base"
                                          )
                                            ? AccessoriesWallBaseMaterial
                                            : currentShownSubCategoryName.includes(
                                                "Moldings & Trims"
                                              )
                                            ? menuItemsMoldingMaterial
                                            : currentShownSubCategoryName.includes(
                                                "Floor Vent"
                                              )
                                            ? menuItemsFloorVentMaterial
                                            : []
                                          ) // Default case if none of the above conditions are met
                                            .slice(
                                              0,
                                              displayedAccessoryMaterialLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `accMaterial-${index}`; // Unique ID for each checkbox
                                              const count =
                                                accessoriesMaterialCounts[
                                                  item.Material
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedAccessoryMaterial.includes(
                                                        item.Material
                                                      )}
                                                      onChange={() => {
                                                        // If the category is selected, remove it; otherwise, add it
                                                        const updatedInstallation =
                                                          selectedAccessoryMaterial.includes(
                                                            item.Material
                                                          )
                                                            ? selectedAccessoryMaterial.filter(
                                                                (
                                                                  installation
                                                                ) =>
                                                                  installation !==
                                                                  item.Material
                                                              )
                                                            : [
                                                                ...selectedAccessoryMaterial,
                                                                item.Material,
                                                              ];

                                                        setSelectedAccessoryMaterial(
                                                          updatedInstallation
                                                        );

                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "Material",
                                                          updatedInstallation
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.Material} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {(currentShownSubCategoryName.includes(
                                            "Wall Base"
                                          )
                                            ? AccessoriesWallBaseMaterial
                                            : currentShownSubCategoryName.includes(
                                                "Moldings & Trims"
                                              )
                                            ? menuItemsMoldingMaterial
                                            : currentShownSubCategoryName.includes(
                                                "Floor Vent"
                                              )
                                            ? menuItemsFloorVentMaterial
                                            : []
                                          ).length > 4 && ( // Default case if none of the above conditions are met // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !accessoryMaterialExpanded; // Toggle the expanded state
                                                setAccessoryMaterialExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedAccessoryMaterialLimit(
                                                  isExpanded
                                                    ? (currentShownSubCategoryName.includes(
                                                        "Wall Base"
                                                      )
                                                        ? AccessoriesWallBaseMaterial
                                                        : currentShownSubCategoryName.includes(
                                                            "Moldings & Trims"
                                                          )
                                                        ? menuItemsMoldingMaterial
                                                        : currentShownSubCategoryName.includes(
                                                            "Floor Vent"
                                                          )
                                                        ? menuItemsFloorVentMaterial
                                                        : []
                                                      ).length // Default case if none of the above conditions are met
                                                    : 4
                                                );
                                              }}
                                            >
                                              {accessoryMaterialExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* *************  Accessories sub Material END ******/}
                              {/* *************  Accessories sub length in. START ******/}
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#acclegnth"
                                    aria-expanded="false"
                                    aria-controls="acclegnth"
                                  >
                                    Length (in.)
                                  </button>
                                </h2>
                                <div
                                  id="acclegnth"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {(currentShownSubCategoryName.includes(
                                        "Wall Base"
                                      )
                                        ? AccessoriesWallBaseLength
                                        : currentShownSubCategoryName.includes(
                                            "Moldings & Trims"
                                          )
                                        ? AccessoriesMoldingLength
                                        : currentShownSubCategoryName.includes(
                                            "Floor Vent"
                                          )
                                        ? AccessoriesMoldingLength
                                        : AccessoriesMoldingLength
                                      ) // Default case if none of the above conditions are met
                                        .slice(0, displayedAccessoryLengthLimit)
                                        .map((item, index) => {
                                          const checkboxId = `lengthinch-${index}`; // Unique ID for each checkbox
                                          const count =
                                            accessoriesLengthCounts[
                                              item.length
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedAccessoryLength.includes(
                                                    item.length
                                                  )}
                                                  onChange={() => {
                                                    const updatedLength =
                                                      selectedAccessoryLength.includes(
                                                        item.length
                                                      )
                                                        ? selectedAccessoryLength.filter(
                                                            (len) =>
                                                              len !==
                                                              item.length
                                                          )
                                                        : [
                                                            ...selectedAccessoryLength,
                                                            item.length,
                                                          ];
                                                    setSelectedAccessoryLength(
                                                      updatedLength
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "length",
                                                      updatedLength
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.length} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {(currentShownSubCategoryName.includes(
                                        "Wall Base"
                                      )
                                        ? AccessoriesWallBaseLength
                                        : currentShownSubCategoryName.includes(
                                            "Moldings & Trims"
                                          )
                                        ? AccessoriesMoldingLength
                                        : currentShownSubCategoryName.includes(
                                            "Floor Vent"
                                          )
                                        ? AccessoriesMoldingLength
                                        : []
                                      ).length > 4 && ( // Default case if none of the above conditions are met // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !accessoryLengthExpanded; // Toggle the expanded state
                                            setAccessoryLengthExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedAccessoryLengthLimit(
                                              isExpanded
                                                ? (currentShownSubCategoryName.includes(
                                                    "Wall Base"
                                                  )
                                                    ? AccessoriesWallBaseLength
                                                    : currentShownSubCategoryName.includes(
                                                        "Moldings & Trims"
                                                      )
                                                    ? AccessoriesMoldingLength
                                                    : currentShownSubCategoryName.includes(
                                                        "Floor Vent"
                                                      )
                                                    ? AccessoriesMoldingLength
                                                    : []
                                                  ).length // Default case if none of the above conditions are met
                                                : 4
                                            );
                                          }}
                                        >
                                          {accessoryLengthExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                              {/* *************  Accessories sub length in. END ******/}
                              {/* *************  Accessories sub height in. START ******/}
                              {(currentShownSubCategoryName.includes(
                                "Wall Base"
                              ) ||
                                currentShownSubCategoryName.includes(
                                  "Moldings & Trims"
                                )) && (
                                <>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accHeight"
                                        aria-expanded="false"
                                        aria-controls="accHeight"
                                      >
                                        Height (in.)
                                      </button>
                                    </h2>
                                    <div
                                      id="accHeight"
                                      class="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="accordion-body">
                                        <form action="">
                                          {(currentShownSubCategoryName.includes(
                                            "Wall Base"
                                          )
                                            ? AccessoriesWallBaseHeight
                                            : currentShownSubCategoryName.includes(
                                                "Moldings & Trims"
                                              )
                                            ? AccessoriesMoldingHeightInch
                                            : []
                                          ) // Default case if none of the above conditions are met
                                            .slice(
                                              0,
                                              displayedAccessoryHeightLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `accHeight-${index}`; // Unique ID for each checkbox
                                              const count =
                                                accessoriesHeightCounts[
                                                  item.thickness
                                                ] || 0;
                                              return (
                                                <>
                                                  <div className="check-widget">
                                                    <input
                                                      type="checkbox"
                                                      id={checkboxId}
                                                      checked={selectedAccessoryHeight.includes(
                                                        item.thickness
                                                      )}
                                                      onChange={() => {
                                                        const updatedLength =
                                                          selectedAccessoryHeight.includes(
                                                            item.thickness
                                                          )
                                                            ? selectedAccessoryHeight.filter(
                                                                (len) =>
                                                                  len !==
                                                                  item.thickness
                                                              )
                                                            : [
                                                                ...selectedAccessoryHeight,
                                                                item.thickness,
                                                              ];
                                                        setSelectedAccessoryHeight(
                                                          updatedLength
                                                        );
                                                        // Call your filtering logic with updated categories
                                                        handleFilterChange(
                                                          "thickness",
                                                          updatedLength
                                                        );
                                                      }}
                                                    />{" "}
                                                    <label htmlFor={checkboxId}>
                                                      {item.thickness} ({count})
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          {(currentShownSubCategoryName.includes(
                                            "Wall Base"
                                          )
                                            ? AccessoriesWallBaseHeight
                                            : currentShownSubCategoryName.includes(
                                                "Moldings & Trims"
                                              )
                                            ? AccessoriesMoldingHeightInch
                                            : []
                                          ).length > 4 && ( // Default case if none of the above conditions are met // Only show button if more than 4 colors are available
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !accessoryHeightExpanded; // Toggle the expanded state
                                                setAccessoryHeightExpanded(
                                                  isExpanded
                                                ); // Update the expanded state
                                                setDisplayedAccessoryHeightLimit(
                                                  isExpanded
                                                    ? (currentShownSubCategoryName.includes(
                                                        "Wall Base"
                                                      )
                                                        ? AccessoriesWallBaseHeight
                                                        : currentShownSubCategoryName.includes(
                                                            "Moldings & Trims"
                                                          )
                                                        ? AccessoriesMoldingHeightInch
                                                        : []
                                                      ).length // Default case if none of the above conditions are met
                                                    : 4
                                                );
                                              }}
                                            >
                                              {accessoryHeightExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* *************  Accessories sub height in. END ******/}
                              {/* ******************** Accessories sub Finish ********* */}
                              {currentShownSubCategoryName.includes(
                                "Moldings & Trims"
                              ) && (
                                <>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accFinish"
                                        aria-expanded="false"
                                        aria-controls="accFinish"
                                      >
                                        Finish
                                      </button>
                                    </h2>
                                    <div
                                      id="accFinish"
                                      className="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <form action="">
                                          {menuItemsMoldingFinish
                                            .slice(
                                              0,
                                              displayedAccessoryFinishLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `accfinish-${item.Finish}-${index}`;
                                              const count =
                                                accessoriesFinishCounts[
                                                  item.Finish
                                                ] || 0;
                                              return (
                                                <div
                                                  key={index}
                                                  className="check-widget"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={checkboxId}
                                                    checked={selectedAccessoryFinish.includes(
                                                      item.Finish
                                                    )}
                                                    onChange={() => {
                                                      const updatedTileFinish =
                                                        selectedAccessoryFinish.includes(
                                                          item.Finish
                                                        )
                                                          ? selectedAccessoryFinish.filter(
                                                              (finish) =>
                                                                finish !==
                                                                item.Finish
                                                            )
                                                          : [
                                                              ...selectedAccessoryFinish,
                                                              item.Finish,
                                                            ];
                                                      setSelectedAccessoryFinish(
                                                        updatedTileFinish
                                                      );
                                                      handleFilterChange(
                                                        "Finish",
                                                        updatedTileFinish
                                                      );
                                                    }}
                                                  />
                                                  <label htmlFor={checkboxId}>
                                                    {item.display} ({count})
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          {menuItemsMoldingFinish.length >
                                            4 && (
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !accessoryFinishExpanded;
                                                setAccessoryFinishExpanded(
                                                  isExpanded
                                                );
                                                setDisplayedAccessoryFinishLimit(
                                                  isExpanded
                                                    ? menuItemsMoldingFinish.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {accessoryFinishExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* ******************** Accessories sub Floor Vent Format ********* */}
                              {currentShownSubCategoryName.includes(
                                "Moldings & Trims"
                              ) && (
                                <>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accFormat"
                                        aria-expanded="false"
                                        aria-controls="accFormat"
                                      >
                                        Format (in.)
                                      </button>
                                    </h2>
                                    <div
                                      id="accFormat"
                                      className="accordion-collapse collapse"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <form action="">
                                          {menuItemsFloorVentFormat
                                            .slice(
                                              0,
                                              displayedFloorVentFormatLimit
                                            )
                                            .map((item, index) => {
                                              const checkboxId = `accfinish-${item.Format}-${index}`;
                                              const count =
                                                accessoriesFormatCounts[
                                                  item.Format
                                                ] || 0;
                                              return (
                                                <div
                                                  key={index}
                                                  className="check-widget"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={checkboxId}
                                                    checked={selectedFloorVentFormat.includes(
                                                      item.Format
                                                    )}
                                                    onChange={() => {
                                                      const updatedTileFinish =
                                                        selectedFloorVentFormat.includes(
                                                          item.Format
                                                        )
                                                          ? selectedFloorVentFormat.filter(
                                                              (finish) =>
                                                                finish !==
                                                                item.Format
                                                            )
                                                          : [
                                                              ...selectedFloorVentFormat,
                                                              item.Format,
                                                            ];
                                                      setSelectedFloorVentFormat(
                                                        updatedTileFinish
                                                      );
                                                      handleFilterChange(
                                                        "Format",
                                                        updatedTileFinish
                                                      );
                                                    }}
                                                  />
                                                  <label htmlFor={checkboxId}>
                                                    {item.display} ({count})
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          {menuItemsFloorVentFormat.length >
                                            4 && (
                                            <button
                                              type="button"
                                              className="show-more-btn"
                                              onClick={() => {
                                                const isExpanded =
                                                  !floorVentFormatExpanded;
                                                setFloorVentFormatExpanded(
                                                  isExpanded
                                                );
                                                setDisplayedFloorVentFormatLimit(
                                                  isExpanded
                                                    ? menuItemsFloorVentFormat.length
                                                    : 4
                                                );
                                              }}
                                            >
                                              {floorVentFormatExpanded
                                                ? "Show Less"
                                                : "Show More"}
                                            </button>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                          {/* *************Flooring / wood catagory collection name ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                Collection Name
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentCollectionList
                                    .slice(0, displayedCollectionsLimit)
                                    .map((item, index) => {
                                      const checkboxId = `collection-${index}`; // Unique ID for each checkbox
                                      const count =
                                        productCollectionCounts[
                                          item.collection
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedCollection.includes(
                                                item.collection
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedCollection =
                                                  selectedCollection.includes(
                                                    item.collection
                                                  )
                                                    ? selectedCollection.filter(
                                                        (color) =>
                                                          color !==
                                                          item.collection
                                                      )
                                                    : [
                                                        ...selectedCollection,
                                                        item.collection,
                                                      ];

                                                setSelectedCollection(
                                                  updatedCollection
                                                );
                                                console.log(
                                                  "Updated selectedColor:",
                                                  updatedCollection
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "collection",
                                                  updatedCollection
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.collection} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentCollectionList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !collectionsExpanded; // Toggle the expanded state
                                        setCollectionsExpanded(isExpanded); // Update the expanded state
                                        setDisplayedCollectionsLimit(
                                          isExpanded
                                            ? currentCollectionList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {collectionsExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ************* ALL END ******/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary show-filter"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {/* <button type="button" class="btn btn-primary">
                  Understood
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************ listed *************** */}

      <div className="shop-area shop-page-responsive py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-none d-lg-block">
              {/* ************************************************************** Flooring / wood catagory ***********************************************/}
              <div className="sidebar-wrapper pt-1">
                {/* **********Flooring / wood catagory sub catagories ****************** */}

                <div
                  className="sidebar-widget sidebar-widget-border"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-30">
                    <h3>Product Category</h3>
                    {/* {SingleCat[0]?.Type === "Sub_cat" && (
                      <h6 style={{ marginTop: "0.4em" }}>
                        {SingleCat[0]?.name}
                      </h6>
                    )} */}

                    <form action="">
                      {catDetails?.map((item) => {
                        return (
                          <>
                            <div className="check-widget">
                              <input
                                type="checkbox"
                                checked={availCheck("sub_cat", item.name)}
                                onChange={() =>
                                  handleChange("sub_cat", item.name)
                                }
                              />{" "}
                              <p>{item.name}</p>
                            </div>
                          </>
                        );
                      })}
                    </form>
                  </div>
                </div>

                {/* **********Flooring / wood catagory min - max ****************** */}
                <div
                  className="sidebar-widget sidebar-widget-border"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title">
                    <h3>Price</h3>

                    <form action="">
                      <div className="price-widget">
                        <input
                          type="number"
                          placeholder="Min"
                          value={minPrice}
                          onChange={(e) => getMinPrice(e.target.value)}
                        />

                        <input
                          type="number"
                          placeholder="Max"
                          value={maxPrice}
                          onChange={(e) => getMaxPrice(e.target.value)}
                        />
                        {resetPrice === false ? (
                          <>
                            <a
                              className="aply"
                              onClick={(e) => {
                                applyFunc();
                              }}
                            >
                              Apply
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className="aply"
                              onClick={(e) => {
                                clearFunc();
                              }}
                            >
                              Reset
                            </a>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>

                {/* *********** ALL PRODUCT DYNAMIC FILTER STARTS HERE **************** */}

                <div
                  className="sidebar-widget sidebar-widget-border"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-25"></div>

                  {/* ***************** ALL REGULAR MODE ***************** */}

                  <div className="sidebar-list-style">
                    <div class="accordion" id="accordionExample">
                      {/* ***************** ALL catagory brands ***************** */}
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Brands
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <form>
                              {currentBrandList
                                .slice(0, displayedBrandsLimit)
                                .map((item, index) => {
                                  const checkboxId = `brand-${index}`; // Unique ID for each checkbox
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={availCheck(
                                            "BrandName",
                                            item.BrandName
                                          )}
                                          onChange={() =>
                                            handleChange(
                                              "BrandName",
                                              item.BrandName
                                            )
                                          }
                                        />{" "}
                                        <label htmlFor={checkboxId}>
                                          {item.BrandName}
                                        </label>
                                      </div>
                                    </>
                                  );
                                })}
                              {currentBrandList.length > 4 && (
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !brandsExpanded;
                                    setBrandsExpanded(isExpanded);
                                    setDisplayedBrandsLimit(
                                      isExpanded ? currentBrandList.length : 4
                                    );
                                  }}
                                >
                                  {brandsExpanded ? "Show Less" : "Show More"}
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>

                      {/* ******************** ALL catagory color ********* */}
                      {!currentShownCategoryName.includes("Membranes") &&
                        !currentShownCategoryName.includes("Underlayments") && (
                          <>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  Color
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="accordion-body">
                                  <form action="">
                                    {currentColorList
                                      .slice(0, displayedColorsLimit)
                                      .map((item, index) => {
                                        const checkboxId = `color-${index}`; // Unique ID for each checkbox
                                        const count =
                                          colorFilterNameCounts[
                                            item.color_name
                                          ] || 0;
                                        return (
                                          <>
                                            <div className="check-widget">
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={availCheck(
                                                  "color_name",
                                                  item.color_name
                                                )}
                                                onChange={() =>
                                                  handleChange(
                                                    "color_name",
                                                    item.color_name
                                                  )
                                                }
                                              />{" "}
                                              <label htmlFor={checkboxId}>
                                                {item.color_name} ({count})
                                              </label>
                                            </div>
                                          </>
                                        );
                                      })}
                                    {currentColorList.length > 4 && ( // Only show button if more than 4 colors are available
                                      <button
                                        type="button"
                                        className="show-more-btn"
                                        onClick={() => {
                                          const isExpanded = !colorsExpanded; // Toggle the expanded state
                                          setColorsExpanded(isExpanded); // Update the expanded state
                                          setDisplayedColorsLimit(
                                            isExpanded
                                              ? currentColorList.length
                                              : 4
                                          );
                                        }}
                                      >
                                        {colorsExpanded
                                          ? "Show Less"
                                          : "Show More"}
                                      </button>
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                      {/* *************** Flooring WOOD Type ONLY ******* */}
                      {/* *************** Flooring / WOOD Shape Product Type  ******* */}
                      {/* ******************** Flooring / Wall Tile Product Type ********* */}
                      {/* ******************** Product Type will be used in the Sub Cat Previous Category is in Backup For future uses ********* */}
                      {currentShownCategoryName.includes("Wood") && (
                        <>
                          {/* ******************** Flooring / wood cspecies ********* ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSpecies"
                                aria-expanded="false"
                                aria-controls="collapseSpecies"
                              >
                                Species
                              </button>
                            </h2>
                            <div
                              id="collapseSpecies"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsWoodSpecies
                                    .slice(0, displayedSpeciesLimit)
                                    .map((item, index) => {
                                      const checkboxId = `species-${index}`;
                                      const count =
                                        woodSpeciesCounts[item.Species] || 0;
                                      return (
                                        <div className="check-widget">
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={availCheck(
                                              "Species",
                                              item.Species
                                            )}
                                            onChange={() =>
                                              handleChange(
                                                "Species",
                                                item.Species
                                              )
                                            }
                                          />{" "}
                                          <label htmlFor={checkboxId}>
                                            {item.Species} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {menuItemsWoodSpecies.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !speciesExpanded;
                                        setSpeciesExpanded(isExpanded);
                                        setDisplayedSpeciesLimit(
                                          isExpanded
                                            ? menuItemsWoodSpecies.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {speciesExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* ******************** Flooring / wood thickness ********* ********* */}

                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseWoodThickness"
                                aria-expanded="false"
                                aria-controls="collapseWoodThickness"
                              >
                                Thickness (in.)
                              </button>
                            </h2>
                            <div
                              id="collapseWoodThickness"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentWoodThicknessList
                                    .slice(0, displayedWoodThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `wood-thickness-${index}`;
                                      const count =
                                        woodThicknessInchCounts[
                                          item.thickness
                                        ] || 0;
                                      return (
                                        <div className="check-widget">
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={availCheck(
                                              "thickness",
                                              item.thickness
                                            )}
                                            onChange={() =>
                                              handleChange(
                                                "thickness",
                                                item.thickness
                                              )
                                            }
                                          />{" "}
                                          <label htmlFor={checkboxId}>
                                            {item.display} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentWoodThicknessList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !woodThicknessExpanded;
                                        setWoodThicknessExpanded(isExpanded);
                                        setDisplayedWoodThicknessLimit(
                                          isExpanded
                                            ? currentWoodThicknessList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {woodThicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *********************Flooring / wood catagory width (in .)*********  */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                              >
                                Width (in.)
                              </button>
                            </h2>

                            <div
                              id="collapseSeven"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentWoodWidthList
                                    .slice(0, displayedWidthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `widthinch-${index}`; // Unique ID for each checkbox
                                      const count =
                                        woodWidthCounts[item.width] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={availCheck(
                                                "width",
                                                item.width
                                              )}
                                              onChange={() =>
                                                handleChange(
                                                  "width",
                                                  item.width
                                                )
                                              }
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.display} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentWoodWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !widthExpanded; // Toggle the expanded state
                                        setWidthExpanded(isExpanded); // Update the expanded state
                                        setDisplayedWidthLimit(
                                          isExpanded
                                            ? currentWoodWidthList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {widthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring / wood category Finish (Engineer no FInish) ********* */}
                          {currentShownSubCategoryName !== "Engineered" && (
                            <>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFinish"
                                    aria-expanded="false"
                                    aria-controls="collapseFinish"
                                  >
                                    Finish
                                  </button>
                                </h2>
                                <div
                                  id="collapseFinish"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {currentWoodFinishList
                                        .slice(0, displayedFinishLimit)
                                        .map((item, index) => {
                                          const checkboxId = `finish-${index}`;
                                          const count =
                                            woodFinishCounts[item.Finish] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={availCheck(
                                                    "Finish",
                                                    item.Finish
                                                  )}
                                                  onChange={() =>
                                                    handleChange(
                                                      "Finish",
                                                      item.Finish
                                                    )
                                                  }
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Finish} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {currentWoodFinishList.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded = !finishExpanded;
                                            setFinishExpanded(isExpanded);
                                            setDisplayedFinishLimit(
                                              isExpanded
                                                ? currentWoodFinishList.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {finishExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* ******************** Flooring / wood category Surface Texture ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTexture"
                                aria-expanded="false"
                                aria-controls="collapseTexture"
                              >
                                Surface Texture
                              </button>
                            </h2>
                            <div
                              id="collapseTexture"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentSurfaceTextureList
                                    .slice(0, displayedTextureLimit)
                                    .map((item, index) => {
                                      const checkboxId = `texture-${index}`;
                                      const count =
                                        woodSurfaceTextureCounts[
                                          item.Surface_Texture
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={availCheck(
                                                "Surface_Texture",
                                                item.Surface_Texture
                                              )}
                                              onChange={() =>
                                                handleChange(
                                                  "Surface_Texture",
                                                  item.Surface_Texture
                                                )
                                              }
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Surface_Texture} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentSurfaceTextureList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !textureExpanded;
                                        setTextureExpanded(isExpanded);
                                        setDisplayedTextureLimit(
                                          isExpanded
                                            ? currentSurfaceTextureList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {textureExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* ******************** Wood Sub End ********* */}
                        </>
                      )}

                      {/* ******************** Flooring / WOOD ENDED ********* */}

                      {/* *************** Flooring / vinyl ALL ******* */}
                      {currentShownCategoryName.includes("Vinyl") && (
                        <>
                          {/* *************** Flooring / vinyl category installation method  ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#method"
                                aria-expanded="false"
                                aria-controls="method"
                              >
                                Installation Method
                              </button>
                            </h2>

                            <div
                              id="method"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsInstallation
                                    .slice(0, displayedInstallationLimit)
                                    .map((item, index) => {
                                      const checkboxId = `installation-${index}`; // Unique ID for each checkbox
                                      const count =
                                        vinylInstallationMethodCounts[
                                          item.installMethod
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedInstallation.includes(
                                                item.installMethod
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedInstallation.includes(
                                                    item.installMethod
                                                  )
                                                    ? selectedInstallation.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.installMethod
                                                      )
                                                    : [
                                                        ...selectedInstallation,
                                                        item.installMethod,
                                                      ];

                                                setSelectedInstallation(
                                                  updatedInstallation
                                                );
                                                console.log(
                                                  "Updated installation:",
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "installMethod",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.installMethod} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsInstallation.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !installationExpanded; // Toggle the expanded state
                                        setInstallationExpanded(isExpanded); // Update the expanded state
                                        setDisplayedInstallationLimit(
                                          isExpanded
                                            ? menuItemsInstallation.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {installationExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / vinyl category thickness ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                Thickness (mm)
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentThicknessMMList
                                    .slice(0, displayedThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `thickness-${index}`; // Unique ID for each checkbox
                                      const count =
                                        vinylThicknessCounts[item.thickness] ||
                                        0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedThickness.includes(
                                                item.thickness
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedThickness.includes(
                                                    item.thickness
                                                  )
                                                    ? selectedThickness.filter(
                                                        (thick) =>
                                                          thick !==
                                                          item.thickness
                                                      )
                                                    : [
                                                        ...selectedThickness,
                                                        item.thickness,
                                                      ];
                                                setSelectedThickness(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "thickness",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.thickness} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !thicknessExpanded; // Toggle the expanded state
                                        setThicknessExpanded(isExpanded); // Update the expanded state
                                        setDisplayedThicknessLimit(
                                          isExpanded
                                            ? currentThicknessMMList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {thicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************** Flooring / vinyl category Wear Layer  ******* */}

                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#wearLayerThickness"
                                aria-expanded="false"
                                aria-controls="wearLayerThickness"
                              >
                                Wear Layer Thickness (mm)
                              </button>
                            </h2>

                            <div
                              id="wearLayerThickness"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsVinylWearLayerThickness
                                    .slice(0, displayedWearLayerThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `wearLayerThickness-${index}`; // Unique ID for each checkbox
                                      const count =
                                        vinylWearLayerThicknessCounts[
                                          item.wearlayerthickness
                                        ] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedWearLayerThickness.includes(
                                              item.wearlayerthickness
                                            )}
                                            onChange={() => {
                                              const updatedThickness =
                                                selectedWearLayerThickness.includes(
                                                  item.wearlayerthickness
                                                )
                                                  ? selectedWearLayerThickness.filter(
                                                      (thickness) =>
                                                        thickness !==
                                                        item.wearlayerthickness
                                                    )
                                                  : [
                                                      ...selectedWearLayerThickness,
                                                      item.wearlayerthickness,
                                                    ];

                                              setSelectedWearLayerThickness(
                                                updatedThickness
                                              );
                                              // Assuming handleFilterChange can handle updates for wear layer thickness
                                              handleFilterChange(
                                                "wearlayerthickness",
                                                updatedThickness
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.wearlayerthickness} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {menuItemsVinylWearLayerThickness.length >
                                    4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !wearLayerThicknessExpanded;
                                        setWearLayerThicknessExpanded(
                                          isExpanded
                                        );
                                        setDisplayedWearLayerThicknessLimit(
                                          isExpanded
                                            ? menuItemsVinylWearLayerThickness.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {wearLayerThicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************** Flooring / vinyl category Width in ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                              >
                                Width (in.)
                              </button>
                            </h2>

                            <div
                              id="collapseSeven"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentWidthList
                                    .slice(0, displayedWidthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `width-${index}`; // Unique ID for each checkbox
                                      const count =
                                        vinylWidthInchCounts[item.width] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWidth.includes(
                                                item.width
                                              )}
                                              onChange={() => {
                                                console.log(
                                                  "All colors in menuItemsColor:",
                                                  currentWidthList
                                                );
                                                const updatedWidth =
                                                  selectedWidth.includes(
                                                    item.width
                                                  )
                                                    ? selectedWidth.filter(
                                                        (wt) =>
                                                          wt !== item.width
                                                      )
                                                    : [
                                                        ...selectedWidth,
                                                        item.width,
                                                      ];
                                                setSelectedWidth(updatedWidth);

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "width",
                                                  updatedWidth
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.width} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !widthExpanded; // Toggle the expanded state
                                        setWidthExpanded(isExpanded); // Update the expanded state
                                        setDisplayedWidthLimit(
                                          isExpanded
                                            ? currentWidthList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {widthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *********************Flooring / vinyl category Length (in .)*********  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vinylLength"
                                aria-expanded="false"
                                aria-controls="vinylLength"
                              >
                                Length (in.)
                              </button>
                            </h2>

                            <div
                              id="vinylLength"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentLengthList
                                    .slice(0, displayedVinylLengthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `length-${index}`; // Unique ID for each checkbox
                                      const count =
                                        vinylLengthCounts[item.length] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedLength.includes(
                                              item.length
                                            )}
                                            onChange={() => {
                                              const updatedLength =
                                                selectedLength.includes(
                                                  item.length
                                                )
                                                  ? selectedLength.filter(
                                                      (len) =>
                                                        len !== item.length
                                                    )
                                                  : [
                                                      ...selectedLength,
                                                      item.length,
                                                    ];

                                              setSelectedLength(updatedLength);
                                              // Assuming handleFilterChange can handle updates for vinyl length
                                              handleFilterChange(
                                                "length",
                                                updatedLength
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.length} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentLengthList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !vinylLengthExpanded;
                                        setVinylLengthExpanded(isExpanded);
                                        setDisplayedVinylLengthLimit(
                                          isExpanded
                                            ? currentLengthList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {vinylLengthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************Flooring / Vinyl UnhderPad Attached USE Material for now ******/}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#underpadAttached"
                                aria-expanded="false"
                                aria-controls="underpadAttached"
                              >
                                Underpad Attached
                              </button>
                            </h2>

                            <div
                              id="underpadAttached"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsVinylUnderpadAttached.map(
                                    (item, index) => {
                                      const checkboxId = `underpad-${index}`;
                                      const count =
                                        vinylUnderpadCounts[item.Material] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedUnderpadAttached.includes(
                                              item.Material
                                            )}
                                            onChange={() => {
                                              const updatedUnderpadAttached =
                                                selectedUnderpadAttached.includes(
                                                  item.Material
                                                )
                                                  ? selectedUnderpadAttached.filter(
                                                      (underpad) =>
                                                        underpad !==
                                                        item.Material
                                                    )
                                                  : [
                                                      ...selectedUnderpadAttached,
                                                      item.Material,
                                                    ];

                                              setSelectedUnderpadAttached(
                                                updatedUnderpadAttached
                                              );
                                              handleFilterChange(
                                                "Material",
                                                updatedUnderpadAttached
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.Material} ({count})
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                  {menuItemsVinylUnderpadAttached.length >
                                    4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !underpadAttachedExpanded;
                                        setUnderpadAttachedExpanded(isExpanded);
                                        // Assuming a similar limit and expansion behavior as other filters
                                      }}
                                    >
                                      {underpadAttachedExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************Flooring / Vinyl sub ENd ******/}
                        </>
                      )}
                      {/* *************Flooring / Vinyl  ENd ******/}

                      {/* *************Flooring / Laminate  Start ******/}

                      {/* ***********Flooring / Laminate  ALL START **** */}
                      {currentShownCategoryName.includes("Laminate") && (
                        <>
                          {/* ***********Flooring / Laminate  AC Durability **** */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#acDurabilityRating"
                                aria-expanded="false"
                                aria-controls="acDurabilityRating"
                              >
                                AC Durability Rating
                              </button>
                            </h2>

                            <div
                              id="acDurabilityRating"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsACDurabilityRating
                                    .slice(0, displayedACRatingLimit)
                                    .map((item, index) => {
                                      const checkboxId = `acRating-${index}`;
                                      const count =
                                        laminateACCounts[item.ac] || 0;
                                      return (
                                        <div
                                          key={item.id}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedACRating.includes(
                                              item.ac
                                            )}
                                            onChange={() => {
                                              const updatedRating =
                                                selectedACRating.includes(
                                                  item.ac
                                                )
                                                  ? selectedACRating.filter(
                                                      (rating) =>
                                                        rating !== item.ac
                                                    )
                                                  : [
                                                      ...selectedACRating,
                                                      item.ac,
                                                    ];

                                              setSelectedACRating(
                                                updatedRating
                                              );
                                              handleFilterChange(
                                                "ac",
                                                updatedRating
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.ac} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {menuItemsACDurabilityRating.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !acRatingExpanded;
                                        setACRatingExpanded(isExpanded);
                                        setDisplayedACRatingLimit(
                                          isExpanded
                                            ? menuItemsACDurabilityRating.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {acRatingExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring / Laminate Thickness mm ********* */}

                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                Thickness (mm)
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentThicknessMMList
                                    .slice(0, displayedThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `thickness-${index}`; // Unique ID for each checkbox
                                      const count =
                                        laminateThicknessMMCounts[
                                          item.thickness
                                        ] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedThickness.includes(
                                                item.thickness
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedThickness.includes(
                                                    item.thickness
                                                  )
                                                    ? selectedThickness.filter(
                                                        (thick) =>
                                                          thick !==
                                                          item.thickness
                                                      )
                                                    : [
                                                        ...selectedThickness,
                                                        item.thickness,
                                                      ];
                                                setSelectedThickness(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "thickness",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.thickness} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !thicknessExpanded; // Toggle the expanded state
                                        setThicknessExpanded(isExpanded); // Update the expanded state
                                        setDisplayedThicknessLimit(
                                          isExpanded
                                            ? currentThicknessMMList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {thicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *********************Flooring / Laminate Water Resistance use Material for now *********  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#waterResistance"
                                aria-expanded="false"
                                aria-controls="waterResistance"
                              >
                                Water Resistance
                              </button>
                            </h2>
                            <div
                              id="waterResistance"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsWaterResistance.map(
                                    (item, index) => {
                                      const checkboxId = `waterResistance-${index}`;
                                      const count =
                                        laminateWaterResisCounts[
                                          item.Material
                                        ] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedWaterResistance.includes(
                                              item.Material
                                            )}
                                            onChange={() => {
                                              const updatedWaterResistance =
                                                selectedWaterResistance.includes(
                                                  item.Material
                                                )
                                                  ? selectedWaterResistance.filter(
                                                      (resistance) =>
                                                        resistance !==
                                                        item.Material
                                                    )
                                                  : [
                                                      ...selectedWaterResistance,
                                                      item.Material,
                                                    ];
                                              setSelectedWaterResistance(
                                                updatedWaterResistance
                                              );
                                              handleFilterChange(
                                                "Material",
                                                updatedWaterResistance
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.Material} ({count})
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *********************Flooring / Laminate Surface Texture *********  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTexture"
                                aria-expanded="false"
                                aria-controls="collapseTexture"
                              >
                                Surface Texture
                              </button>
                            </h2>
                            <div
                              id="collapseTexture"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentSurfaceTextureList
                                    .slice(0, displayedTextureLimit)
                                    .map((item, index) => {
                                      const checkboxId = `texture-${index}`;
                                      const count =
                                        laminateSurfaceTextureCounts[
                                          item.Surface_Texture
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedSurfaceTexture.includes(
                                                item.Surface_Texture
                                              )}
                                              onChange={() => {
                                                const updatedTexture =
                                                  selectedSurfaceTexture.includes(
                                                    item.Surface_Texture
                                                  )
                                                    ? selectedSurfaceTexture.filter(
                                                        (texture) =>
                                                          texture !==
                                                          item.Surface_Texture
                                                      )
                                                    : [
                                                        ...selectedSurfaceTexture,
                                                        item.Surface_Texture,
                                                      ];

                                                setSelectedSurfaceTexture(
                                                  updatedTexture
                                                );
                                                handleFilterChange(
                                                  "Surface_Texture",
                                                  updatedTexture
                                                );
                                              }}
                                            />
                                            <label htmlFor={checkboxId}>
                                              {item.Surface_Texture} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentSurfaceTextureList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !textureExpanded;
                                        setTextureExpanded(isExpanded);
                                        setDisplayedTextureLimit(
                                          isExpanded
                                            ? currentSurfaceTextureList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {textureExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / Laminate category Width in ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                              >
                                Width (in.)
                              </button>
                            </h2>

                            <div
                              id="collapseSeven"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentWidthList
                                    .slice(0, displayedWidthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `width-${index}`; // Unique ID for each checkbox
                                      const count =
                                        laminateWidthCounts[item.width] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWidth.includes(
                                                item.width
                                              )}
                                              onChange={() => {
                                                console.log(
                                                  "All colors in menuItemsColor:",
                                                  currentWidthList
                                                );
                                                const updatedWidth =
                                                  selectedWidth.includes(
                                                    item.width
                                                  )
                                                    ? selectedWidth.filter(
                                                        (wt) =>
                                                          wt !== item.width
                                                      )
                                                    : [
                                                        ...selectedWidth,
                                                        item.width,
                                                      ];
                                                setSelectedWidth(updatedWidth);

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "width",
                                                  updatedWidth
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.width} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !widthExpanded; // Toggle the expanded state
                                        setWidthExpanded(isExpanded); // Update the expanded state
                                        setDisplayedWidthLimit(
                                          isExpanded
                                            ? currentWidthList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {widthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *********************Flooring / Laminate category Length (in .)*********  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vinylLength"
                                aria-expanded="false"
                                aria-controls="vinylLength"
                              >
                                Length (in.)
                              </button>
                            </h2>

                            <div
                              id="vinylLength"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentLengthList
                                    .slice(0, displayedVinylLengthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `length-${index}`; // Unique ID for each checkbox
                                      const count =
                                        laminateLengthCounts[item.length] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedLength.includes(
                                              item.length
                                            )}
                                            onChange={() => {
                                              const updatedLength =
                                                selectedLength.includes(
                                                  item.length
                                                )
                                                  ? selectedLength.filter(
                                                      (len) =>
                                                        len !== item.length
                                                    )
                                                  : [
                                                      ...selectedLength,
                                                      item.length,
                                                    ];

                                              setSelectedLength(updatedLength);
                                              // Assuming handleFilterChange can handle updates for vinyl length
                                              handleFilterChange(
                                                "length",
                                                updatedLength
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.length} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentLengthList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !vinylLengthExpanded;
                                        setVinylLengthExpanded(isExpanded);
                                        setDisplayedVinylLengthLimit(
                                          isExpanded
                                            ? currentLengthList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {vinylLengthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* ******************** Flooring / Laminate Finish ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFinish"
                                aria-expanded="false"
                                aria-controls="collapseFinish"
                              >
                                Finish
                              </button>
                            </h2>
                            <div
                              id="collapseFinish"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsLaminateFinish
                                    .slice(0, displayedFinishLimit)
                                    .map((item, index) => {
                                      const checkboxId = `finish-${index}`; // Unique ID for each checkbox
                                      const count =
                                        laminateFinishCounts[item.Finish] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedLaminateFinish.includes(
                                                item.Finish
                                              )}
                                              onChange={() => {
                                                const updatedFinish =
                                                  selectedLaminateFinish.includes(
                                                    item.Finish
                                                  )
                                                    ? selectedLaminateFinish.filter(
                                                        (finish) =>
                                                          finish !== item.Finish
                                                      )
                                                    : [
                                                        ...selectedLaminateFinish,
                                                        item.Finish,
                                                      ];
                                                setSelectedLaminateFinish(
                                                  updatedFinish
                                                );
                                                // Invoke your filtering logic here
                                                handleFilterChange(
                                                  "Finish",
                                                  updatedFinish
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Finish} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsLaminateFinish.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !finishExpanded; // Toggle the expanded state
                                        setFinishExpanded(isExpanded); // Update the expanded state
                                        setDisplayedFinishLimit(
                                          isExpanded
                                            ? menuItemsLaminateFinish.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {finishExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring / Laminate Sub End ********* */}
                        </>
                      )}

                      {/* ******************** Flooring / Laminate ALL End ********* */}
                      {/* ******************** Tiles ALL START ********* */}
                      {(currentShownCategoryName.includes(" Tiles") ||
                        currentShownCategoryName.includes("Mosaic")) && (
                        <>
                          {/* ******************** Flooring / Wall Tile Size********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTileSize"
                                aria-expanded="false"
                                aria-controls="collapseTileSize"
                              >
                                Approximate Size (in.)
                              </button>
                            </h2>
                            <div
                              id="collapseTileSize"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileSizeList
                                    .slice(0, displayedWallTileSizeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `size-${index}`;
                                      const count =
                                        tileSizeCounts[
                                          item.Approximate_sizeIN
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWallTileSize.includes(
                                                item.Approximate_sizeIN
                                              )}
                                              onChange={() => {
                                                const updatedWallTileSize =
                                                  selectedWallTileSize.includes(
                                                    item.Approximate_sizeIN
                                                  )
                                                    ? selectedWallTileSize.filter(
                                                        (size) =>
                                                          size !==
                                                          item.Approximate_sizeIN
                                                      )
                                                    : [
                                                        ...selectedWallTileSize,
                                                        item.Approximate_sizeIN,
                                                      ];
                                                setSelectedWallTileSize(
                                                  updatedWallTileSize
                                                );
                                                // Call your filtering logic with updated selections
                                                handleFilterChange(
                                                  "Approximate_sizeIN",
                                                  updatedWallTileSize
                                                );
                                              }}
                                            />
                                            <label htmlFor={checkboxId}>
                                              {item.display} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentTileSizeList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !wallTileSizeExpanded;
                                        setWallTileSizeExpanded(isExpanded);
                                        setDisplayedWallTileSizeLimit(
                                          isExpanded
                                            ? currentTileSizeList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {wallTileSizeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring /  Tile Shape ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTileShape"
                                aria-expanded="false"
                                aria-controls="collapseTileShape"
                              >
                                Shape
                              </button>
                            </h2>
                            <div
                              id="collapseTileShape"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileShapeList
                                    .slice(0, displayedTileShapeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `shape-${index}`;
                                      const count =
                                        tileShapeCounts[item.shape] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedTileShape.includes(
                                                item.shape
                                              )}
                                              onChange={() => {
                                                const updatedTileShape =
                                                  selectedTileShape.includes(
                                                    item.shape
                                                  )
                                                    ? selectedTileShape.filter(
                                                        (sp) =>
                                                          sp !== item.shape
                                                      )
                                                    : [
                                                        ...selectedTileShape,
                                                        item.shape,
                                                      ];
                                                setSelectedTileShape(
                                                  updatedTileShape
                                                );
                                                handleFilterChange(
                                                  "shape",
                                                  updatedTileShape
                                                );
                                              }}
                                            />
                                            <label htmlFor={checkboxId}>
                                              {item.shape} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentTileShapeList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !tileShapeExpanded;
                                        setTileShapeExpanded(isExpanded);
                                        setDisplayedTileShapeLimit(
                                          isExpanded
                                            ? currentTileShapeList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {tileShapeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* ******************** Flooring /  Tile Look ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTileLook"
                                aria-expanded="false"
                                aria-controls="collapseTileLook"
                              >
                                Look
                              </button>
                            </h2>
                            <div
                              id="collapseTileLook"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileLookList
                                    .slice(0, displayedTileLookLimit)
                                    .map((item, index) => {
                                      const checkboxId = `look-${item.Species}-${index}`;
                                      const count =
                                        tileLookCounts[item.Species] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedTileLook.includes(
                                              item.Species
                                            )}
                                            onChange={() => {
                                              const updatedTileLook =
                                                selectedTileLook.includes(
                                                  item.Species
                                                )
                                                  ? selectedTileLook.filter(
                                                      (look) =>
                                                        look !== item.Species
                                                    )
                                                  : [
                                                      ...selectedTileLook,
                                                      item.Species,
                                                    ];
                                              setSelectedTileLook(
                                                updatedTileLook
                                              );
                                              handleFilterChange(
                                                "Species",
                                                updatedTileLook
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.display} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentTileLookList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !tileLookExpanded;
                                        setTileLookExpanded(isExpanded);
                                        setDisplayedTileLookLimit(
                                          isExpanded
                                            ? currentTileLookList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {tileLookExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring / Wall Tile Finish ********* */}

                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTileFinish"
                                aria-expanded="false"
                                aria-controls="collapseTileFinish"
                              >
                                Finish
                              </button>
                            </h2>
                            <div
                              id="collapseTileFinish"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileFinishList
                                    .slice(0, displayedTileFinishLimit)
                                    .map((item, index) => {
                                      const checkboxId = `finish-${item.Finish}-${index}`;
                                      const count =
                                        tileFinishCounts[item.Finish] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedTileFinish.includes(
                                              item.Finish
                                            )}
                                            onChange={() => {
                                              const updatedTileFinish =
                                                selectedTileFinish.includes(
                                                  item.Finish
                                                )
                                                  ? selectedTileFinish.filter(
                                                      (finish) =>
                                                        finish !== item.Finish
                                                    )
                                                  : [
                                                      ...selectedTileFinish,
                                                      item.Finish,
                                                    ];
                                              setSelectedTileFinish(
                                                updatedTileFinish
                                              );
                                              handleFilterChange(
                                                "Finish",
                                                updatedTileFinish
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.display} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentTileFinishList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !tileFinishExpanded;
                                        setTileFinishExpanded(isExpanded);
                                        setDisplayedTileFinishLimit(
                                          isExpanded
                                            ? currentTileFinishList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {tileFinishExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring / Wall Tile Material ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTileMaterial"
                                aria-expanded="false"
                                aria-controls="collapseTileMaterial"
                              >
                                Material
                              </button>
                            </h2>

                            <div
                              id="collapseTileMaterial"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileMaterialList
                                    .slice(0, displayedTileMaterialLimit)
                                    .map((item, index) => {
                                      const checkboxId = `material-${item.Material}-${index}`;
                                      const count =
                                        tileMaterialCounts[item.Material] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedTileMaterial.includes(
                                              item.Material
                                            )}
                                            onChange={() => {
                                              const updatedTileMaterial =
                                                selectedTileMaterial.includes(
                                                  item.Material
                                                )
                                                  ? selectedTileMaterial.filter(
                                                      (material) =>
                                                        material !==
                                                        item.Material
                                                    )
                                                  : [
                                                      ...selectedTileMaterial,
                                                      item.Material,
                                                    ];
                                              setSelectedTileMaterial(
                                                updatedTileMaterial
                                              );
                                              handleFilterChange(
                                                "Material",
                                                updatedTileMaterial
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.display} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentTileMaterialList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !tileMaterialExpanded;
                                        setTileMaterialExpanded(isExpanded);
                                        setDisplayedTileMaterialLimit(
                                          isExpanded
                                            ? currentTileMaterialList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {tileMaterialExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring / Wall Tile PEI Rating ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTilePEIRating"
                                aria-expanded="false"
                                aria-controls="collapseTilePEIRating"
                              >
                                PEI Rating
                              </button>
                            </h2>

                            <div
                              id="collapseTilePEIRating"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTilePEIRatingList
                                    .slice(0, displayedTilePEIRatingLimit)
                                    .map((item, index) => {
                                      const checkboxId = `pei-rating-${item.PEI_Rating}`;
                                      const count =
                                        tilePEICounts[item.PEI_Rating] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedTilePEIRating.includes(
                                              item.PEI_Rating
                                            )}
                                            onChange={() => {
                                              const updatedTilePEIRating =
                                                selectedTilePEIRating.includes(
                                                  item.PEI_Rating
                                                )
                                                  ? selectedTilePEIRating.filter(
                                                      (rating) =>
                                                        rating !==
                                                        item.PEI_Rating
                                                    )
                                                  : [
                                                      ...selectedTilePEIRating,
                                                      item.PEI_Rating,
                                                    ];
                                              setSelectedTilePEIRating(
                                                updatedTilePEIRating
                                              );
                                              handleFilterChange(
                                                "PEI_Rating",
                                                updatedTilePEIRating
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.PEI_Rating} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentTilePEIRatingList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !tilePEIRatingExpanded;
                                        setTilePEIRatingExpanded(isExpanded);
                                        setDisplayedTilePEIRatingLimit(
                                          isExpanded
                                            ? currentTilePEIRatingList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {tilePEIRatingExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Flooring / Wall Tile Edge Type ********* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTileEdgeType"
                                aria-expanded="false"
                                aria-controls="collapseTileEdgeType"
                              >
                                Edge Type
                              </button>
                            </h2>

                            <div
                              id="collapseTileEdgeType"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileEdgeTypeList
                                    .slice(0, displayedTileEdgeTypeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `edge-type-${item.edgeType}`;
                                      const count =
                                        tileEdgeTypeCounts[item.edgeType] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedTileEdgeType.includes(
                                              item.edgeType
                                            )}
                                            onChange={() => {
                                              const updatedTileEdgeType =
                                                selectedTileEdgeType.includes(
                                                  item.edgeType
                                                )
                                                  ? selectedTileEdgeType.filter(
                                                      (type) =>
                                                        type !== item.edgeType
                                                    )
                                                  : [
                                                      ...selectedTileEdgeType,
                                                      item.edgeType,
                                                    ];
                                              setSelectedTileEdgeType(
                                                updatedTileEdgeType
                                              );
                                              handleFilterChange(
                                                "edgeType",
                                                updatedTileEdgeType
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.display} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentTileEdgeTypeList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !tileEdgeTypeExpanded;
                                        setTileEdgeTypeExpanded(isExpanded);
                                        setDisplayedTileEdgeTypeLimit(
                                          isExpanded
                                            ? currentTileEdgeTypeList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {tileEdgeTypeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* ******************** Tile Sub End ********* */}
                        </>
                      )}
                      {/* ******************** Tile END e********* */}

                      {/* ***********Flooring / Cork Flooring **** */}
                      {currentShownCategoryName.includes("Cork Flooring") && (
                        <>
                          {/* *************** Flooring / Cork Flooring thickness ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                Thickness (mm)
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentThicknessMMList
                                    .slice(0, displayedThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `corkThickness-${index}`; // Unique ID for each checkbox
                                      const count =
                                        corkFloorThicknessMMCounts[
                                          item.thickness
                                        ] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedThickness.includes(
                                                item.thickness
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedThickness.includes(
                                                    item.thickness
                                                  )
                                                    ? selectedThickness.filter(
                                                        (thick) =>
                                                          thick !==
                                                          item.thickness
                                                      )
                                                    : [
                                                        ...selectedThickness,
                                                        item.thickness,
                                                      ];
                                                setSelectedThickness(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "thickness",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.thickness} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !thicknessExpanded; // Toggle the expanded state
                                        setThicknessExpanded(isExpanded); // Update the expanded state
                                        setDisplayedThicknessLimit(
                                          isExpanded
                                            ? currentThicknessMMList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {thicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / Cork Flooring Width in ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                              >
                                Width (in.)
                              </button>
                            </h2>

                            <div
                              id="collapseSeven"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentWidthList
                                    .slice(0, displayedWidthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `corkWidth-${index}`; // Unique ID for each checkbox
                                      const count =
                                        corkFloorWidthCounts[item.width] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWidth.includes(
                                                item.width
                                              )}
                                              onChange={() => {
                                                const updatedWidth =
                                                  selectedWidth.includes(
                                                    item.width
                                                  )
                                                    ? selectedWidth.filter(
                                                        (wt) =>
                                                          wt !== item.width
                                                      )
                                                    : [
                                                        ...selectedWidth,
                                                        item.width,
                                                      ];
                                                setSelectedWidth(updatedWidth);

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "width",
                                                  updatedWidth
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.width} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentWidthList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !widthExpanded; // Toggle the expanded state
                                        setWidthExpanded(isExpanded); // Update the expanded state
                                        setDisplayedWidthLimit(
                                          isExpanded
                                            ? currentWidthList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {widthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *********************Flooring / Cork Flooring Length (in .)*********  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vinylLength"
                                aria-expanded="false"
                                aria-controls="vinylLength"
                              >
                                Length (in.)
                              </button>
                            </h2>

                            <div
                              id="vinylLength"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentLengthList
                                    .slice(0, displayedVinylLengthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `length-${index}`; // Unique ID for each checkbox
                                      const count =
                                        corkFloorLengthCounts[item.length] || 0;
                                      return (
                                        <div
                                          key={index}
                                          className="check-widget"
                                        >
                                          <input
                                            type="checkbox"
                                            id={checkboxId}
                                            checked={selectedLength.includes(
                                              item.length
                                            )}
                                            onChange={() => {
                                              const updatedLength =
                                                selectedLength.includes(
                                                  item.length
                                                )
                                                  ? selectedLength.filter(
                                                      (len) =>
                                                        len !== item.length
                                                    )
                                                  : [
                                                      ...selectedLength,
                                                      item.length,
                                                    ];

                                              setSelectedLength(updatedLength);
                                              // Assuming handleFilterChange can handle updates for vinyl length
                                              handleFilterChange(
                                                "length",
                                                updatedLength
                                              );
                                            }}
                                          />
                                          <label htmlFor={checkboxId}>
                                            {item.length} ({count})
                                          </label>
                                        </div>
                                      );
                                    })}
                                  {currentLengthList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !vinylLengthExpanded;
                                        setVinylLengthExpanded(isExpanded);
                                        setDisplayedVinylLengthLimit(
                                          isExpanded
                                            ? currentLengthList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {vinylLengthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {/* ***********Flooring / Carpet Flooring **** */}
                      {currentShownCategoryName.includes("Carpets") && (
                        <>
                          {/* *************** Flooring / Carpets Size ******* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTileSize"
                                aria-expanded="false"
                                aria-controls="collapseTileSize"
                              >
                                Approximate Size (in.)
                              </button>
                            </h2>
                            <div
                              id="collapseTileSize"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileSizeList
                                    .slice(0, displayedWallTileSizeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `carpetSize-${index}`;
                                      const count =
                                        corkFloorSizeCounts[
                                          item.Approximate_sizeIN
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWallTileSize.includes(
                                                item.Approximate_sizeIN
                                              )}
                                              onChange={() => {
                                                const updatedWallTileSize =
                                                  selectedWallTileSize.includes(
                                                    item.Approximate_sizeIN
                                                  )
                                                    ? selectedWallTileSize.filter(
                                                        (size) =>
                                                          size !==
                                                          item.Approximate_sizeIN
                                                      )
                                                    : [
                                                        ...selectedWallTileSize,
                                                        item.Approximate_sizeIN,
                                                      ];
                                                setSelectedWallTileSize(
                                                  updatedWallTileSize
                                                );
                                                // Call your filtering logic with updated selections
                                                handleFilterChange(
                                                  "Approximate_sizeIN",
                                                  updatedWallTileSize
                                                );
                                              }}
                                            />
                                            <label htmlFor={checkboxId}>
                                              {item.display} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentTileSizeList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !wallTileSizeExpanded;
                                        setWallTileSizeExpanded(isExpanded);
                                        setDisplayedWallTileSizeLimit(
                                          isExpanded
                                            ? currentTileSizeList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {wallTileSizeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / Carpets Fiber Material ******* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFiberMaterial"
                                aria-expanded="false"
                                aria-controls="collapseFiberMaterial"
                              >
                                Fiber Material
                              </button>
                            </h2>
                            <div
                              id="collapseFiberMaterial"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsCarpetMaterial
                                    .slice(0, displayedWallTileSizeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `carpetMaterial-${index}`;
                                      const count =
                                        carpetFiberMaterialCounts[
                                          item.Material
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWallTileSize.includes(
                                                item.Material
                                              )}
                                              onChange={() => {
                                                const updatedWallTileSize =
                                                  selectedWallTileSize.includes(
                                                    item.Material
                                                  )
                                                    ? selectedWallTileSize.filter(
                                                        (size) =>
                                                          size !== item.Material
                                                      )
                                                    : [
                                                        ...selectedWallTileSize,
                                                        item.Material,
                                                      ];
                                                setSelectedWallTileSize(
                                                  updatedWallTileSize
                                                );
                                                // Call your filtering logic with updated selections
                                                handleFilterChange(
                                                  "Material",
                                                  updatedWallTileSize
                                                );
                                              }}
                                            />
                                            <label htmlFor={checkboxId}>
                                              {item.Material} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsCarpetMaterial.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !wallTileSizeExpanded;
                                        setWallTileSizeExpanded(isExpanded);
                                        setDisplayedWallTileSizeLimit(
                                          isExpanded
                                            ? menuItemsCarpetMaterial.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {wallTileSizeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / Carpets Flooring thickness ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                Thickness (mm)
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentThicknessMMList
                                    .slice(0, displayedThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `carpetsThickness-${index}`; // Unique ID for each checkbox
                                      const count =
                                        carpetThicknessCounts[item.thickness] ||
                                        0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedThickness.includes(
                                                item.thickness
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedThickness.includes(
                                                    item.thickness
                                                  )
                                                    ? selectedThickness.filter(
                                                        (thick) =>
                                                          thick !==
                                                          item.thickness
                                                      )
                                                    : [
                                                        ...selectedThickness,
                                                        item.thickness,
                                                      ];
                                                setSelectedThickness(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "thickness",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.thickness} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !thicknessExpanded; // Toggle the expanded state
                                        setThicknessExpanded(isExpanded); // Update the expanded state
                                        setDisplayedThicknessLimit(
                                          isExpanded
                                            ? currentThicknessMMList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {thicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *********************Flooring / Carpet Pile Weight *********  */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCarpetWeight"
                                aria-expanded="false"
                                aria-controls="collapseCarpetWeight"
                              >
                                Pile Weight (oz/sqyd)
                              </button>
                            </h2>
                            <div
                              id="collapseCarpetWeight"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsCarpetPileWeight
                                    .slice(0, displayedCarpetsPileWeightLimit)
                                    .map((item, index) => {
                                      const checkboxId = `carpetsPileWeight-${index}`; // Unique ID for each checkbox
                                      const count =
                                        carpetWeightCounts[item.Species] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedCarpetsPileWeight.includes(
                                                item.Species
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedCarpetsPileWeight.includes(
                                                    item.Species
                                                  )
                                                    ? selectedCarpetsPileWeight.filter(
                                                        (thick) =>
                                                          thick !== item.Species
                                                      )
                                                    : [
                                                        ...selectedCarpetsPileWeight,
                                                        item.Species,
                                                      ];
                                                setSelectedCarpetsPileWeight(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Species",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.display} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}

                                  {menuItemsCarpetPileWeight.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !carpetsPileWeightExpanded; // Toggle the expanded state
                                        setCarpetsPileWeightExpanded(
                                          isExpanded
                                        ); // Update the expanded state
                                        setDisplayedCarpetsPileWeightLimit(
                                          isExpanded
                                            ? menuItemsCarpetPileWeight.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {carpetsPileWeightExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************Flooring / Carpets Linear Density ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCarpetLinearDensity"
                                aria-expanded="false"
                                aria-controls="collapseCarpetLinearDensity"
                              >
                                Carpet Linear Density (ktex)
                              </button>
                            </h2>
                            <div
                              id="collapseCarpetLinearDensity"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsCarpetLinearDensity
                                    .slice(0, displayedCarpetLinearDensityLimit)
                                    .map((item, index) => {
                                      const checkboxId = `carpetsPileWeight-${index}`; // Unique ID for each checkbox
                                      const count =
                                        carpetLinearDensityCounts[
                                          item.Surface_Texture
                                        ] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedCarpetLinearDensity.includes(
                                                item.Surface_Texture
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedCarpetLinearDensity.includes(
                                                    item.Surface_Texture
                                                  )
                                                    ? selectedCarpetLinearDensity.filter(
                                                        (thick) =>
                                                          thick !==
                                                          item.Surface_Texture
                                                      )
                                                    : [
                                                        ...selectedCarpetLinearDensity,
                                                        item.Surface_Texture,
                                                      ];
                                                setSelectedCarpetLinearDensity(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Surface_Texture",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Surface_Texture} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}

                                  {menuItemsCarpetLinearDensity.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !carpetLinearDensityExpanded; // Toggle the expanded state
                                        setCarpetLinearDensityExpanded(
                                          isExpanded
                                        ); // Update the expanded state
                                        setDisplayedCarpetLinearDensityLimit(
                                          isExpanded
                                            ? menuItemsCarpetLinearDensity.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {carpetLinearDensityExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************Flooring / Carpets Pile Construction Method ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCarpetConstructionMethod"
                                aria-expanded="false"
                                aria-controls="collapseCarpetConstructionMethod"
                              >
                                Pile Construction Method
                              </button>
                            </h2>
                            <div
                              id="collapseCarpetConstructionMethod"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsCarpetPileConstructionMethod
                                    .slice(
                                      0,
                                      displayedCarpetPileConstructionMethodLimit
                                    )
                                    .map((item, index) => {
                                      const checkboxId = `carpetsPileConstructionMethod-${index}`; // Unique ID for each checkbox
                                      const count =
                                        carpetPileMethodCounts[item.edgeType] ||
                                        0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedCarpetPileConstructionMethod.includes(
                                                item.edgeType
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedCarpetPileConstructionMethod.includes(
                                                    item.edgeType
                                                  )
                                                    ? selectedCarpetPileConstructionMethod.filter(
                                                        (thick) =>
                                                          thick !==
                                                          item.edgeType
                                                      )
                                                    : [
                                                        ...selectedCarpetPileConstructionMethod,
                                                        item.edgeType,
                                                      ];
                                                setSelectedCarpetPileConstructionMethod(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "edgeType",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.edgeType} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}

                                  {menuItemsCarpetPileConstructionMethod.length >
                                    4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !carpetPileConstructionMethodExpanded; // Toggle the expanded state
                                        setCarpetPileConstructionMethodExpanded(
                                          isExpanded
                                        ); // Update the expanded state
                                        setDisplayedCarpetPileConstructionMethodLimit(
                                          isExpanded
                                            ? menuItemsCarpetPileConstructionMethod.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {carpetPileConstructionMethodExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************Flooring / Carpets Onces ******/}

                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCarpetOnces"
                                aria-expanded="false"
                                aria-controls="collapseCarpetOnces"
                              >
                                Onces
                              </button>
                            </h2>
                            <div
                              id="collapseCarpetOnces"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsCarpetOunces
                                    .slice(0, displayedCarpetOuncesLimit)
                                    .map((item, index) => {
                                      const checkboxId = `carpetsPileWeight-${index}`; // Unique ID for each checkbox
                                      const count =
                                        carpetOncesCounts[item.Onces] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedCarpetOunces.includes(
                                                item.Onces
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedCarpetOunces.includes(
                                                    item.Onces
                                                  )
                                                    ? selectedCarpetOunces.filter(
                                                        (thick) =>
                                                          thick !== item.Onces
                                                      )
                                                    : [
                                                        ...selectedCarpetOunces,
                                                        item.Onces,
                                                      ];
                                                setSelectedCarpetOunces(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Onces",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Onces} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsCarpetOunces.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !carpetOuncesExpanded; // Toggle the expanded state
                                        setCarpetOuncesExpanded(isExpanded); // Update the expanded state
                                        setDisplayedCarpetOuncesLimit(
                                          isExpanded
                                            ? menuItemsCarpetOunces.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {carpetOuncesExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************Flooring / Density ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCarpetDensity"
                                aria-expanded="false"
                                aria-controls="collapseCarpetDensity"
                              >
                                Density
                              </button>
                            </h2>
                            <div
                              id="collapseCarpetDensity"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsCarpetDensity
                                    .slice(0, displayedCarpetDensityLimit)
                                    .map((item, index) => {
                                      const checkboxId = `carpetsDensity-${index}`; // Unique ID for each checkbox
                                      const count =
                                        carpetDensityCounts[item.Density] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedCarpetDensity.includes(
                                                item.Density
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedCarpetDensity.includes(
                                                    item.Density
                                                  )
                                                    ? selectedCarpetDensity.filter(
                                                        (thick) =>
                                                          thick !== item.Density
                                                      )
                                                    : [
                                                        ...selectedCarpetDensity,
                                                        item.Density,
                                                      ];
                                                setSelectedCarpetDensity(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Density",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Density} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsCarpetDensity.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !carpetDensityExpanded; // Toggle the expanded state
                                        setCarpetDensityExpanded(isExpanded); // Update the expanded state
                                        setDisplayedCarpetDensityLimit(
                                          isExpanded
                                            ? menuItemsCarpetDensity.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {carpetDensityExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************Flooring / Carpets sub End ******/}
                        </>
                      )}
                      {/* *************Flooring / Carpets ALL End ******/}
                      {/* *************Flooring / Sport Flooring ALL START ******/}
                      {currentShownCategoryName.includes("Sport") && (
                        <>
                          {/* *************** Flooring / Carpets Size ******* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSportSize"
                                aria-expanded="false"
                                aria-controls="collapseSportSize"
                              >
                                Approximate Size (in.)
                              </button>
                            </h2>
                            <div
                              id="collapseSportSize"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {currentTileSizeList
                                    .slice(0, displayedWallTileSizeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `sportSize-${index}`;
                                      const count =
                                        sportFloorSizeCounts[
                                          item.Approximate_sizeIN
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWallTileSize.includes(
                                                item.Approximate_sizeIN
                                              )}
                                              onChange={() => {
                                                const updatedWallTileSize =
                                                  selectedWallTileSize.includes(
                                                    item.Approximate_sizeIN
                                                  )
                                                    ? selectedWallTileSize.filter(
                                                        (size) =>
                                                          size !==
                                                          item.Approximate_sizeIN
                                                      )
                                                    : [
                                                        ...selectedWallTileSize,
                                                        item.Approximate_sizeIN,
                                                      ];
                                                setSelectedWallTileSize(
                                                  updatedWallTileSize
                                                );
                                                // Call your filtering logic with updated selections
                                                handleFilterChange(
                                                  "Approximate_sizeIN",
                                                  updatedWallTileSize
                                                );
                                              }}
                                            />
                                            <label htmlFor={checkboxId}>
                                              {item.display} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentTileSizeList.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !wallTileSizeExpanded;
                                        setWallTileSizeExpanded(isExpanded);
                                        setDisplayedWallTileSizeLimit(
                                          isExpanded
                                            ? currentTileSizeList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {wallTileSizeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / Sport Flooring thickness ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSportThickness"
                                aria-expanded="false"
                                aria-controls="collapseSportThickness"
                              >
                                Thickness (mm)
                              </button>
                            </h2>
                            <div
                              id="collapseSportThickness"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {currentThicknessMMList
                                    .slice(0, displayedThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `sportThickness-${index}`; // Unique ID for each checkbox
                                      const count =
                                        sportFloorThicknessCounts[
                                          item.thickness
                                        ] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedThickness.includes(
                                                item.thickness
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedThickness.includes(
                                                    item.thickness
                                                  )
                                                    ? selectedThickness.filter(
                                                        (thick) =>
                                                          thick !==
                                                          item.thickness
                                                      )
                                                    : [
                                                        ...selectedThickness,
                                                        item.thickness,
                                                      ];
                                                setSelectedThickness(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "thickness",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.thickness} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {currentThicknessMMList.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !thicknessExpanded; // Toggle the expanded state
                                        setThicknessExpanded(isExpanded); // Update the expanded state
                                        setDisplayedThicknessLimit(
                                          isExpanded
                                            ? currentThicknessMMList.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {thicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / Sport Material ******* */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSportMaterial"
                                aria-expanded="false"
                                aria-controls="collapseSportMaterial"
                              >
                                Material
                              </button>
                            </h2>
                            <div
                              id="collapseSportMaterial"
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form action="">
                                  {menuItemsSportMaterial
                                    .slice(0, displayedWallTileSizeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `sportMaterial-${index}`;
                                      const count =
                                        sportFloorMaterialCounts[
                                          item.Material
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedWallTileSize.includes(
                                                item.Material
                                              )}
                                              onChange={() => {
                                                const updatedWallTileSize =
                                                  selectedWallTileSize.includes(
                                                    item.Material
                                                  )
                                                    ? selectedWallTileSize.filter(
                                                        (size) =>
                                                          size !== item.Material
                                                      )
                                                    : [
                                                        ...selectedWallTileSize,
                                                        item.Material,
                                                      ];
                                                setSelectedWallTileSize(
                                                  updatedWallTileSize
                                                );
                                                // Call your filtering logic with updated selections
                                                handleFilterChange(
                                                  "Material",
                                                  updatedWallTileSize
                                                );
                                              }}
                                            />
                                            <label htmlFor={checkboxId}>
                                              {item.Material} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsSportMaterial.length > 4 && (
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !wallTileSizeExpanded;
                                        setWallTileSizeExpanded(isExpanded);
                                        setDisplayedWallTileSizeLimit(
                                          isExpanded
                                            ? menuItemsSportMaterial.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {wallTileSizeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************Flooring / Sport Onces ******/}

                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSportOnces"
                                aria-expanded="false"
                                aria-controls="collapseSportOnces"
                              >
                                Onces
                              </button>
                            </h2>
                            <div
                              id="collapseSportOnces"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsCarpetOunces
                                    .slice(0, displayedSportOuncesLimit)
                                    .map((item, index) => {
                                      const checkboxId = `sportPileWeight-${index}`; // Unique ID for each checkbox
                                      const count =
                                        sportFloorOncesCounts[item.Onces] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedSportOunces.includes(
                                                item.Onces
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedSportOunces.includes(
                                                    item.Onces
                                                  )
                                                    ? selectedSportOunces.filter(
                                                        (thick) =>
                                                          thick !== item.Onces
                                                      )
                                                    : [
                                                        ...selectedSportOunces,
                                                        item.Onces,
                                                      ];
                                                setSelectedSportOunces(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Onces",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Onces} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsCarpetOunces.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !sportOuncesExpanded; // Toggle the expanded state
                                        setSportOuncesExpanded(isExpanded); // Update the expanded state
                                        setDisplayedSportOuncesLimit(
                                          isExpanded
                                            ? menuItemsCarpetOunces.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {sportOuncesExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************Flooring / Sport Density ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSportDensity"
                                aria-expanded="false"
                                aria-controls="collapseSportDensity"
                              >
                                Density
                              </button>
                            </h2>
                            <div
                              id="collapseSportDensity"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsCarpetDensity
                                    .slice(0, displayedSportDensityLimit)
                                    .map((item, index) => {
                                      const checkboxId = `sportDensity-${index}`; // Unique ID for each checkbox
                                      const count =
                                        sportFloorDensityCounts[item.Density] ||
                                        0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedSportDensity.includes(
                                                item.Density
                                              )}
                                              onChange={() => {
                                                const updatedThickness =
                                                  selectedSportDensity.includes(
                                                    item.Density
                                                  )
                                                    ? selectedSportDensity.filter(
                                                        (thick) =>
                                                          thick !== item.Density
                                                      )
                                                    : [
                                                        ...selectedCarpetDensity,
                                                        item.Density,
                                                      ];
                                                setSelectedSportDensity(
                                                  updatedThickness
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Density",
                                                  updatedThickness
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Density} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsCarpetDensity.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !sportDensityExpanded; // Toggle the expanded state
                                        setSportDensityExpanded(isExpanded); // Update the expanded state
                                        setDisplayedSportDensityLimit(
                                          isExpanded
                                            ? menuItemsCarpetDensity.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {sportDensityExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************** Flooring / Sport installation method  ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#sportInstallationMethod"
                                aria-expanded="false"
                                aria-controls="sportInstallationMethod"
                              >
                                Installation Method
                              </button>
                            </h2>

                            <div
                              id="sportInstallationMethod"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsSportInstallation
                                    .slice(
                                      0,
                                      displayedSportInstallationMethodLimit
                                    )
                                    .map((item, index) => {
                                      const checkboxId = `sportInstallation-${index}`; // Unique ID for each checkbox
                                      const count =
                                        sportInstallationMethodCounts[
                                          item.installMethod
                                        ] || 0;

                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedSportInstallationMethod.includes(
                                                item.installMethod
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedSportInstallationMethod.includes(
                                                    item.installMethod
                                                  )
                                                    ? selectedSportInstallationMethod.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.installMethod
                                                      )
                                                    : [
                                                        ...selectedSportInstallationMethod,
                                                        item.installMethod,
                                                      ];

                                                setSelectedSportInstallationMethod(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "installMethod",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.installMethod} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsSportInstallation.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !sportInstallationMethodExpanded; // Toggle the expanded state
                                        setSportInstallationMethodExpanded(
                                          isExpanded
                                        ); // Update the expanded state
                                        setDisplayedSportInstallationMethodLimit(
                                          isExpanded
                                            ? menuItemsInstallation.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {sportInstallationMethodExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************** Flooring / Sport Sub End  ******* */}
                        </>
                      )}
                      {/* *************** Flooring / Sport All End  ******* */}
                      {/* ************* Membranes ALL START ******/}
                      {currentShownCategoryName.includes("Membranes") && (
                        <>
                          {/* *************** Membranes sub START  ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#membrancesCoverage"
                                aria-expanded="false"
                                aria-controls="membrancesCoverage"
                              >
                                Coverage (sqft)
                              </button>
                            </h2>

                            <div
                              id="membrancesCoverage"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsMembranesCoverage
                                    .slice(0, displayedMembranesCoverageLimit)
                                    .map((item, index) => {
                                      const checkboxId = `mbCoverage-${index}`; // Unique ID for each checkbox
                                      const count =
                                        membranesCoverageCounts[item.covrage] ||
                                        0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedMembranesCoverage.includes(
                                                item.covrage
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedMembranesCoverage.includes(
                                                    item.covrage
                                                  )
                                                    ? selectedMembranesCoverage.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.covrage
                                                      )
                                                    : [
                                                        ...selectedMembranesCoverage,
                                                        item.covrage,
                                                      ];

                                                setSelectedMembranesCoverage(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "covrage",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.covrage} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsMembranesCoverage.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !membranesCoverageExpanded; // Toggle the expanded state
                                        setMembranesCoverageExpanded(
                                          isExpanded
                                        ); // Update the expanded state
                                        setDisplayedMembranesCoverageLimit(
                                          isExpanded
                                            ? menuItemsMembranesCoverage.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {membranesCoverageExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************** MembranesCoverage Sub End  ******* */}
                          {/* *************** Membranes Thickness mm sub START  ******* */}
                          {currentShownSubCategoryName.includes("Heating") && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#membrancesThickiness"
                                    aria-expanded="false"
                                    aria-controls="membrancesThickiness"
                                  >
                                    Thickness (mm)
                                  </button>
                                </h2>

                                <div
                                  id="membrancesThickiness"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsMembranesOnlyThicknessMM
                                        .slice(
                                          0,
                                          displayedMembranesThicknessLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `mbThick-${index}`; // Unique ID for each checkbox
                                          const count =
                                            membranesThicknessMMCounts[
                                              item.thickness
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedMembranesThickness.includes(
                                                    item.thickness
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedMembranesThickness.includes(
                                                        item.thickness
                                                      )
                                                        ? selectedMembranesThickness.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.thickness
                                                          )
                                                        : [
                                                            ...selectedMembranesThickness,
                                                            item.thickness,
                                                          ];

                                                    setSelectedMembranesThickness(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "thickness",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsMembranesOnlyThicknessMM.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !membranesThicknessExpanded; // Toggle the expanded state
                                            setMembranesThicknessExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedMembranesThicknessLimit(
                                              isExpanded
                                                ? menuItemsMembranesOnlyThicknessMM.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {membranesThicknessExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* *************** Membranes Thickness Sub End  ******* */}
                          {/* *************** Membranes Thickness inch sub START  ******* */}
                          {currentShownSubCategoryName.includes("Heating") && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#membrancesThickinessInch"
                                    aria-expanded="false"
                                    aria-controls="membrancesThickinessInch"
                                  >
                                    Thickness (inches in fraction)
                                  </button>
                                </h2>

                                <div
                                  id="membrancesThickinessInch"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsMembranesThicknessFraction
                                        .slice(
                                          0,
                                          displayedMembranesThicknessInchLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `mbThickInch-${index}`; // Unique ID for each checkbox
                                          const count =
                                            membranesThicknessInchCounts[
                                              item.wearlayerthickness
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedMembranesThicknessInch.includes(
                                                    item.wearlayerthickness
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedMembranesThicknessInch.includes(
                                                        item.wearlayerthickness
                                                      )
                                                        ? selectedMembranesThicknessInch.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.wearlayerthickness
                                                          )
                                                        : [
                                                            ...selectedMembranesThicknessInch,
                                                            item.wearlayerthickness,
                                                          ];

                                                    setSelectedMembranesThicknessInch(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "wearlayerthickness",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.wearlayerthickness} (
                                                  {count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsMembranesThicknessFraction.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !membranesThicknessInchExpanded; // Toggle the expanded state
                                            setMembranesThicknessInchExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedMembranesThicknessInchLimit(
                                              isExpanded
                                                ? menuItemsMembranesThicknessFraction.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {membranesThicknessInchExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* *************** Membranes Thickness Inch Sub End  ******* */}
                          {/* *************** Membranes Heating Type sub START  ******* */}
                          {currentShownSubCategoryName.includes("Heating") && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#membrancesHeatType"
                                    aria-expanded="false"
                                    aria-controls="membrancesHeatType"
                                  >
                                    Radiant Heat Type
                                  </button>
                                </h2>

                                <div
                                  id="membrancesHeatType"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsMembranesOnyHeatType
                                        .slice(
                                          0,
                                          displayedMembranesOnlyHeatTypeLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `mbHeatType-${index}`; // Unique ID for each checkbox
                                          const count =
                                            membranesHeatCounts[
                                              item.edgeType
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedMembranesOnlyHeatType.includes(
                                                    item.edgeType
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedMembranesOnlyHeatType.includes(
                                                        item.edgeType
                                                      )
                                                        ? selectedMembranesOnlyHeatType.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.edgeType
                                                          )
                                                        : [
                                                            ...selectedMembranesOnlyHeatType,
                                                            item.edgeType,
                                                          ];

                                                    setSelectedMembranesOnlyHeatType(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "edgeType",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.edgeType} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsMembranesOnyHeatType.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !membranesOnlyHeatTypeExpanded; // Toggle the expanded state
                                            setMembranesOnlyHeatTypeExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedMembranesOnlyHeatTypeLimit(
                                              isExpanded
                                                ? menuItemsMembranesOnyHeatType.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {membranesOnlyHeatTypeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* *************** Membranes Thickness Inch Sub End  ******* */}

                          {/* *************** Membranes VolumeL sub START  ******* */}
                          {(currentShownSubCategoryName.includes(
                            "Waterproofing"
                          ) ||
                            currentShownSubCategoryName.includes("Crack")) && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#membrancesVolume"
                                    aria-expanded="false"
                                    aria-controls="membrancesVolume"
                                  >
                                    Volume (L)
                                  </button>
                                </h2>

                                <div
                                  id="membrancesVolume"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsMembranesWaterproofVolume
                                        .slice(0, displayedMembranesVolumeLimit)
                                        .map((item, index) => {
                                          const checkboxId = `mbVol-${index}`; // Unique ID for each checkbox
                                          const count =
                                            membranesVolumeLCounts[
                                              item.volume
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedMembranesVolume.includes(
                                                    item.volume
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedMembranesVolume.includes(
                                                        item.volume
                                                      )
                                                        ? selectedMembranesVolume.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.volume
                                                          )
                                                        : [
                                                            ...selectedMembranesVolume,
                                                            item.volume,
                                                          ];
                                                    setSelectedMembranesVolume(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "volume",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.volume} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsMembranesWaterproofVolume.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !membranesVolumeExpanded; // Toggle the expanded state
                                            setMembranesVolumeExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedMembranesVolumeLimit(
                                              isExpanded
                                                ? menuItemsMembranesWaterproofVolume.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {membranesVolumeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* *************** MembranesvolumeL Sub End  ******* */}
                          {/* *************** Membranes Format sub START  ******* */}
                          {currentShownSubCategoryName.includes("Plywood") && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#membrancesFormat"
                                    aria-expanded="false"
                                    aria-controls="membrancesFormat"
                                  >
                                    Format (ft.)
                                  </button>
                                </h2>

                                <div
                                  id="membrancesFormat"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsMembranesPlywoodFormat
                                        .slice(0, displayedMembranesFormatLimit)
                                        .map((item, index) => {
                                          const checkboxId = `mbFormat-${index}`; // Unique ID for each checkbox
                                          const count =
                                            membranesFormatCounts[
                                              item.format
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedMembranesFormat.includes(
                                                    item.format
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedMembranesFormat.includes(
                                                        item.format
                                                      )
                                                        ? selectedMembranesFormat.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.format
                                                          )
                                                        : [
                                                            ...selectedMembranesFormat,
                                                            item.format,
                                                          ];
                                                    setSelectedMembranesFormat(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "format",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.format} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsMembranesPlywoodFormat.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !membranesFormatExpanded; // Toggle the expanded state
                                            setMembranesFormatExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedMembranesFormatLimit(
                                              isExpanded
                                                ? menuItemsMembranesPlywoodFormat.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {membranesFormatExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {/* *************** MembranesFormat Sub End  ******* */}
                          {/* *************** Membranes Thickness Inch sub START  ******* */}
                          {currentShownSubCategoryName.includes("Plywood") && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#membrancesThicknessInch"
                                    aria-expanded="false"
                                    aria-controls="membrancesThicknessInch"
                                  >
                                    Thickness (in.)
                                  </button>
                                </h2>

                                <div
                                  id="membrancesThicknessInch"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsMembranesPlywoodThickness
                                        .slice(0, displayedMembranesFormatLimit)
                                        .map((item, index) => {
                                          const checkboxId = `mbThicknessIn-${index}`; // Unique ID for each checkbox
                                          const count =
                                            membranesThicknessInchCounts2[
                                              item.thickness
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedMembranesThicknessInch.includes(
                                                    item.thickness
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedMembranesThicknessInch.includes(
                                                        item.thickness
                                                      )
                                                        ? selectedMembranesThicknessInch.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.thickness
                                                          )
                                                        : [
                                                            ...selectedMembranesThicknessInch,
                                                            item.thickness,
                                                          ];
                                                    setSelectedMembranesThicknessInch(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "thickness",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsMembranesPlywoodThickness.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !membranesThicknessInchExpanded; // Toggle the expanded state
                                            membranesThicknessInchExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedMembranesThicknessInchLimit(
                                              isExpanded
                                                ? menuItemsMembranesPlywoodThickness.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {membranesThicknessInchExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {/* *************** MembranesThickness Inch Sub End  ******* */}
                        </>
                      )}
                      {/* *************** Membranes END  ******* */}
                      {/* *************** Underlayments START  ******* */}
                      {currentShownCategoryName.includes("Underlayments") && (
                        <>
                          {/* *************** Underlayments sub START  ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#underLayCoverage"
                                aria-expanded="false"
                                aria-controls="underLayCoverage"
                              >
                                Coverage (sqft)
                              </button>
                            </h2>

                            <div
                              id="underLayCoverage"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsUnderLayCoverage
                                    .slice(0, displayedUnderlayCoverageLimit)
                                    .map((item, index) => {
                                      const checkboxId = `mbCoverage-${index}`; // Unique ID for each checkbox
                                      const count =
                                        underlaymentsCoverageCounts[
                                          item.covrage
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedUnderlayCoverage.includes(
                                                item.covrage
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedUnderlayCoverage.includes(
                                                    item.covrage
                                                  )
                                                    ? selectedUnderlayCoverage.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.covrage
                                                      )
                                                    : [
                                                        ...selectedUnderlayCoverage,
                                                        item.covrage,
                                                      ];

                                                setSelectedUnderlayCoverage(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "covrage",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.covrage} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsUnderLayCoverage.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !underlayCoverageExpanded; // Toggle the expanded state
                                        setUnderlayCoverageExpanded(isExpanded); // Update the expanded state
                                        setDisplayedUnderlayCoverageLimit(
                                          isExpanded
                                            ? menuItemsUnderLayCoverage.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {underlayCoverageExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************** UnderlayCoverage Sub End  ******* */}
                          {/* *************** UnderlayCoverageSqYd Sub START  ******* */}
                          {currentShownSubCategoryName.includes("Carpet") && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#underlayCoverageYd"
                                    aria-expanded="false"
                                    aria-controls="underlayCoverageYd"
                                  >
                                    Coverage (sqyd)
                                  </button>
                                </h2>

                                <div
                                  id="underlayCoverageYd"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsUnderLayCarpetCoverageYard
                                        .slice(
                                          0,
                                          displayedUnderlayCoverageYdLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `underLayCgYd-${index}`; // Unique ID for each checkbox
                                          const count =
                                            underlayCoverageSqYdCounts[
                                              item.format
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedUnderlayCoverageYd.includes(
                                                    item.format
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedUnderlayCoverageYd.includes(
                                                        item.format
                                                      )
                                                        ? selectedUnderlayCoverageYd.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.format
                                                          )
                                                        : [
                                                            ...selectedUnderlayCoverageYd,
                                                            item.format,
                                                          ];

                                                    setSelectedUnderlayCoverageYd(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "format",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.format} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsUnderLayCarpetCoverageYard.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !underlayCoverageYdExpanded; // Toggle the expanded state
                                            setUnderlayCoverageYdExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedUnderlayCoverageYdLimit(
                                              isExpanded
                                                ? menuItemsUnderLayCarpetCoverageYard.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {underlayCoverageYdExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {/* *************** UnderlayCoverageSqYd Sub End  ******* */}
                          {/* *************** Underlay Thickness mm  Sub START  ******* */}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#underlayThickiness"
                                aria-expanded="false"
                                aria-controls="underlayThickiness"
                              >
                                Thickness (mm)
                              </button>
                            </h2>

                            <div
                              id="underlayThickiness"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsUnderLayThicknessMM
                                    .slice(0, displayedUnderlayThicknessLimit)
                                    .map((item, index) => {
                                      const checkboxId = `underLayThick-${index}`; // Unique ID for each checkbox
                                      const count =
                                        underThicknessMMCounts[
                                          item.thickness
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedUnderlayThickness.includes(
                                                item.thickness
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedUnderlayThickness.includes(
                                                    item.thickness
                                                  )
                                                    ? selectedUnderlayThickness.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.thickness
                                                      )
                                                    : [
                                                        ...selectedUnderlayThickness,
                                                        item.thickness,
                                                      ];

                                                setUnderlayThicknessExpanded(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "thickness",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.thickness} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsUnderLayThicknessMM.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !underlayThicknessExpanded; // Toggle the expanded state
                                        setSelectedUnderlayThickness(
                                          isExpanded
                                        ); // Update the expanded state
                                        setDisplayedUnderlayThicknessLimit(
                                          isExpanded
                                            ? underlayThicknessExpanded.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {underlayThicknessExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************** Underlay Thickness mm Sub END  ******* */}
                          {/* *************** Underlay Vapor Barrier  Sub START  ******* */}
                          {(currentShownSubCategoryName.includes("Laminate") ||
                            currentShownSubCategoryName.includes(
                              "Hardwoord"
                            )) && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#underlayVaporB"
                                    aria-expanded="false"
                                    aria-controls="underlayVaporB"
                                  >
                                    Vapor Barrier
                                  </button>
                                </h2>

                                <div
                                  id="underlayVaporB"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsUnderLayVaporBarrier
                                        .slice(
                                          0,
                                          displayedUnderlayVaporBarrierLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `underLayVB-${index}`; // Unique ID for each checkbox
                                          const count =
                                            underVaporCounts[item.edgeType] ||
                                            0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedUnderlayVaporBarrier.includes(
                                                    item.edgeType
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedUnderlayVaporBarrier.includes(
                                                        item.edgeType
                                                      )
                                                        ? selectedUnderlayVaporBarrier.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.edgeType
                                                          )
                                                        : [
                                                            ...selectedUnderlayVaporBarrier,
                                                            item.edgeType,
                                                          ];

                                                    setSelectedUnderlayVaporBarrier(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "edgeType",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.edgeType} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsUnderLayVaporBarrier.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !underlayVaporBarrierExpanded; // Toggle the expanded state
                                            setUnderlayVaporBarrierExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedUnderlayVaporBarrierLimit(
                                              isExpanded
                                                ? menuItemsUnderLayVaporBarrier.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {underlayVaporBarrierExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {/* *************  UnderLay  ALL END ******/}
                      {/* *************  Setting Materials  ALL START ******/}
                      {currentShownCategoryName.includes(
                        "Setting Materials"
                      ) && (
                        <>
                          {/* *************  Setting Materials sub Weight START ******/}
                          {!currentShownSubCategoryName.includes(
                            "Sealants"
                          ) && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#settingMaterialLb"
                                    aria-expanded="false"
                                    aria-controls="settingMaterialLb"
                                  >
                                    Weight (lb)
                                  </button>
                                </h2>

                                <div
                                  id="settingMaterialLb"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsTileSettingMaterialWeight
                                        .slice(
                                          0,
                                          displayedSettingMaterialLbLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `settingMatLb-${index}`; // Unique ID for each checkbox
                                          const count =
                                            settingMaterialWeightCounts[
                                              item.Species
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedSettingMaterialLb.includes(
                                                    item.Species
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedSettingMaterialLb.includes(
                                                        item.Species
                                                      )
                                                        ? selectedSettingMaterialLb.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.Species
                                                          )
                                                        : [
                                                            ...selectedSettingMaterialLb,
                                                            item.Species,
                                                          ];

                                                    setSelectedSettingMaterialLb(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Species",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Species} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsTileSettingMaterialWeight.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !settingMaterialLbExpanded; // Toggle the expanded state
                                            setSettingMaterialLbExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedSettingMaterialLbLimit(
                                              isExpanded
                                                ? menuItemsTileSettingMaterialWeight.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {settingMaterialLbExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {/* *************  Setting Materials sub Weight END ******/}
                          {/* *************  Setting Materials sub Volumn START ******/}
                          {currentShownSubCategoryName.includes("Sealants") && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#settingMaterialVol"
                                    aria-expanded="false"
                                    aria-controls="settingMaterialVol"
                                  >
                                    Volume (L)
                                  </button>
                                </h2>

                                <div
                                  id="settingMaterialVol"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {menuItemsTileSettingMaterialVolume
                                        .slice(
                                          0,
                                          displayedSettingMaterialVolumeLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `settingMatVol-${index}`; // Unique ID for each checkbox
                                          const count =
                                            settingMaterialVolumeLCounts[
                                              item.volume
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedSettingMaterialVolume.includes(
                                                    item.volume
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedSettingMaterialVolume.includes(
                                                        item.volume
                                                      )
                                                        ? selectedSettingMaterialVolume.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.volume
                                                          )
                                                        : [
                                                            ...selectedSettingMaterialVolume,
                                                            item.volume,
                                                          ];

                                                    setSelectedSettingMaterialVolume(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "volume",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.volume} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {menuItemsTileSettingMaterialVolume.length >
                                        4 && ( // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !settingMaterialVolumeExpanded; // Toggle the expanded state
                                            setSettingMaterialVolumeExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedSettingMaterialVolumeLimit(
                                              isExpanded
                                                ? menuItemsTileSettingMaterialVolume.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {settingMaterialVolumeExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {/* *************  Setting Materials  ALL END ******/}
                      {/* ************* Floor Patch ALL START ******/}

                      {currentShownCategoryName.includes("Floor Patch") && (
                        <>
                          {/* *************  Floor Patch sub Weight START ******/}

                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#patchLb"
                                aria-expanded="false"
                                aria-controls="patchLb"
                              >
                                Weight (lb)
                              </button>
                            </h2>

                            <div
                              id="patchLb"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsFloorPatchWeight
                                    .slice(0, displayedPatchWeightLimit)
                                    .map((item, index) => {
                                      const checkboxId = `patchLb-${index}`; // Unique ID for each checkbox
                                      const count =
                                        settingMaterialWeightLbCounts[
                                          item.Species
                                        ] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedPatchWeight.includes(
                                                item.Species
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedPatchWeight.includes(
                                                    item.Species
                                                  )
                                                    ? selectedPatchWeight.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.Species
                                                      )
                                                    : [
                                                        ...selectedPatchWeight,
                                                        item.Species,
                                                      ];

                                                setSelectedPatchWeight(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Species",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Species} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsFloorPatchWeight.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !patchWeightExpanded; // Toggle the expanded state
                                        setPatchWeightExpanded(isExpanded); // Update the expanded state
                                        setDisplayedPatchWeightLimit(
                                          isExpanded
                                            ? menuItemsFloorPatchWeight.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {patchWeightExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************  Patch sub Weight END ******/}
                          {/* *************  Patch sub Volumn START ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#patchVol"
                                aria-expanded="false"
                                aria-controls="patchVol"
                              >
                                Volume (L)
                              </button>
                            </h2>

                            <div
                              id="patchVol"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsFloorPatchVolume
                                    .slice(0, displayedPatchVolumeLimit)
                                    .map((item, index) => {
                                      const checkboxId = `patchVol-${index}`; // Unique ID for each checkbox
                                      const count =
                                        patchVolumeLCounts[item.volume] || 0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedPatchVolume.includes(
                                                item.volume
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedPatchVolume.includes(
                                                    item.volume
                                                  )
                                                    ? selectedPatchVolume.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.volume
                                                      )
                                                    : [
                                                        ...selectedPatchVolume,
                                                        item.volume,
                                                      ];

                                                setSelectedPatchVolume(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "volume",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.volume} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsFloorPatchVolume.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded = !patchVolumeExpanded; // Toggle the expanded state
                                        setPatchVolumeExpanded(isExpanded); // Update the expanded state
                                        setDisplayedPatchVolumeLimit(
                                          isExpanded
                                            ? menuItemsFloorPatchVolume.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {patchVolumeExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {/* *************  Floor Patch ALL END ******/}
                      {/* ************* Adhesives ALL START ******/}

                      {currentShownCategoryName.includes("Adhesives") && (
                        <>
                          {/* *************  Adhesives sub Vol L START ******/}

                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#adVolumeL"
                                aria-expanded="false"
                                aria-controls="adVolumeL"
                              >
                                Volume (L)
                              </button>
                            </h2>

                            <div
                              id="adVolumeL"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsAdhesivesvolume
                                    .slice(0, displayedAdhesivesVolumeLLimit)
                                    .map((item, index) => {
                                      const checkboxId = `adVol-${index}`; // Unique ID for each checkbox
                                      const count =
                                        adhesivesVolumeLCounts[item.Species] ||
                                        0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedAdhesivesVolumeL.includes(
                                                item.Species
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedAdhesivesVolumeL.includes(
                                                    item.Species
                                                  )
                                                    ? selectedAdhesivesVolumeL.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.Species
                                                      )
                                                    : [
                                                        ...selectedAdhesivesVolumeL,
                                                        item.Species,
                                                      ];

                                                setSelectedAdhesivesVolumeL(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "Species",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.Species} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsAdhesivesvolume.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !adhesivesVolumeLExpanded; // Toggle the expanded state
                                        setAdhesivesVolumeLExpanded(isExpanded); // Update the expanded state
                                        setDisplayedAdhesivesVolumeLLimit(
                                          isExpanded
                                            ? menuItemsAdhesivesvolume.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {adhesivesVolumeLExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          {/* *************  Patch sub Weight END ******/}
                          {/* *************  Patch sub Volumn START ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#adVolumeMl"
                                aria-expanded="false"
                                aria-controls="adVolumeMl"
                              >
                                Volume (ml)
                              </button>
                            </h2>

                            <div
                              id="adVolumeMl"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {menuItemsAdhesivesvolumeML
                                    .slice(0, displayedAdhesivesVolumeMLLimit)
                                    .map((item, index) => {
                                      const checkboxId = `adVolumeMl-${index}`; // Unique ID for each checkbox
                                      const count =
                                        adhesivesVolumeMLCounts[item.volume] ||
                                        0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedAdhesivesVolumeML.includes(
                                                item.volume
                                              )}
                                              onChange={() => {
                                                // If the category is selected, remove it; otherwise, add it
                                                const updatedInstallation =
                                                  selectedAdhesivesVolumeML.includes(
                                                    item.volume
                                                  )
                                                    ? selectedAdhesivesVolumeML.filter(
                                                        (installation) =>
                                                          installation !==
                                                          item.volume
                                                      )
                                                    : [
                                                        ...selectedAdhesivesVolumeML,
                                                        item.volume,
                                                      ];

                                                setSelectedAdhesivesVolumeML(
                                                  updatedInstallation
                                                );

                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "volume",
                                                  updatedInstallation
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.volume} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {menuItemsAdhesivesvolumeML.length > 4 && ( // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !adhesivesVolumeMLExpanded; // Toggle the expanded state
                                        setAdhesivesVolumeMLExpanded(
                                          isExpanded
                                        ); // Update the expanded state
                                        setDisplayedAdhesivesVolumeMLLimit(
                                          isExpanded
                                            ? menuItemsAdhesivesvolumeML.length
                                            : 4
                                        );
                                      }}
                                    >
                                      {adhesivesVolumeMLExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {/* ************* adhesives ALL END   ******/}
                      {/* ************* Accessories ALL START   ******/}
                      {currentShownCategoryName.includes("Accessories") && (
                        <>
                          {/* *************  Accessories sub Material START ******/}

                          {(currentShownSubCategoryName.includes("Wall Base") ||
                            currentShownSubCategoryName.includes(
                              "Moldings & Trims"
                            ) ||
                            currentShownSubCategoryName.includes(
                              "Floor Vent"
                            )) && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accessoryMaterial"
                                    aria-expanded="false"
                                    aria-controls="accessoryMaterial"
                                  >
                                    Material
                                  </button>
                                </h2>

                                <div
                                  id="accessoryMaterial"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {(currentShownSubCategoryName.includes(
                                        "Wall Base"
                                      )
                                        ? AccessoriesWallBaseMaterial
                                        : currentShownSubCategoryName.includes(
                                            "Moldings & Trims"
                                          )
                                        ? menuItemsMoldingMaterial
                                        : currentShownSubCategoryName.includes(
                                            "Floor Vent"
                                          )
                                        ? menuItemsFloorVentMaterial
                                        : []
                                      ) // Default case if none of the above conditions are met
                                        .slice(
                                          0,
                                          displayedAccessoryMaterialLimit
                                        )
                                        .map((item, index) => {
                                          const checkboxId = `accMaterial-${index}`; // Unique ID for each checkbox
                                          const count =
                                            accessoriesMaterialCounts[
                                              item.Material
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedAccessoryMaterial.includes(
                                                    item.Material
                                                  )}
                                                  onChange={() => {
                                                    // If the category is selected, remove it; otherwise, add it
                                                    const updatedInstallation =
                                                      selectedAccessoryMaterial.includes(
                                                        item.Material
                                                      )
                                                        ? selectedAccessoryMaterial.filter(
                                                            (installation) =>
                                                              installation !==
                                                              item.Material
                                                          )
                                                        : [
                                                            ...selectedAccessoryMaterial,
                                                            item.Material,
                                                          ];

                                                    setSelectedAccessoryMaterial(
                                                      updatedInstallation
                                                    );

                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "Material",
                                                      updatedInstallation
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.Material} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {(currentShownSubCategoryName.includes(
                                        "Wall Base"
                                      )
                                        ? AccessoriesWallBaseMaterial
                                        : currentShownSubCategoryName.includes(
                                            "Moldings & Trims"
                                          )
                                        ? menuItemsMoldingMaterial
                                        : currentShownSubCategoryName.includes(
                                            "Floor Vent"
                                          )
                                        ? menuItemsFloorVentMaterial
                                        : []
                                      ).length > 4 && ( // Default case if none of the above conditions are met // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !accessoryMaterialExpanded; // Toggle the expanded state
                                            setAccessoryMaterialExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedAccessoryMaterialLimit(
                                              isExpanded
                                                ? (currentShownSubCategoryName.includes(
                                                    "Wall Base"
                                                  )
                                                    ? AccessoriesWallBaseMaterial
                                                    : currentShownSubCategoryName.includes(
                                                        "Moldings & Trims"
                                                      )
                                                    ? menuItemsMoldingMaterial
                                                    : currentShownSubCategoryName.includes(
                                                        "Floor Vent"
                                                      )
                                                    ? menuItemsFloorVentMaterial
                                                    : []
                                                  ).length // Default case if none of the above conditions are met
                                                : 4
                                            );
                                          }}
                                        >
                                          {accessoryMaterialExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* *************  Accessories sub Material END ******/}
                          {/* *************  Accessories sub length in. START ******/}
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#acclegnth"
                                aria-expanded="false"
                                aria-controls="acclegnth"
                              >
                                Length (in.)
                              </button>
                            </h2>
                            <div
                              id="acclegnth"
                              class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <form action="">
                                  {(currentShownSubCategoryName.includes(
                                    "Wall Base"
                                  )
                                    ? AccessoriesWallBaseLength
                                    : currentShownSubCategoryName.includes(
                                        "Moldings & Trims"
                                      )
                                    ? AccessoriesMoldingLength
                                    : currentShownSubCategoryName.includes(
                                        "Floor Vent"
                                      )
                                    ? AccessoriesMoldingLength
                                    : AccessoriesMoldingLength
                                  ) // Default case if none of the above conditions are met
                                    .slice(0, displayedAccessoryLengthLimit)
                                    .map((item, index) => {
                                      const checkboxId = `lengthinch-${index}`; // Unique ID for each checkbox
                                      const count =
                                        accessoriesLengthCounts[item.length] ||
                                        0;
                                      return (
                                        <>
                                          <div className="check-widget">
                                            <input
                                              type="checkbox"
                                              id={checkboxId}
                                              checked={selectedAccessoryLength.includes(
                                                item.length
                                              )}
                                              onChange={() => {
                                                const updatedLength =
                                                  selectedAccessoryLength.includes(
                                                    item.length
                                                  )
                                                    ? selectedAccessoryLength.filter(
                                                        (len) =>
                                                          len !== item.length
                                                      )
                                                    : [
                                                        ...selectedAccessoryLength,
                                                        item.length,
                                                      ];
                                                setSelectedAccessoryLength(
                                                  updatedLength
                                                );
                                                // Call your filtering logic with updated categories
                                                handleFilterChange(
                                                  "length",
                                                  updatedLength
                                                );
                                              }}
                                            />{" "}
                                            <label htmlFor={checkboxId}>
                                              {item.length} ({count})
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })}
                                  {(currentShownSubCategoryName.includes(
                                    "Wall Base"
                                  )
                                    ? AccessoriesWallBaseLength
                                    : currentShownSubCategoryName.includes(
                                        "Moldings & Trims"
                                      )
                                    ? AccessoriesMoldingLength
                                    : currentShownSubCategoryName.includes(
                                        "Floor Vent"
                                      )
                                    ? AccessoriesMoldingLength
                                    : []
                                  ).length > 4 && ( // Default case if none of the above conditions are met // Only show button if more than 4 colors are available
                                    <button
                                      type="button"
                                      className="show-more-btn"
                                      onClick={() => {
                                        const isExpanded =
                                          !accessoryLengthExpanded; // Toggle the expanded state
                                        setAccessoryLengthExpanded(isExpanded); // Update the expanded state
                                        setDisplayedAccessoryLengthLimit(
                                          isExpanded
                                            ? (currentShownSubCategoryName.includes(
                                                "Wall Base"
                                              )
                                                ? AccessoriesWallBaseLength
                                                : currentShownSubCategoryName.includes(
                                                    "Moldings & Trims"
                                                  )
                                                ? AccessoriesMoldingLength
                                                : currentShownSubCategoryName.includes(
                                                    "Floor Vent"
                                                  )
                                                ? AccessoriesMoldingLength
                                                : []
                                              ).length // Default case if none of the above conditions are met
                                            : 4
                                        );
                                      }}
                                    >
                                      {accessoryLengthExpanded
                                        ? "Show Less"
                                        : "Show More"}
                                    </button>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* *************  Accessories sub length in. END ******/}
                          {/* *************  Accessories sub height in. START ******/}
                          {(currentShownSubCategoryName.includes("Wall Base") ||
                            currentShownSubCategoryName.includes(
                              "Moldings & Trims"
                            )) && (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accHeight"
                                    aria-expanded="false"
                                    aria-controls="accHeight"
                                  >
                                    Height (in.)
                                  </button>
                                </h2>
                                <div
                                  id="accHeight"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body">
                                    <form action="">
                                      {(currentShownSubCategoryName.includes(
                                        "Wall Base"
                                      )
                                        ? AccessoriesWallBaseHeight
                                        : currentShownSubCategoryName.includes(
                                            "Moldings & Trims"
                                          )
                                        ? AccessoriesMoldingHeightInch
                                        : []
                                      ) // Default case if none of the above conditions are met
                                        .slice(0, displayedAccessoryHeightLimit)
                                        .map((item, index) => {
                                          const checkboxId = `accHeight-${index}`; // Unique ID for each checkbox
                                          const count =
                                            accessoriesHeightCounts[
                                              item.thickness
                                            ] || 0;
                                          return (
                                            <>
                                              <div className="check-widget">
                                                <input
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={selectedAccessoryHeight.includes(
                                                    item.thickness
                                                  )}
                                                  onChange={() => {
                                                    const updatedLength =
                                                      selectedAccessoryHeight.includes(
                                                        item.thickness
                                                      )
                                                        ? selectedAccessoryHeight.filter(
                                                            (len) =>
                                                              len !==
                                                              item.thickness
                                                          )
                                                        : [
                                                            ...selectedAccessoryHeight,
                                                            item.thickness,
                                                          ];
                                                    setSelectedAccessoryHeight(
                                                      updatedLength
                                                    );
                                                    // Call your filtering logic with updated categories
                                                    handleFilterChange(
                                                      "thickness",
                                                      updatedLength
                                                    );
                                                  }}
                                                />{" "}
                                                <label htmlFor={checkboxId}>
                                                  {item.thickness} ({count})
                                                </label>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {(currentShownSubCategoryName.includes(
                                        "Wall Base"
                                      )
                                        ? AccessoriesWallBaseHeight
                                        : currentShownSubCategoryName.includes(
                                            "Moldings & Trims"
                                          )
                                        ? AccessoriesMoldingHeightInch
                                        : []
                                      ).length > 4 && ( // Default case if none of the above conditions are met // Only show button if more than 4 colors are available
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !accessoryHeightExpanded; // Toggle the expanded state
                                            setAccessoryHeightExpanded(
                                              isExpanded
                                            ); // Update the expanded state
                                            setDisplayedAccessoryHeightLimit(
                                              isExpanded
                                                ? (currentShownSubCategoryName.includes(
                                                    "Wall Base"
                                                  )
                                                    ? AccessoriesWallBaseHeight
                                                    : currentShownSubCategoryName.includes(
                                                        "Moldings & Trims"
                                                      )
                                                    ? AccessoriesMoldingHeightInch
                                                    : []
                                                  ).length // Default case if none of the above conditions are met
                                                : 4
                                            );
                                          }}
                                        >
                                          {accessoryHeightExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {/* *************  Accessories sub height in. END ******/}
                          {/* ******************** Accessories sub Finish ********* */}
                          {currentShownSubCategoryName.includes(
                            "Moldings & Trims"
                          ) && (
                            <>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accFinish"
                                    aria-expanded="false"
                                    aria-controls="accFinish"
                                  >
                                    Finish
                                  </button>
                                </h2>
                                <div
                                  id="accFinish"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsMoldingFinish
                                        .slice(0, displayedAccessoryFinishLimit)
                                        .map((item, index) => {
                                          const checkboxId = `accfinish-${item.Finish}-${index}`;
                                          const count =
                                            accessoriesFinishCounts[
                                              item.Finish
                                            ] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedAccessoryFinish.includes(
                                                  item.Finish
                                                )}
                                                onChange={() => {
                                                  const updatedTileFinish =
                                                    selectedAccessoryFinish.includes(
                                                      item.Finish
                                                    )
                                                      ? selectedAccessoryFinish.filter(
                                                          (finish) =>
                                                            finish !==
                                                            item.Finish
                                                        )
                                                      : [
                                                          ...selectedAccessoryFinish,
                                                          item.Finish,
                                                        ];
                                                  setSelectedAccessoryFinish(
                                                    updatedTileFinish
                                                  );
                                                  handleFilterChange(
                                                    "Finish",
                                                    updatedTileFinish
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.display} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {menuItemsMoldingFinish.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !accessoryFinishExpanded;
                                            setAccessoryFinishExpanded(
                                              isExpanded
                                            );
                                            setDisplayedAccessoryFinishLimit(
                                              isExpanded
                                                ? menuItemsMoldingFinish.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {accessoryFinishExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {/* ******************** Accessories sub Floor Vent Format ********* */}
                          {currentShownSubCategoryName.includes(
                            "Moldings & Trims"
                          ) && (
                            <>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accFormat"
                                    aria-expanded="false"
                                    aria-controls="accFormat"
                                  >
                                    Format (in.)
                                  </button>
                                </h2>
                                <div
                                  id="accFormat"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <form action="">
                                      {menuItemsFloorVentFormat
                                        .slice(0, displayedFloorVentFormatLimit)
                                        .map((item, index) => {
                                          const checkboxId = `accfinish-${item.Format}-${index}`;
                                          const count =
                                            accessoriesFormatCounts[
                                              item.Format
                                            ] || 0;
                                          return (
                                            <div
                                              key={index}
                                              className="check-widget"
                                            >
                                              <input
                                                type="checkbox"
                                                id={checkboxId}
                                                checked={selectedFloorVentFormat.includes(
                                                  item.Format
                                                )}
                                                onChange={() => {
                                                  const updatedTileFinish =
                                                    selectedFloorVentFormat.includes(
                                                      item.Format
                                                    )
                                                      ? selectedFloorVentFormat.filter(
                                                          (finish) =>
                                                            finish !==
                                                            item.Format
                                                        )
                                                      : [
                                                          ...selectedFloorVentFormat,
                                                          item.Format,
                                                        ];
                                                  setSelectedFloorVentFormat(
                                                    updatedTileFinish
                                                  );
                                                  handleFilterChange(
                                                    "Format",
                                                    updatedTileFinish
                                                  );
                                                }}
                                              />
                                              <label htmlFor={checkboxId}>
                                                {item.display} ({count})
                                              </label>
                                            </div>
                                          );
                                        })}
                                      {menuItemsFloorVentFormat.length > 4 && (
                                        <button
                                          type="button"
                                          className="show-more-btn"
                                          onClick={() => {
                                            const isExpanded =
                                              !floorVentFormatExpanded;
                                            setFloorVentFormatExpanded(
                                              isExpanded
                                            );
                                            setDisplayedFloorVentFormatLimit(
                                              isExpanded
                                                ? menuItemsFloorVentFormat.length
                                                : 4
                                            );
                                          }}
                                        >
                                          {floorVentFormatExpanded
                                            ? "Show Less"
                                            : "Show More"}
                                        </button>
                                      )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {/* *************Flooring / wood catagory collection name ******/}
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Collection Name
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <form action="">
                              {currentCollectionList
                                .slice(0, displayedCollectionsLimit)
                                .map((item, index) => {
                                  const checkboxId = `collection-${index}`; // Unique ID for each checkbox
                                  const count =
                                    productCollectionCounts[item.collection] ||
                                    0;
                                  return (
                                    <>
                                      <div className="check-widget">
                                        <input
                                          type="checkbox"
                                          id={checkboxId}
                                          checked={selectedCollection.includes(
                                            item.collection
                                          )}
                                          onChange={() => {
                                            // If the category is selected, remove it; otherwise, add it
                                            const updatedCollection =
                                              selectedCollection.includes(
                                                item.collection
                                              )
                                                ? selectedCollection.filter(
                                                    (color) =>
                                                      color !== item.collection
                                                  )
                                                : [
                                                    ...selectedCollection,
                                                    item.collection,
                                                  ];

                                            setSelectedCollection(
                                              updatedCollection
                                            );
                                            console.log(
                                              "Updated selectedColor:",
                                              updatedCollection
                                            );

                                            // Call your filtering logic with updated categories
                                            handleFilterChange(
                                              "collection",
                                              updatedCollection
                                            );
                                          }}
                                        />{" "}
                                        <label htmlFor={checkboxId}>
                                          {item.collection} ({count})
                                        </label>
                                      </div>
                                    </>
                                  );
                                })}
                              {currentCollectionList.length > 4 && ( // Only show button if more than 4 colors are available
                                <button
                                  type="button"
                                  className="show-more-btn"
                                  onClick={() => {
                                    const isExpanded = !collectionsExpanded; // Toggle the expanded state
                                    setCollectionsExpanded(isExpanded); // Update the expanded state
                                    setDisplayedCollectionsLimit(
                                      isExpanded
                                        ? currentCollectionList.length
                                        : 4
                                    );
                                  }}
                                >
                                  {collectionsExpanded
                                    ? "Show Less"
                                    : "Show More"}
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>

                      {/* ************* ALL END ******/}
                    </div>
                  </div>

                  {/* ************* ALL regular copy END ******/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarFilters;
