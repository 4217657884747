import React from "react";

function Privacy() {
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>Privacy Policy</li>
          </ul>
        </div>
      </div>

      {/* ******************************* About us ****************** */}
      <div className="about-us-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 about-content">
            <h1><b>Privacy Policy</b></h1>
    <p><b>Privacy policy in effect as of May 1st, 2024.</b></p>
    <p>FlooringDeals.ca ("we," "us," or "our") values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit or interact with our website, FlooringDeals.ca (the "Site"). By using our Site, you agree to the terms of this Privacy Policy.</p>

    <h2><b>1. Collection of Information</b></h2>
    <p>When you use our Site, sign up for our newsletter, fill out a contact form, or request a quote, we collect personal information from you. The types of personal information we collect may include your name, email address, phone number, and any other relevant information you choose to provide.</p>
    <p>We also collect non-personal information about your use of our Site, such as your IP address, browser type, operating system, and other technical details. This information helps us improve the Site and provide a better user experience.</p>

    <h2><b>2. Use of Information</b></h2>
    <p>We use the information we collect from you for various purposes, including:</p>
    <ul>
        <li>To provide the products and services you request;</li>
        <li>To respond to your inquiries or requests for information;</li>
        <li>To send you marketing communications, such as newsletters and promotional offers;</li>
        <li>To personalize and improve your experience on our Site;</li>
        <li>To monitor and analyze usage and trends to improve the functionality of our Site;</li>
        <li>To protect the security and integrity of our Site; and</li>
        <li>To comply with legal obligations and enforce our policies.</li>
    </ul>
    <br/>
    <h2><b>3. Disclosure of Information</b></h2>
    <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your personal information with trusted third parties who assist us in operating our Site, providing our services, or conducting our business as long as these parties agree to keep your information confidential.</p>
    <p>We may also disclose your personal information if required by law or in good faith belief that such action is necessary to comply with legal requirements, protect our rights, or enforce our policies.</p>

    <h2><b>4. Cookies and Tracking Technologies</b></h2>
    <p>We use cookies and other tracking technologies to collect and store information about your visit to our Site. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser, allowing the Site to recognize your browser and remember certain information.</p>
    <p>You can choose to disable cookies in your browser settings; however, this may limit the functionality of our Site.</p>

    <h2><b>5. Third-Party Links</b></h2>
    <p>Our Site may contain links to third-party websites. These third-party sites have separate and independent privacy policies. We have no responsibility or liability for the content and activities of these linked sites. We encourage you to read the privacy policies of each website you visit.</p>

    <h2><b>6. Security</b></h2>
    <p>We implement reasonable security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to protect your data, we cannot guarantee its security.</p>

    <h2><b>7. Changes to This Privacy Policy</b></h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on this page with a new effective date. Your continued use of the Site after we post the updated Privacy Policy constitutes your acceptance of the changes.</p>

    <h2><b>8. Contact Us</b></h2>
    <p>If you have any questions about this Privacy Policy or our privacy practices, please get in touch with us at:</p>
    <p>
      FlooringDeals.ca<br/>
       1606 Sedlescomb Dr<br/>
       Mississauga ON L4X 1M6<br/>
       Email: <a href="mailto:flooringdeals.info@gmail.com">flooringdeals.info@gmail.com</a><br/>
       Phone: 905-247-6555
       </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
