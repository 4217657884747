import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"; // useLocation is used to track route changes
import NoProductPage from "../components/flooring/NoProductPage";
import new_logo from "../assets/images/icons/new-logo.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { getFev } from "../store/slices/FavSlices";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ Product }) => {
  const dispatch = useDispatch();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [refresh, setRefresh] = useState(false);
  const fevProduct = useSelector((state) => state.favroite.data);
  const navigate = useNavigate();
  const location = useLocation(); // Track route changes

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const AddFavroites = (item) => {
    if (userData && userData.user && userData.user.email) {
      const userID = userData.user.email;
      const obj = {
        userId: userID,
        isFev: true,
        productId: item._id,
        product: item,
      };

      axios
        .post("https://allinonetables.com/api/fev/create", obj)
        .then((res) => {
          toast.success("Product Added to Favorites");
          dispatch(getFev());
        })
        .catch((err) => console.log(err));
    } else {
      window.location.href = "/login";
    }
  };

  const removeFaverites = (id) => {
    if (userData && userData.user && userData.user.email) {
      if (id) {
        axios
          .delete(
            `https://allinonetables.com/api/fev/delete_by_productId/${id}`
          )
          .then((res) => {
            dispatch(getFev());
            setRefresh(!refresh);
            toast.error("Favorite Product Deleted From List.");
          })
          .catch((err) => console.log(err));
      }
    } else {
      toast.error("Please log in to manage favorites");
    }
  };

  const CheckisFev = (id) => {
    if (fevProduct?.length >= 0) {
      return fevProduct.some((item) => item.productId === id);
    }
  };

  function formatPrice(price) {
    if (typeof price !== "number" || isNaN(price)) {
      return "Invalid Price";
    }
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return (
    <div className="shop-bottom-area">
      <div className="tab-content jump">
        <div id="shop-1" className="tab-pane active">
          <div className="row">
            {Product?.length > 0 ? (
              <>
                {Product.map((item) => (
                  <div
                    className="col-6 col-lg-3 col-md-4 col-sm-6 mb-35"
                    key={item._id}
                  >
                    <div className="product-wrap mb-3 h-100">
                      <div className="product-img img-zoom">
                        <Link to={`/productdetail/${item.slug}`}>
                          <img
                            src={
                              item?.productPictures[0] ||
                              "https://images.homedepot.ca/productimages/p_1001708289.jpg?product-images=m"
                            }
                            alt=""
                          />
                          <img
                            id="change-img"
                            src={
                              item?.productPictures[1] ||
                              item?.productPictures[0]
                            }
                            alt=""
                          />
                        </Link>
                        {item.BestSeller && (
                          <div className="product-badge badge-top badge-right badge-pink">
                            <span>Best Seller</span>
                          </div>
                        )}
                        {item.IsmostViewed && (
                          <div className="product-badge badge-top badge-right badge-pink">
                            <span>Most Viewed</span>
                          </div>
                        )}
                        {item.isDiscount && (
                          <div className="discount-badge">
                            <span>Discount</span>
                          </div>
                        )}
                        {item.newProduct && (
                          <div className="new-badge">
                            <span>
                              <img src={new_logo} alt="" />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="product-content h-100">
                        <h3>
                          <Link to={`/productdetail/${item.slug}`}>
                            {item?.name}
                          </Link>
                        </h3>
                        <p>{item.short_desc}</p>
                        <div className="product-price">
                          <span className="new-price">
                            $
                            {item.isDiscount
                              ? formatPrice(item.sale_price)
                              : formatPrice(item.price)}
                            <span
                              className="new-price"
                              style={{
                                position: "relative",
                                bottom: "0",
                                color: "#555252",
                              }}
                            >
                              / sq. ft.
                            </span>
                          </span>
                          <i
                            className={`bi bi-${
                              CheckisFev(item._id)
                                ? "heart-fill"
                                : "suit-heart"
                            } ${
                              CheckisFev(item._id) ? "text-danger" : ""
                            } favorite-heart`}
                            role="button"
                            title="Add To Favorite"
                            onClick={() => {
                              if (CheckisFev(item._id)) {
                                removeFaverites(item._id);
                              } else {
                                AddFavroites(item);
                              }
                            }}
                          ></i>
                        </div>
                        <Link to={`/productdetail/${item.slug}`}>
                          <button
                            type="button"
                            className="add-quote-summery-btn mt-3 text-light"
                            aria-label="Add product to cart"
                          >
                            Add to Cart <i className="ps-2 bi bi-cart3"></i>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <NoProductPage />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
