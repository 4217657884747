import React from "react";

function Abuotus() {
  return (
    <div>
      {/* ************************ breadcrumb ******************** */}

      <div className="breadcrumb mb-0">
        <div className="container">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {">"}
            <li>About Us</li>
          </ul>
        </div>
      </div>

      {/* ******************************* About us ****************** */}
      <div className="about-us-area pb-100">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="about-content">
                {/* <h2 data-aos="fade-up" data-aos-delay="200">Furniture</h2>
                                <h1 data-aos="fade-up" data-aos-delay="300">Best Furniture</h1> */}
                <p data-aos="fade-up" data-aos-delay="400">
                Welcome to Flooring Deals, your premier destination for exceptional flooring and tile solutions in Mississauga. We're dedicated to providing high-quality products at unbeatable prices, making your flooring dreams a reality. With a commitment to customer satisfaction and a vast selection of flooring options, we're here to help you create the perfect space for your home or business. Discover the best deals on flooring with us at Flooring Deals.<br />
                <br /><strong style={{ color: 'black' }}>Swift Delivery:</strong>  We pride ourselves on our fast and reliable shipping services, ensuring your order arrives promptly.
                <br /><strong style={{ color: 'black' }}>Careful Packaging:</strong>  Your flooring products are carefully packaged to prevent damage during transit, guaranteeing they arrive in perfect condition.
                <br /><strong style={{ color: 'black' }}>Track Your Order:</strong>  Keep track of your shipment with our easy-to-use tracking system, providing real-time updates on your delivery status.
                <br /><strong style={{ color: 'black' }}>Customer Support:</strong> Our dedicated customer support team is available to assist you with any questions or concerns regarding your shipment.
                </p>
                <p className="mrg-inc" data-aos="fade-up" data-aos-delay="500">
                <strong style={{ color: 'black' }}>Browse and Select:</strong>  Explore our extensive collection of flooring products and tiles from the comfort of your home. Find the perfect options to suit your style and budget.
                <br /><strong style={{ color: 'black' }}>Add to Cart: </strong>Once you've made your selections, simply add them to your cart and proceed to checkout. Review your order to ensure accuracy.
                <br /><strong style={{ color: 'black' }}>Secure Payment: </strong>  Complete your purchase securely using our encrypted payment gateway. We accept various payment methods for your convenience.
                <br /><strong style={{ color: 'black' }}>Fast Delivery: </strong> Sit back and relax as we swiftly process your order. Enjoy prompt delivery of your flooring products right to your doorstep, ready to enhance your space.{" "}
              </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-img"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <img
                  src="https://images.unsplash.com/photo-1613621792067-8e28d16b735c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Zmxvb3Jpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 about-content">
              {/* <p>
              Why Us: We pride ourselves on our fast and reliable shipping services, ensuring your order arrives promptly.
Careful Packaging: Your flooring products are carefully packaged to prevent damage during transit, guaranteeing they arrive in perfect condition.
Track Your Order: Keep track of your shipment with our easy-to-use tracking system, providing real-time updates on your delivery status.
Customer Support: Our dedicated customer support team is available to assist you with any questions or concerns regarding your shipment.
              </p> */}
              {/* <p className="mrg-inc" data-aos="fade-up" data-aos-delay="500">
              Browse and Select: Explore our extensive collection of flooring products and tiles from the comfort of your home. Find the perfect options to suit your style and budget.
Add to Cart: Once you've made your selections, simply add them to your cart and proceed to checkout. Review your order to ensure accuracy.
Secure Payment: Complete your purchase securely using our encrypted payment gateway. We accept various payment methods for your convenience.
Fast Delivery: Sit back and relax as we swiftly process your order. Enjoy prompt delivery of your flooring products right to your doorstep, ready to enhance your space.{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Abuotus;
