import React, { useEffect, useState, useRef } from "react";
import "./checkout.css";
import ShippingAddress from "./ShippingAddress";
import DeliveryOption from "./DeliveryOption";
import WarehouseModal from "./WarehouseModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
// import imageSrc from "./"; // Adjust the import path

function CheckoutContact() {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);

  const [warehouses, setWarehouses] = useState([]);
  const [werehouse, setwerehouse] = useState();
  const [matchedWarehouses, setMatchedWarehouses] = useState({});

  const [isMOdalClose, setisMOdalClose] = useState(false);
  const [provinces, setProvinces] = useState([]);

  const [showContactInfo, setShowContactInfo] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [showDelivaryOption, setshowDelivaryOption] = useState(false);
  const [showPaymentDetails, setshowPaymentDetails] = useState(false);
  const [showReview, setshowReview] = useState(false);

  const [orderItems, setOrderItems] = useState({});
  const tax = 13;
  const [originalPrice, setOriginalPrice] = useState(0);
  const [Sub_Total, setSub_Total] = useState(0);
  const [taxval, setTaxval] = useState(0);
  const [GST, setGST] = useState(0);
  const [HST, setHST] = useState(0);
  const [PST, setPST] = useState(0);
  const [QST, setQST] = useState(0);
  const [i, seti] = useState(false);
  const [searchId, setsearchId] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [Address, setAddress] = useState();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);


  const [totalDeliveryCharge, setTotalDeliveryCharge] = useState(0);

  const [pick, setPick] = useState({
    pickup_firstName: "",
    pickup_lastName: "",
    pickup_email: "",
    pickup_Date: "",
  });

  useEffect(() => {
    if (userData == null || userData === undefined || userData === "") {
      const localCartData = localStorage.getItem('cart');
      const cartData = localCartData ? JSON.parse(localCartData) : [];
      console.log('cartData', cartData)
      setCart(cartData);
      manageCartData(cartData); 

    } else {
      axios
        .get(`https://allinonetables.com/api/cart/${userData.user.email}`)
        .then((res) => {
          const resData = res.data;
          // console.log(129, resData);
          setCart(resData);
          manageCartData(resData);
        })
        .catch((err) => console.log(err));

    }
    axios
        .get("https://allinonetables.com/api/provinces/getAll")
        .then((response) => {
          // console.log(28, response.data);
          setProvinces(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
  }, [i]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch warehouses
        const warehousesResponse = await axios.get(`https://allinonetables.com/api/werehouse/getAll`);
        setWarehouses(warehousesResponse.data); // Assuming the data is directly in the response
        console.log("Warehouses:", warehousesResponse.data);

        // Existing logic for fetching products...
      } catch (error) {
        console.error("Error fetching warehouse data:", error);
      }
    };

    fetchData();
  }, []); 
  useEffect(() => {
    const newMatchedWarehouses = {};
    cart.forEach(item => {
      const matchingWarehouse = warehouses.find(warehouse => warehouse.brandName === item.BrandName);
      if (matchingWarehouse) {
        newMatchedWarehouses[item._id] = matchingWarehouse.werehouseAdd;
      }
    });
    setMatchedWarehouses(newMatchedWarehouses);
  }, [cart, warehouses]);


  function generateUniqueIdentifier() {
    let uniqueIdentifier = 0;

    const currentDatetime = new Date();
    const formattedDatetime = `${currentDatetime.getFullYear()}${(
      currentDatetime.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${currentDatetime
        .getDate()
        .toString()
        .padStart(2, "0")}${currentDatetime
          .getHours()
          .toString()
          .padStart(2, "0")}${currentDatetime
            .getMinutes()
            .toString()
            .padStart(2, "0")}${currentDatetime
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;
    const randomCode = Math.floor(Math.random() * 90 + 10);
    uniqueIdentifier = `${formattedDatetime}${randomCode}`;
    // localStorage.setItem("uniqueId", uniqueIdentifier);

    return uniqueIdentifier;
  }
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [data, setData] = useState({
    firstName: userData?.user?.firstName || "",
    lastName: userData?.user?.lastName || "",
    userEmail: userData?.user?.email || "",
    email: userData?.user?.email || "",
    orderId: generateUniqueIdentifier(),
    orderItems: "",
    address: "",
    company: "",
    city: "",
    state: "",
    stateId: "",
    StatetaxRate: "",
    Apartment: "",
    GST,
    HST,
    PST,
    QST,
    GSTPer: "",
    HSTPer: "",
    PSTPer: "",
    QSTPer: "",
    postalCode: "",
    mNo: "",
    deliveryCharge: "",
    pickup_firstName: "",
    pickup_lastName: "",
    pickup_email: "",
    pickup_Date: "",
    totalPrice: "",
    stripe_id: "",
    paymentStatus: "",
    tax: "",
  });
  const getOneTaxAdd = (id) => { };

  const manageCartData = (cartData) => {
    console.log(126, cartData);
    let totalPrice = 0;
    let dCharge = 0;
    cartData.forEach((item) => {
      console.log("ok");
      totalPrice += parseFloat(item.total || 0);
    if (!item.is_werehousePickup) {
      dCharge += parseFloat(item.deliveryCharge || 0);
    }
    });

    setSub_Total(totalPrice);
    setOriginalPrice(totalPrice + dCharge);

    // Handle address fetching or setting defaults
  if (userData && userData.user && userData.user.email) {

    axios
      .get(`https://allinonetables.com/api/address/get/${userData.user.email}`)
      .then((res) => {
        const response = res.data[0];
        console.log(12999, response?.StatetaxRate[0]);
        setAddress(response);
        console.log(229, data.StatetaxRate);
        if (response !== undefined && response !== null) {
          ChangeCalonPrivincess(response.StatetaxRate[0]);
        } else if (
          data.StatetaxRate !== undefined &&
          data.StatetaxRate !== null
        ) {
          ChangeCalonPrivincess(data.StatetaxRate);
        }
        setData((prevData) => ({
          ...prevData,
          orderItems: cartData,
          tax: "",
          address: response?.address || "",
          company: response?.cName || "",
          city: response?.city || "",
          Apartment: response?.Apartment || "",
          postalCode: response?.postalCode || "",
          mNo: response?.mNo || "",
        }));
      })
      .catch((err) => console.log(err));

    } else {
      setData(prevData => ({
        ...prevData,
        address: "",
        city: "",
        postalCode: "",
      }));

    }
  };

  useEffect(() => {
    console.log("Updated data state:", data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleMnoChange = (e) => {
    const inputValue = e.target.value;
  
    // Filter out non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, '');
  
    // Update the state with the numeric value
    setData({ ...data, mNo: numericValue });
  };
  const handleChangepick = (e) => {
    const { name, value } = e.target;
    const selectedDate = new Date(value);
    const dayOfWeek = selectedDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    if (dayOfWeek === 5 || dayOfWeek === 6) {
       console.log("Weekends are not allowed.");
    } else {
      setPick({
        ...pick,
        [name]: value,
      });
    }
  };

  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }

  const initializeModal = () => {
    const tryClickButton = () => {
      const modalButton = document.getElementById("myButton");
      if (modalButton) {
        modalButton.addEventListener("click", () => {
          setisMOdalClose(false);
        });
  
        // If the button exists, click it
        modalButton.click();
      } else {
        // If the button doesn't exist, try again after a short delay
        setTimeout(tryClickButton, 100);
      }
    };
  
    tryClickButton();
  };
  

  // Call the function to initialize the modal when it's rendered

  const setWereHousePickup = (id) => {
    console.log(127, id);
    const singleCart = cart.filter((item) => {
      console.log("Checking item with ID:", item._id); // Log the ID being checked
      return item._id === id;
  });
    const matchingWarehouse = warehouses.find((warehouse) => warehouse.brandName === singleCart[0].BrandName);
    console.log("Selected brand:", singleCart[0].BrandName); // Log the selected brand name
    if (matchingWarehouse) {
        console.log("Matching warehouse found:", matchingWarehouse); // Log the matching warehouse details if found
    } else {
        console.log("No matching warehouse found for brand:", singleCart[0].BrandName);
        toast.error("Can't pick up at this brand yet, please contact customer service.");
        return;  // Exit the function if no warehouse is found
    }

    singleCart[0].is_werehousePickup = true;
    singleCart[0].pickup_firstName = pick.pickup_firstName;
    singleCart[0].pickup_lastName = pick.pickup_lastName;
    singleCart[0].pickup_email = pick.pickup_email;
    singleCart[0].pickup_Date = pick.pickup_Date;
    singleCart[0].WerehouseAddress = matchingWarehouse.werehouseAdd;
    // Update for logged-in users
  if (userData && userData.user) {

    axios
      .put(`https://allinonetables.com/api/cart/${id}`, singleCart[0])
      .then((res) => {
        const resData = res;
        console.log(129, resData);
        toast.success("Cart Updated Succefully");
        seti(!i);


        setPick({
          pickup_firstName: "",
          pickup_lastName: "",
          pickup_email: "",
          pickup_Date: "",
        })
        initializeModal();
        console.log(127, singleCart[0]);
      })
      .catch((err) => console.log(err));
  } else {
    const index = cart.findIndex(item => item._id === id); // Find the index of the item to update
    if (index === -1) {
        console.log("Item not found with ID:", id);
        toast.error("Item not found.");
        return;
    }
    const item = cart[index];
    const matchingWarehouseNoUser = warehouses.find(warehouse => warehouse.brandName === item.BrandName);
    console.log("Selected brand:", item.BrandName); // Log the selected brand name
    if (!matchingWarehouseNoUser) {
        console.log("No matching warehouse found for brand:", item.BrandName);
        toast.error("Can't pick up at this brand yet, please contact customer service.");
        return;
    }

    // Update the item with pickup details
    const updatedItem = {
      ...item,
      is_werehousePickup: true,
      pickup_firstName: pick.pickup_firstName,
      pickup_lastName: pick.pickup_lastName,
      pickup_email: pick.pickup_email,
      pickup_Date: pick.pickup_Date,
      WerehouseAddress: matchingWarehouse.werehouseAdd
  };

  // Update the cart array with the updated item
  const updatedCart = [
    ...cart.slice(0, index),
    updatedItem,
    ...cart.slice(index + 1)
];
    
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setCart(updatedCart); // Update the cart in the React state
    toast.success("Cart updated");
    console.log(128, updatedCart);

    setPick({
      pickup_firstName: "",
      pickup_lastName: "",
      pickup_email: "",
      pickup_Date: "",
    })
    initializeModal();
  }
  };

  const setHomeDelewary = (id) => {
    console.log(id);
    const singleCart = cart.filter((item) => item._id === id);
    if (singleCart[0].is_werehousePickup) {
      singleCart[0].is_werehousePickup = false;

      // Define the updatedCartItem with the changed delivery method
  const updatedCartItem = {
    ...singleCart,
    is_werehousePickup: false,  // Change to scheduled delivery
    deliveryCharge: 100 
  };

      // Handle update for logged-in users
    if (userData && userData.user) {
      axios
        .put(`https://allinonetables.com/api/cart/${id}`, singleCart[0])
        .then((res) => {
          const resData = res;
          console.log(129, resData);
          toast.success("Cart Updated Succefully");
          seti(!i);
        })
        .catch((err) => console.log(err));
    } else {
      const index = cart.findIndex(item => item._id === id); // Find the index of the item to update
    if (index === -1) {
        console.log("Item not found with ID:", id);
        toast.error("Item not found.");
        return;
    }

    const item = cart[index];

    // Update the item with home delivery details
    const updatedItem = {
      ...item,
      is_werehousePickup: false, // Change to scheduled delivery
  };

      // Handle local storage update for non-logged-in users
      const updatedCart = [
        ...cart.slice(0, index),
        updatedItem,
        ...cart.slice(index + 1)
    ];
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCart(updatedCart); // Update the cart in the React state
      toast.success("Scheduled delivery updated");
    }

      
    }
    console.log(128, singleCart);
  };

  const Payment = async () => {
    console.log(177, data);
    try {
      const stripe = await loadStripe(
        "pk_test_51Og0ZUSH3DlARmoKFXUdn9b7bkzyiLqEmizZdIpeAE585R8rSqDkPC92gttUDjpQrSSlQEgI8eZtMJUOQz2r39VE00l4Jnu6Qi"
      );

      // Assume 'data' is defined elsewhere
      const response = await axios.post(
        "https://allinonetables.com/api/payment/create",
        
        data
      );
      console.log(response);

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.data.stripe_id,
      });

      // Handle the result if needed
      console.log(296, result);
    } catch (error) {
      console.error("Error during payment:", error);
      // Handle error, show a user-friendly message, etc.
    }
  };

  const ChangeCalonPrivincess = (item) => {
    console.log(248, item);
    console.log(originalPrice);
    const { GSTrate, HSTrate, PSTrate, QSTrate } = item;
    let totalPrice = 0;
    let dCharge = 0;
    cart.forEach((cardData) => {
      totalPrice += cardData.total;
      if (cardData.is_werehousePickup === false) {
        dCharge += cardData.deliveryCharge;
      }
    });
    const gst = GSTrate ? (totalPrice * GSTrate) / 100 : 0;
    const hst = HSTrate ? (totalPrice * HSTrate) / 100 : 0;
    const pst = PSTrate ? (totalPrice * PSTrate) / 100 : 0;
    const qst = QSTrate ? (totalPrice * QSTrate) / 100 : 0;
    console.log("gst:", gst, "hst:", hst, "pst:", pst, "qst:", qst);
    setGST(gst);
    setHST(hst);
    setPST(pst);
    setQST(qst);
    const allAdd = totalPrice + gst + hst + pst + qst + dCharge;
    console.log(totalPrice, gst, hst, pst, qst);
    console.log(261, allAdd);
    setOriginalPrice(allAdd);
    setData((prevData) => ({
      ...prevData,
      GST: gst,
      HST: hst,
      QST: qst,
      PST: pst,
      totalPrice: allAdd,
      GSTPer: GSTrate != null ? GSTrate : 0,
      HSTPer: HSTrate != null ? HSTrate : 0,
      QSTPer: QSTrate != null ? QSTrate : 0,
      PSTPer: PSTrate != null ? PSTrate : 0,
    }));
  };

  function getCurrentDate() {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
    //---------show sqft or not based on different categories-----------
  // Decide based on the product name if certain UI elements should be hidden
      // Define the categories array outside the component for efficiency
const excludedCategories = [
  "Mortars", "Grout", "Caulk", "Sealants", "Floor Patch",
  "Levelers", "Primers", "Adhesives", "Adhesive", "Wall Base",
  "Moldings", "Floor Vent", "Underlayments", "Underlayment", "Membrane", "Membranes"
];

// Helper function to determine if the name includes any excluded category
const includesExcludedCategory = name => {
  return name && excludedCategories.some(category => name.includes(category));
};

useEffect(() => {
  const totalDelivery = cart.reduce((acc, item) => {
      return item.is_werehousePickup ? acc : acc + item.deliveryCharge;
  }, 0);
  setTotalDeliveryCharge(totalDelivery);
}, [cart]);  // Re-calculate whenever the cart changes

  //---------show sqft or not based on different categories End-----------

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
            <div className="back-option pb-3">
              <a href="/">
                <i class="fa fa-arrow-left"></i> <u>Continue Shoping</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* *************************Contact************************************ */}
      <div className="container">
        <div className="row">
          <div className="quote-summery-box-name-details">
            {/* <span className="quote-summery-number ps-2">{myStoredValue}</span> */}
          </div>
          <div className="quote-summery-heading ps-3  ">
            <h2>
              <strong>Contact information</strong>
            </h2>
          </div>
        </div>
      </div>

      <div className="checkout-area mb-50">
        <div className="container">
          <div className="row  flex-column-reverse flex-lg-row">
            <div className="col-12 clo-sm-12 col-md-12 col-lg-8 col-xl-8">
              <div className="billing-info-wrap">
                <div className="row">
                  {!showDelivaryOption && (
                    <>
                      <div className="col-lg-12 mb-3">
                        <div className="billing-info mb-20px">
                          <label>
                            Email Address <sup>Required</sup>
                          </label>
                          <input
                            type="email"
                            name="userEmail"
                            value={data.userEmail}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="billing-info mb-20px">
                          <label>
                            First Name <sup>Required</sup>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            value={data.firstName}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="billing-info mb-20px">
                          <label>
                            Last Name <sup>Required</sup>
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            value={data.lastName}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="billing-info mb-20px">
                          <label>Company</label>
                          <input
                            type="text"
                            name="company"
                            value={data.company}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      {showContactInfo ? (
                        <>
                          <div className="checkout-account mt-3">
                            {/* <ConditionModal /> */}
                            <div className="Place-order mt-25">
                              <a
                                className="btn-hover btn-default"
                                style={{ textDecoration: "none" }}
                                href="#"
                                onClick={() => {
                                  if (userData && userData.user) {
                                    // Logic for logged-in users
                                    if (data.email !== "" && data.firstName !== "" && data.lastName !== "") {
                                      setShowContactInfo(false);
                                      setShowAddress(true);
                                    } else {
                                      toast.error("Please fill required fields");
                                    }
                                  } else {
                                    // Logic for users who are not logged in
                                    if (data.userEmail !== "" && data.firstName !== "" && data.lastName !== "") {
                                      console.log("All fields filled for anonymous user");
                                      setShowContactInfo(false);
                                      setShowAddress(true);
                                    } else {
                                      console.log("Some fields are empty for anonymous user");
                                      toast.error("Please fill required fields for checkout");
                                    }
                                  }
                                }}
                              >
                                {" "}
                                Countinue to next step
                              </a>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* *************************************************Address*************************************************************** */}
                      {showAddress ? (
                        <>
                          <div className="col-lg-12 mb-3 mt-2">
                            <div className="billing-info mb-20px">
                              <label>
                                Shipping Address <sup>Required</sup>
                              </label>
                              <input
                                type="text"
                                name="address"
                                value={data.address}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="billing-info mb-20px">
                              <label>Apartment, suite, etc.</label>
                              <input
                                type="text"
                                name="Apartment"
                                value={data.Apartment}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="billing-info mb-20px">
                              <label>City <sup>Required</sup></label>
                              
                              <input
                                type="text"
                                name="city"
                                value={data.city}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 mt-2">
                            <div className="billing-info mb-20px">
                              <label>
                                Province <sup>Required</sup>
                              </label>
                              <select
                                className="form-select"
                                id="province"
                                aria-label="Select Province for Canada"
                                name="state"
                                onChange={(e) => {
                                  const selectedProvince = e.target.value;
                                  const findPro = provinces.find(
                                    (province) =>
                                      province.provinces_name ===
                                      selectedProvince
                                  );
                                  console.log(424, findPro);
                                  ChangeCalonPrivincess(findPro);
                                  setData((prevData) => ({
                                    ...prevData,
                                    state: findPro.provinces_name,
                                    stateId: findPro._id,
                                    StatetaxRate: findPro,
                                  }));
                                }}
                                value={data.state}
                                defaultValue="Select Province" // Set the default value
                              >
                                <option value="Select Province">
                                  Select Province
                                </option>
                                {/* Options for Canada */}
                                {provinces.map((item) => (
                                  <option
                                    key={item._id}
                                    value={item.provinces_name}
                                  >
                                    {item.provinces_name}
                                  </option>
                                ))}
                                {/* Add more options as needed */}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 mt-2">
                            <div className="billing-info mb-20px">
                              <label>
                                Postal Code <sup>Required</sup>
                              </label>
                              <input
                                type="text"
                                name="postalCode"
                                value={data.postalCode}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 mt-2">
                            <div className="billing-info mb-20px">
                              <label>
                                Phone Number <sup>Required</sup>
                              </label>
                              <input
                                type="tel"
                                name="mNo"
                                value={data.mNo}
                                onChange={(e) => handleMnoChange(e)}
                                // pattern="[0-9]{10}" // Specify your desired phone number pattern
                                // title="Please enter a valid 10-digit phone number"
                                // required

                                maxLength="10"
                                pattern="[0-9]{10}"
                              />
                            </div>
                          </div>

                          <div className="checkout-account mt-3">
                            <div className="Place-order mt-25">
                              <a
                                className="btn-hover btn-default "
                                href="#"
                                style={{ textDecoration: "none" }}
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent default anchor behavior
                                  console.log("Address:", data.address);
                                  console.log("Apartment:", data.Apartment);
                                  console.log("City:", data.city);
                                  console.log("State:", data.state);
                                  console.log("Postal Code:", data.postalCode);
                                  console.log("Phone Number:", data.mNo);

                                  if (
                                    data.address !== "" &&
                                    data.city !== "" &&
                                    data.state !== "" &&
                                    data.postalCode !== "" &&
                                    data.mNo !== ""
                                  ) {
                                    console.log("All fields filled");
                                    console.log(data);
                                    setshowDelivaryOption(true);
                                  } else {
                                    console.log("Some fields are empty");
                                    toast.error("Please fill required fields");
                                  }
                                }}
                              >
                                Continue to next step
                              </a>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* *************************************************Address End*************************************************************** */}
                    </>
                  )}
                  {/* *************************************************DelivaryOption Start*************************************************************** */}
                  {showDelivaryOption ? (
                    <>
                      {cart.map((item, index) => {
                        return (
                          <>
                            <div className="checkoutDeliveryOption border">
                              <div className="row g-3">
                                <div className="contactinfo1">
                                  <div className="deliverymain">
                                    <div className="border-bottom">
                                      <h5 className="ship">
                                        Shipment {cart.length} of {index + 1}
                                      </h5>
                                      <p className="depart mb-3">
                                        Departing from {item.WerehouseAddress}
                                      </p>
                                    </div>
                                    <div className="deliverymain1">
                                      {/* <div className="col-3 col-sm-3 col-md-2 col-lg-1 col-xl-1">
                                        <div className="img-card">
                                          <img
                                            src="assets/images/cost-esimation/2.jpg"
                                            alt=""
                                            className=" img-fluid rounded-circle"
                                          />
                                        </div>
                                      </div> */}

                                      <div className="col-9 col-sm-9 col-md-10 col-lg-11 col-xl-11">
                                        <span>{item.ProductName}</span>
                                        <p className="quali">
                                          Quantity: {item.boxCovrage}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row deliverymain2 pt-3">
                                      <h5 className="delivry ">
                                        Select a delivery method:
                                      </h5>

                                      {/* ********************** Warehouse *********** */}
                                        {" "}

                                        {
                                          // item.WerehouseAddress !== "" && (
                                            <>

                                            <div className=" col-md-6 col-lg-6 col-xl-6 pt-4">
                                              <div
                                                className="paracel"
                                                data-bs-toggle="modal"
                                                data-bs-target="#staticBackdrop"
                                              >
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <h6 className="m-0">
                                                    Warehouse Pickup
                                                  </h6>

                                                  <input
                                                    type="checkbox"
                                                    checked={item.is_werehousePickup}
                                                    onClick={() => setCurrentItem(item)}
                                                    onChange={(e) => {

                                                      setwerehouse(item._id);
                                                    }}
                                                  />
                                                </div>
                                                <p className="mb-1 text-dark">
                                                  Warehouse address
                                                </p>

                                                <p className="m-0">
                                                  {" "}
                                                  {matchedWarehouses[item._id] || 'No matching warehouse found'}
                                                  {/* {item.WerehouseAddress} */}
                                                </p>
                                                <p className="m-0">
                                                  {" "}
                                                  Order online and pickup at our local
                                                  warehouse
                                                </p>
                                                <p>1 - 2 business days </p>
                                                <h6 className="fre m-0">free</h6>
                                              </div>
                                              {/* <WarehouseModal /> */}
                                              {/* ********************************************Pickup MOdala STart************************************************ */}
                                              <div
                                                className="modal fade"
                                                id="staticBackdrop"
                                                data-bs-backdrop="static"
                                                data-bs-keyboard="false"
                                                tabIndex="-1"
                                                aria-labelledby="staticBackdropLabel"
                                                aria-hidden="true"
                                              >
                                                <div className="modal-dialog modal-dialog-centered  modal-lg ">
                                                  <div className="modal-content ps-2 pe-2 pt-3 bg-color">
                                                    <div className="modal-header p-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', position: 'relative' }}>
                                                      <i className="bi bi-geo-alt pe-2 fs-3"></i>
                                                      <h1
                                                        className="modal-title fs-5 m-0"
                                                        id="staticBackdropLabel"
                                                      >
                                                        Warehouse Pickup Details
                                                        <p>{currentItem ? currentItem.WerehouseAddress : 'No Address Available'}</p>
                                                      </h1>
                                                      <button
                                                        type="button"
                                                        className="btn-close "
                                                        style={{ marginTop: '-0.5rem' }}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                      ></button>
                                                    </div>
                                                    <div className="modal-body">
                                                      <form className="row g-3">
                                                        <div className="col-md-6">
                                                          <label
                                                            for="inputEmail4"
                                                            className="form-label m-0"
                                                          >
                                                            First name of pick-up
                                                            person
                                                          </label>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            id="inputEmail4"
                                                            name="pickup_firstName"
                                                            value={
                                                              pick.pickup_firstName
                                                            }
                                                            onChange={(e) =>
                                                              handleChangepick(e)
                                                            }
                                                          />
                                                        </div>
                                                        <div className="col-md-6">
                                                          <label
                                                            for="inputPassword4"
                                                            className="form-label m-0"
                                                          >
                                                            Last name of pick-up
                                                            person
                                                          </label>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            id="inputPassword4"
                                                            name="pickup_lastName"
                                                            value={
                                                              pick.pickup_lastName
                                                            }
                                                            onChange={(e) =>
                                                              handleChangepick(e)
                                                            }
                                                          />
                                                        </div>
                                                        <div className="col-md-12">
                                                          <span className="fs-6 bg-warning">
                                                            First and last name must
                                                            match governement-issued
                                                            photo ID
                                                          </span>
                                                        </div>
                                                        <div className="col-md-12">
                                                          <label
                                                            for="inputEmail4"
                                                            className="form-label m-0"
                                                          >
                                                            Email address of pick-up
                                                            person
                                                          </label>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            id="inputEmail4"
                                                            name="pickup_email"
                                                            value={pick.pickup_email}
                                                            onChange={(e) =>
                                                              handleChangepick(e)
                                                            }
                                                          />
                                                        </div>
                                                        <div className="col-md-6">
                                                          <label
                                                            for="inputPassword4"
                                                            className="form-label m-0"
                                                          >
                                                            Preferred pick-up date
                                                          </label>
                                                          <input
                                                            type="date"
                                                            className="form-control"
                                                            id="inputPassword4"
                                                            name="pickup_Date"
                                                            value={pick.pickup_Date}
                                                            onChange={(e) =>
                                                              handleChangepick(e)
                                                            }
                                                            min={getCurrentDate()}
                                                          />
                                                        </div>
                                                      </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                      {!isMOdalClose && (
                                                        <>
                                                          <button
                                                            type="button"
                                                            className="btn text-start address-modal"
                                                            onClick={() => {
                                                              if (
                                                                pick.pickup_firstName !==
                                                                "" &&
                                                                pick.pickup_lastName !==
                                                                "" &&
                                                                pick.pickup_email !==
                                                                "" &&
                                                                pick.pickup_Date !==
                                                                ""
                                                              ) {
                                                                // All fields are filled, close the modal using Bootstrap modal API
                                                                setisMOdalClose(true);
                                                                setWereHousePickup(
                                                                  werehouse
                                                                );
                                                                
                                                                

                                                             

                                                              } else {
                                                                toast.error(
                                                                  "Please fill required fields"
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            Save
                                                          </button>
                                                        </>
                                                      )}
                                                      {isMOdalClose && (
                                                        <>
                                                          <button
                                                          // ref={continueButtonRef} // Use React ref to reference this button
                                                            type="button"
                                                            id="myButton"
                                                            className="btn text-start address-modal"
                                                            data-bs-dismiss="modal"
                                                          >
                                                            Continue
                                                          </button>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* ********************************************Pickup MOdala End************************************************ */}
                                            </div>
                                            </>
                                          // )
                                        }
                                      {/* ********Scheduled Delivery************* */}
                                      <div className=" col-md-6 col-lg-6 col-xl-6 pt-4">
                                        {" "}
                                        <div className="paracel">
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h6>Scheduled Delivery</h6>
                                            <input
                                              type="checkbox"
                                              checked={
                                                item.is_werehousePickup ===
                                                false
                                              }
                                              onChange={(e) => {
                                                setHomeDelewary(item._id);
                                              }}
                                            />
                                          </div>

                                          <p className="m-0">
                                            {" "}
                                            Parcel shipping service.
                                          </p>
                                          <p>3 - 5 business days </p>
                                          <h6 className=" m-0">
                                            CA${item.deliveryCharge}.00
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}

                      <div class="Place-order mt-3">
                        <a
                          class="btn-hover btn-default"
                          href="#"
                          onClick={(e) => {
                            Payment();
                          }}
                        >
                          Continue to Payment
                        </a>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {/* *************************************************DelivaryOption End*************************************************************** */}
                </div>
              </div>
              <div className="para">
                {showContactInfo ? (
                  <>
                    <p>Shipping address</p>
                    <hr></hr>
                  </>
                ) : (
                  ""
                )}
                {!showDelivaryOption && (
                  <>
                    <p>Delivery options</p>
                    <hr></hr>
                  </>
                )}
              </div>
            </div>

            {/* *******************************************************************SUmmAry Card**************************************** */}
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-md-30px mt-lm-30px  mb-50">
              <div className="your-order-area  ">
                <div className="your-order-wrap gray-bg-4 pb-3">
                  {/* <h3>Quote Summary</h3> */}
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>Quote Summary</li>
                      </ul>
                    </div>
                    {cart.map((item, index) => {
                      return (
                        <>
                          <div className="your-order-middle">
                            <ul className="prodNaming">
                              <li>
                                <div className="row">
                                  <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-3">
                                    <div className="quoteSummaryImage">
                                      <img
                                        src={item.img}
                                        alt=""
                                        className="img-fluid prodImgForCheckout"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-9 col-sm-9 col-md-10 col-lg-9 col-xl-9">
                                    <p className="text-start">
                                      {" "}
                                      {item.ProductName}
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <span className="order-middle-left">
                                  Qty:{" "}
                                  {item.addTenPer
                                    ? item.boxCovrageWithTenPer
                                    : item.boxCovrage}
                                    {!includesExcludedCategory(item.ProductName) && (
                      <>
                                            sq. ft.
                                            </>
                                        )}
                                  
                                  {" "}
                                </span>{" "}
                                <span className="order-price">
                                  ${formatPrice(item.total)}
                                </span>{" "}
                              </li>
                            </ul>
                          </div>
                        </>
                      );
                    })}

                    <div className="your-order-middle">
                      <ul>
                        <li>
                          <span className="order-middle-left">Sub Total</span>{" "}
                          <span className="order-price">
                            ${formatPrice(Sub_Total)}{" "}
                          </span>{" "}
                        </li>
                        {totalDeliveryCharge > 0 && (
                          <li>
                              <span className="order-middle-left">Delivery Charge</span>
                              <span className="order-price">${formatPrice(totalDeliveryCharge)}</span>
                          </li>
                        )}
          
                        <li>
                          {GST != 0 && (
                            <>
                              <span className="order-middle-left ">GST </span>{" "}
                              <span className="order-price ">
                                {" "}
                                ${formatPrice(GST)}
                              </span>
                            </>
                          )}
                        </li>

                        <li>
                          {HST !== 0 && (
                            <>
                              <span className="order-middle-left">HST </span>{" "}
                              <span className="order-price">
                                {" "}
                                ${formatPrice(HST)}
                              </span>
                            </>
                          )}
                        </li>

                        <li>
                          {PST !== 0 && (
                            <>
                              <span className="order-middle-left">PST </span>{" "}
                              <span className="order-price">
                                {" "}
                                ${formatPrice(PST)}
                              </span>
                            </>
                          )}
                        </li>
                        <li>
                          {QST !== 0 && (
                            <>
                              <span className="order-middle-left">QST </span>{" "}
                              <span className="order-price">
                                {" "}
                                ${formatPrice(QST)}
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="your-order-top mt-3">
                      <ul>
                        <li>
                          <span className="order-middle-left">Total</span>{" "}
                        </li>
                        <li>
                          {" "}
                          <span className="order-price">
                            {" "}
                            <b>${formatPrice(Sub_Total+totalDeliveryCharge)}</b>
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="Place-order mt-25">
                  <a className="btn-hover" href="#">
                    Submit
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckoutContact;
