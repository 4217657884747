import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrderDetails from "./OrderDetails";

function OrderHistory() {
  const Navigate = useNavigate();
  const [data, setData] = useState([]);
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showOrder, setshowOrder] = useState(false);
  const [orderId, setOrderId] = useState();
  const itemsPerPage = 5;

  const [refresh, setRefresh] = useState(false);
  

  useEffect(() => {
    if (userData == null || userData === undefined || userData == "") {
      Navigate("/login");
    } else {
      axios
        .get(`https://allinonetables.com/api/order/user/${userData.user.email}`)
        .then((data) => {
          console.log(111, data.data);
          const revData = data.data.reverse();
          setData(revData);
          setTotalPages(Math.ceil(revData.length / itemsPerPage));
        })
        .catch((Err) => {
          console.log(Err);
        });
      if (userData == null || userData === undefined || userData == "") {
        Navigate("/login");
      }
    }
  }, []);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const getDate = (str) => {
    const date = new Date(str);

    // Options for formatting the date
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Format the date using the options
    const formattedDate = date.toLocaleDateString(undefined, options);

    return formattedDate;
  };

  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }
  console.log(81, data)
  const renderOrders = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, data.length);
    return data.slice(startIndex, endIndex).map((item) => (
      <>
        <div className="favoriteHeadings mt-4">
          <div className="row p-2">
            <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
              <h6 className="m-0">
                <b>Order Number</b>
              </h6>
              <p className="m-0 text-break">{item.orderId}</p>
            </div>
            <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
              <h6 className="m-0">
                <b>Placed On</b>
              </h6>
              <p className="m-0">{getDate(item.createdAt)}</p>
            </div>
            <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
              <h6 className="m-0">
                <b>Order Status</b>
              </h6>
              <p className="m-0 OrderStatusComplete">Completed</p>
            </div>
            <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
              <h6 className="m-0">
                <b>Total Amount</b>
              </h6>
              <p className="m-0 text-break">CAD ${formatPrice(item.totalPrice)}</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className="m-0 orderHistorybtn float-end">
                <button className="p-2 viewOrderDetailBtn " onClick={(e) => {
                  setOrderId(item._id);
                  setshowOrder(true)
                }}>
                  View Order Details
                </button>
              </div>
            </div>
          </div>
        </div>
        {item.orderItems.map((cart) => (
          <>
            <div className="orderHistoryRow border">
              <div className="row pt-2 pb-2 d-flex align-items-lg-center ">
                <div className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 ">
                  <div className="productImg">
                    <img
                      src={cart.img}
                      className="w-100 border rounded h-100"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-9 col-sm-6 col-md-6 col-lg-6 col-xl-8">
                  <div className="ProductName">
                    <h6 className="m-0">{cart.ProductName}</h6>
                    <p className="mb-1">SKU:{cart.SKU}</p>
                    <div className="shipped-pending">
                      <p className="m-0 shipped">Shipped</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-3 col-md-4 col-lg-4 col-xl-2">
                  <div className="OrderHistoryprice-total">
                    <h6 className="m-0 myColor">
                      {" "}
                      <b>CA${formatPrice(cart.sellPrice)} / each</b>
                    </h6>
                    <p className="m-0">
                      <b>Qty :{cart.boxQuantity}</b>
                    </p>
                    <p className="mb-2  ">
                      <b>Total : CA${formatPrice(cart.total)}</b>
                    </p>
                    <div className="">
                      <Link
                        to={`/productdetail/${cart.slug}`}
                        className="orderHistoryBuyAgainbtn"
                      >
                        Buy Again
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </>
    ));
  };

  return (
    <>
      {/* <div
        className="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h4 className="offcanvas-title" id="offcanvasExampleLabel">
            <i className="bi bi-funnel-fill pe-2 fs-4 myColor"></i>
            <b>Filters</b>
          </h4>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <hr className="m-0" />
        <div className="offcanvas-body">

          <div className="forShippingStatus">
            <h6>
              <b>FILTER BY DELIVERY STATUS</b>
            </h6>
            <ul className="fs-6 fw-medium">
              <li className="mb-2">
                <input type="radio" className="me-3 fs-3" />
                All Statuses
              </li>
              <li className="mb-2">
                <input type="radio" className="me-3" />
                Not Yet Shipped
              </li>
              <li className="mb-2">
                <input type="radio" className="me-3" />
                Partially Shipped
              </li>
              <li>
                <input type="radio" className="me-3" />
                Shipped
              </li>
            </ul>
          </div>
 
          <hr />
   
          <div className="forShippingStatus">
            <h6>
              <b>FILTER BY PAYMENT STATUS</b>
            </h6>
            <ul className="fs-6 fw-medium">
              <li className="mb-2">
                <input type="radio" className="me-3 fs-3" />
                All Statuses
              </li>
              <li className="mb-2">
                <input type="radio" className="me-3" />
                Unpaid
              </li>
              <li className="mb-2">
                <input type="radio" className="me-3" />
                Partially Paid
              </li>
              <li>
                <input type="radio" className="me-3" />
                Paid
              </li>
            </ul>
          </div>
        </div>
        <div className="offcanvas-footer">
          <hr className="m-0" />
          <div className="float-end p-2">
            {" "}
            <button
              type="button"
              class="btn btn-secondary me-2"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Close
            </button>
            <button
              type="button"
              class="btn mybgColor text-white confirm"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Apply
            </button>
          </div>
        </div>
      </div> */}
      {
        showOrder ? (<>


          <OrderDetails id={orderId} />
          
          <button className="ViewOrder-buttton text-white " onClick={(e) => {
            setshowOrder(false)
            setOrderId("")
          }}>
            back
          </button>


        </>) : (<>

          <div className="row orderHistoryRow">
            <h3 className="text-start">Order History & Status</h3>
            <p className="border-bottom">
              Keep track of all your orders and their status.
            </p>
          </div>

          <div className="input-group mb-3 p-0">
            <input
              type="text"
              className=" bi bi-search  form-control p-3"
              placeholder="Search All Orders Here"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <span
              className="input-group-text bi bi-funnel FilterForShip"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <span className="ps-1 cursor-pointer">Filter</span>
            </span>
          </div>
          {renderOrders()}
          <div className=" FavoriteBottom ">
            <div className="row p-2">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="myProfilePagination">
                  <nav aria-label="Page navigation example .d-sm-none .d-md-block">
                    <ul className="pagination justify-content-end mt-4">
                      <li
                        className={`page-item ${currentPage === 1 ? "disabled" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          style={{
                            backgroundColor: "#f8f9fa", // Change this to your desired background color
                            color: "black",
                            border: "1px solid #dee2e6" // Example border style
                            
                          }}
                          onClick={() => handlePageClick(currentPage - 1)}
                          disabled={currentPage === 1}
                          aria-label="Previous"
                        >
                          Previous
                        </button>
                      </li>
                      <li className="page-item">
  <span className="page-link">
    {totalPages > 1 ? `Page ${currentPage} of ${totalPages}` : `${currentPage}`}
  </span>
</li>

                      <li
                        className={`page-item ${currentPage === totalPages ? "disabled" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          style={{
                            backgroundColor: "#f8f9fa", // Match background color with previous button
                            color: "black",
                            border: "1px solid #dee2e6" // Match border style with previous button
                          }}
                          onClick={() => handlePageClick(currentPage + 1)}
                          disabled={currentPage === totalPages}
                          aria-label="Next"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>)
      }


    </>
  );
}

export default OrderHistory;
