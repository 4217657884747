import React from 'react';

const ShippingOptions = ({ deliveryLocation, shippingOptions }) => {
  return (
    <div className="container mt-4 p-0">
      <div className="shipping-header mb-3">
        <h5><i class="bi bi-truck me-1"></i>Shipping Options</h5>
        <p>
          Delivery to: <a href="#">{deliveryLocation}</a>
        </p>
      </div>
      <div className="row">
        {shippingOptions.map((option, index) => (
          <div className="col-md-6 mb-3 product-image-container h-auto" key={index} data-tooltip={option.shippingOptionInfo}>
            <div className="card shadow-sm">
              <div className="card-body text-center p-3">
                <h5 className="card-title">{option.title}</h5>
                <p className="card-text">{option.location}</p>
                <p className="card-text">{option.time}</p>
                <p className="card-text fw-bold">
                  {option.price === 'FREE' ? 'FREE' : `${option.currencySymbol} ${option.price}`}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShippingOptions;
