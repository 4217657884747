import React, { useState, useEffect, useRef } from "react";
import "./InstallationCostIstimation.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Product from "../flooring/Product";

const InstallationCostEstimation = () => {
  const navigate = useNavigate();
  const uniqueIdentifier = localStorage.getItem("uniqueId");
  const [showInstallationTab, setshowInstallationTab] = useState(true);
  const [totalsqfeet, setTotalsqfeet] = useState(0);
  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [CostData, setCostData] = useState();
  const [remove_dis, setremove_dis] = useState([]);
  const [FloorPrepration, setFloorPrepration] = useState();
  const [inData, setinData] = useState();
  const [removeSingleData, setRemoveSingleData] = useState([]);
  const [floorPreSingleData, setfloorPreSingleData] = useState([]);
  const [installArr, setInstallArr] = useState([]);
  const [installSingleArr, setInstallSingleArr] = useState();
  const [removalTotal, setRemovalTotal] = useState(0);
  const [floorPrepTotal, setfloorPrepTotal] = useState(0);
  const [installTotal, setInstallTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [showFootTotal, setShowFootTotal] = useState(true);
  const [One, setOne] = useState(true);
  const [Two, setTwo] = useState(false);
  const [Three, setThree] = useState(false);
  const [show_tab, setshow_tab] = useState(true);
  const [orderItems, setOrderItems] = useState([]);
  const [removeDisIndex, setremoveDisIndex] = useState(0);
  const [FloorPremIndex, setFloorPremIndex] = useState(0);
  const [instalationIndex, setinstalationIndex] = useState(0);
  const [remove, setremove] = useState("");
  const [FloorPre, setFloorPre] = useState("");
  const [instalation, setinstalation] = useState("");
  const [termsCheck, setTermsCheck] = useState(false);

  const [data, setData] = useState({
    identifier: uniqueIdentifier,
    firstName: "",
    lastName: "",
    city: "",
    orderItems: [],
    postalCode: "",
    mNo: "",
    email: "",
    totalPrice: "",
    totalSquareFeet: "",
    isComplete: false,
  });

  const formatNumber = (value) => {
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) {
      // console.error("Invalid number");
      return null;
    }
    const hasDecimal = floatValue % 1 !== 0;
    const formattedValue = hasDecimal
      ? floatValue.toString()
      : floatValue.toFixed(2);
    return formattedValue;
  };

  function generateUniqueIdentifier() {
    let uniqueIdentifier = 0;
    const myStoredValue = localStorage.getItem("uniqueId");
    if (myStoredValue !== null) {
      uniqueIdentifier = localStorage.getItem("uniqueId");
    } else {
      const currentDatetime = new Date();
      const formattedDatetime = `${currentDatetime.getFullYear()}${(
        currentDatetime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getDate()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDatetime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const randomCode = Math.floor(Math.random() * 90 + 10);
      uniqueIdentifier = `${formattedDatetime}${randomCode}`;
      localStorage.setItem("uniqueId", uniqueIdentifier);
    }

    return uniqueIdentifier;
  }

  useEffect(() => {
    axios
      .get("https://allinonetables.com/api/cost/get")
      .then((res) => {
        const response = res.data;
        setCostData(response);
        const rem_dis = response.filter(
          (item) => item.name == "Removal & Disposal"
        );
        setremove_dis(rem_dis);
        console.log(16, rem_dis);
        const Floor_Prepration = response.filter(
          (item) => item.name == "Floor Prepration"
        );
        setFloorPrepration(Floor_Prepration);
        const installData = response.filter(
          (item) =>
            item.name != "Floor Prepration" && item.name != "Removal & Disposal"
        );
        setinData(installData);
        // console.log(installData)
      })
      .catch((err) => console.log(err));

    generateUniqueIdentifier();
  }, []);

  useEffect(() => {
    let removeArrTotal = 0;
    let floorprepArrTotal = 0;
    let installArrTotal = 0;
    floorPreSingleData.forEach((item) => {
      floorprepArrTotal += item.totalPrice;
    });
    removeSingleData.forEach((item) => {
      removeArrTotal += item.totalPrice;
    });
    installArr.forEach((item) => {
      installArrTotal += item.total;
    });
    setTotal(
      formatNumber(removeArrTotal + floorprepArrTotal + installArrTotal)
    );
    setData({
      ...data,
      totalPrice: removeArrTotal + floorprepArrTotal + installArrTotal,
    });
    console.log(
      286,
      installArr.every((item) => item.total !== 0)
    );
    console.log(286, installArr);
  }, [floorPreSingleData, removeSingleData, installArr]);

  const getSingleCosts = (id) => {
    axios
      .get(`https://allinonetables.com/api/cost/get/single/${id}`)
      .then((res) => {
        const response = res.data;
        if (response[0].parentName === "Removal & Disposal") {
          setremove("");
          let isObjectPresent = removeSingleData.includes(response[0]);
          if (isObjectPresent) {
            toast.error("Alredy Present..");
            console.log(162, "present");
          } else {
            setRemoveSingleData((prevData) => [
              ...prevData,
              { ...response[0], totalPrice: response[0].price * totalsqfeet },
            ]);
          }
        } else {
          setfloorPreSingleData((prevData) => [
            ...prevData,
            { ...response[0], totalPrice: response[0].price * totalsqfeet },
          ]);
          setFloorPre("");
        }
      })
      .catch((err) => console.log(err));
  };
  const getSingleinstallCosts = (id) => {
    axios
      .get(`https://allinonetables.com/api/cost/get/${id}`)
      .then((res) => {
        const response = res.data;
        // Set the installation data including setting the default price
        if (response && response.length > 0) {
          const defaultItem = response[0]; // Assume the first item should be the default
          setInstallArr((prevData) => [
            ...prevData,
            {
              data: response,
              name: defaultItem.parentName,
              total: defaultItem.price * totalsqfeet, // Calculate total based on default item
              singleprice: defaultItem.price,
              selectedChild: defaultItem.name,
            },
          ]);
        }
        setinstalation("");
      })
      .catch((err) => console.log(err));
};


  const PlaceOrder = () => {
    console.log(197, data);
    const obj = {
      data: {
        ...data,
        totalPrice: total,
        orderItems: [
          ...data.orderItems,
          { Removal_Disposal: removeSingleData },
          { floorPreparation: floorPreSingleData },
          { installation: installArr },
        ],
      },
    };
    axios
      .post("https://allinonetables.com/api/InstallationOrder/create", obj.data)
      .then(() => {
        toast.success("Order Place Successfully...");
        navigate("/Installation");
      })
      .catch((err) => console.log(err));
  };
  const UpdateInstallObject = (id, totalsqft, parentIndex) => {
    const OneChield = installArr[parentIndex];
    const filter = OneChield.data.find((item) => item._id === id);
    const updatedArr = {
      ...OneChield,
      singleprice: filter.price,
      total: filter.price * totalsqft,
      selctedChield: filter.name,
    };
    const newArray = [...installArr];
    newArray[parentIndex] = updatedArr;
    setInstallArr(newArray);
  };
  const removeObj = (type, index) => {
    if (type == "Removal_Disposal") {
      const AllArr = [...removeSingleData];
      AllArr.splice(index, 1);
      setRemoveSingleData(AllArr);
    }
    if (type == "floorPrepration") {
      const AllArr = [...floorPreSingleData];
      AllArr.splice(index, 1);
      setfloorPreSingleData(AllArr);
    }
    if (type == "installation") {
      const AllArr = [...installArr];
      AllArr.splice(index, 1);
      setInstallArr(AllArr);
    }
  };
  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }

  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  // Function to toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Function to sort items by sale price
  const sortItems = () => {
    const sortedItems = [...Product].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.salePrice - b.salePrice;
      } else {
        return b.salePrice - a.salePrice;
      }
    });

  };
  return (
    <>
      {/* ****************************** term condition ************************ */}
      <div
        className="modal fade "
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Terms& Conditions
              </h1>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque
                exercitationem at ducimus voluptates, tempora similique? Nisi
                veritatis velit doloremque nostrum, quam odit. Ullam aspernatur
                molestias facilis officiis ipsum fugiat animi dolore pariatur
                autem. Quia dolorum accusamus illo a modi molestias quam
                exercitationem, itaque incidunt, nisi sunt quis consequuntur
                similique nostrum aliquid eaque quos porro eligendi, obcaecati
                pariatur minus tempora! Non earum magni in voluptatum iure
                perferendis sit dolore obcaecati quam ea! Sed assumenda
                doloremque voluptatum exercitationem tenetur ratione quidem
                quibusdam, consequuntur et magni placeat veniam esse laboriosam
                nihil eos, aliquam pariatur odit. Veritatis officia explicabo
                aperiam laudantium. Neque voluptatibus vero harum delectus
                accusamus architecto, tempore distinctio quibusdam veniam nulla
                esse quis labore minima at. Provident harum temporibus
                voluptates suscipit reprehenderit, minima nihil voluptatibus,
                asperiores ratione laborum commodi quam mollitia delectus quod,
                ea sint necessitatibus iusto repellat explicabo aperiam
                voluptate.
              </p>
              <h6>Order Processing</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Deserunt, corrupti enim tempore iure explicabo, impedit sed non
                totam maiores, consectetur qui sequi provident quidem culpa
                quisquam nihil sapiente. Ducimus harum beatae iusto esse ipsum
                pariatur quae sed cupiditate sunt molestiae.
              </p>
              <h6>Quotes:</h6>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit,
                dignissimos!
                <h6>Delivery Times:</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Perspiciatis vel ab ut in quidem. Ducimus explicabo minima
                  beatae blanditiis aut.
                </p>
                <h6>Inspection of Material:</h6>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Atque commodi corrupti tempore id voluptate voluptatum tempora
                  fugit ut quasi enim.
                </p>
                <h6>Currenct:</h6>
                <p>Lorem ipsum dolor sit.</p>
                <h6>Recieving Material:</h6>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
                  quasi autem, aliquam iusto ratione officia unde molestiae
                  earum doloremque praesentium aliquid dolorum, facilis
                  voluptatem perferendis quas consectetur voluptatum? Quisquam,
                  est?
                </p>
                <p>
                  FlooringDeals.ca <br />
                  700 Dundas Street East, <br />
                  Unit 4 L4Y 3Y5 Mississauga, Ontario <br />
                  Phone: (905) 277-2227 <br />
                  Fax : (905) 277-0227 <br />
                </p>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ============ Modal Remove and Disposal============ */}
      <div
        class="modal"
        tabindex="-1"
        id="exampleModal1"
        aria-labelledby="exampleModalLabel"
      >
        <div class="modal-dialog modal-dialog-centered justify-content-center">
          <div class="modal-content remove-box">
            <div className="row justify-content-center ">
              <div className="col-8 col-md-11 p-2 p-md-4 ">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i class="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the selected item?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      onClick={(e) => {
                        setRemovalTotal(0);
                        removeObj("Removal_Disposal", removeDisIndex);
                      }}
                      data-bs-dismiss="modal"
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ Modal floor preparation============ */}
      <div
        class="modal"
        tabindex="-1"
        id="exampleModal2"
        aria-labelledby="exampleModalLabel"
      >
        <div class="modal-dialog modal-dialog-centered justify-content-center">
          <div class="modal-content remove-box">
            <div className="row justify-content-center ">
              <div className="col-8 col-md-11 p-2 p-md-4 ">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i class="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the selected item?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      onClick={(e) => {
                        setfloorPreSingleData("");
                        setfloorPrepTotal(0);
                        removeObj("floorPrepration", FloorPremIndex);
                      }}
                      data-bs-dismiss="modal"
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ Modal Installation============ */}
      <div
        class="modal"
        tabindex="-1"
        id="exampleModal3"
        aria-labelledby="exampleModalLabel"
      >
        <div class="modal-dialog modal-dialog-centered justify-content-center">
          <div class="modal-content remove-box">
            <div className="row justify-content-center ">
              <div className="col-8 col-md-11 p-2 p-md-4 ">
                <div className="row py-4 justify-content-between align-items-center">
                  <div className="col-2">
                    <i class="bi bi-exclamation-circle text-danger fs-1"></i>
                  </div>
                  <div className="col-10">
                    Are you sure that you want to remove the selected item?
                  </div>
                </div>
                <div className="row justify-content-between align-items-center py-3">
                  <div className="col-6">
                    <button
                      className="w-100 p-1 text-white yes-remove"
                      onClick={(e) => {
                        removeObj("installation", instalationIndex);
                      }}
                      data-bs-dismiss="modal"
                    >
                      Yes, Remove
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 p-1 yes-cancel"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-4 py-4">
        <div className="back-option">
          <Link to="/">
            <i class="fa fa-arrow-left"></i> <u>Back</u>
          </Link>
        </div>
      </div>

      <div className="container ptb-50">
        <div className="row">
        <div className="col-lg-12 px-5">
<div className="col-lg-12 px-5">
  
  {/* <p>{uniqueIdentifier}</p> */}
</div>
        {/* <p>{uniqueIdentifier}</p> */}
      </div>

          <div className="col-lg-12 px-5">
            <h4>Create a New Installation Cost Estimation</h4>
            <p><strong>Disclaimer:</strong> All prices estimated here are subject to change without prior notice.</p>
          </div>
        </div>

        <div className="row ">
          
          <div className="step-wizard">
            
            <ul className="step-wizard-list">
              <li className="step-wizard-item">
                <span className="progress-count1">
                  <span
                    className="one text-center"
                    style={{ backgroundColor: One ? "#f16f1e" : "#ffe3d2" }}
                  >
                    1
                  </span>
                </span>
                <span
                  className="progress-label1"
                  style={{
                    fontWeight: One ? "700" : "500",
                    color: One ? "#000" : "#7f7f7f",
                  }}
                >
                  Area to be estimated
                </span>
              </li>
              <li className="step-wizard-item">
                <span className="progress-count2">
                  <span
                    className="two text-center"
                    style={{ backgroundColor: Two ? "#f16f1e" : "#ffe3d2" }}
                  >
                    2
                  </span>
                </span>
                <span
                  className="progress-label2"
                  style={{
                    fontWeight: Two ? "700" : "500",
                    color: Two ? "#000" : "#7f7f7f",
                  }}
                >
                  Add Labour Items
                </span>
              </li>
              <li className="step-wizard-item">
                <span className="progress-count3">
                  <span
                    className="three text-center"
                    style={{ backgroundColor: Three ? "#f16f1e" : "#ffe3d2" }}
                  >
                    3
                  </span>
                </span>
                <span
                  className="progress-label3"
                  style={{
                    fontWeight: Three ? "700" : "500",
                    color: Three ? "#000" : "#7f7f7f",
                  }}
                >
                  Preview & Save
                </span>
              </li>
            </ul>
          </div>
        </div>
        {showInstallationTab ? (
          <>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-3 col-lg-3"></div>
              <div className="col-sm-12 col-md-6 col-lg-5">
                <div className="center-area">
                  <ul className="cost-estimation-tab">
                    <li className="nav-item">
                      <a
                        className="active nav-link room-area-btn"
                        aria-current="page"
                        onClick={(e) => setshow_tab(true)}
                        style={{ backgroundColor: show_tab ? "#e6e6e6" : "" }}
                      >
                        Room area
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link room-area-btn"
                        id="contact-tab-pane"
                        onClick={(e) => setshow_tab(false)}
                        style={{ backgroundColor: show_tab ? "" : "#e6e6e6" }}
                      >
                        Room dimensions
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {show_tab ? (
                      <>
                        <div className="p-4 pt-5 mb-3 sq-ft-txt">
                          <div className="add-room-area">
                            <input
                              type="number"
                              min="0"
                              className="form-control cost-installation-room-area"
                              id="exampleFormControlInput1"
                              placeholder="Enter room area"
                              value={totalsqfeet}
                              onChange={(e) => {
                                setTotalsqfeet(e.target.value);
                                setData({
                                  ...data,
                                  totalSquareFeet: e.target.value,
                                });
                              }}
                            />
                            <p className="m-0 room-area-sq-ft">Sq.Ft.</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row wid-len p-4">
                          <div className="col-sm-5 col-md-5 col-lg-5 room-width">
                            <div className="add-room-area">
                              <input
                                type="number"
                                min="0"
                                className="form-control cost-installation-room-area"
                                id="exampleFormControlInput1"
                                placeholder=" Room width"
                                onChange={(e) => setWidth(e.target.value)}
                              />
                              <p className="m-0 room-area-sq-ft">Ft.</p>
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2 col-lg-2 into-sign">
                            X
                          </div>
                          <div className=" col-sm-5 col-md-5 col-lg-5 room-length">
                            <div className="add-room-area">
                              <input
                                type="number"
                                min="0"
                                className="form-control cost-installation-room-area"
                                id="exampleFormControlInput1"
                                placeholder="Room length"
                                onChange={(e) => {
                                  setLength(e.target.value);
                                  setTotalsqfeet(e.target.value * width);
                                }}
                              />
                              <p className="m-0 room-area-sq-ft">Ft.</p>
                            </div>
                          </div>
                          <h6 className="pt-5">Total : {totalsqfeet}</h6>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3"></div>
            </div>
            <div className="row justify-content-center">
              <button
                className="installtion-buttton text-center w-25 text-white"
                onClick={(e) => {
                  if (totalsqfeet == 0) {
                    console.log(totalsqfeet);
                    toast.error("Field cannot be blank.");
                  } else {
                    setOne(false);
                    setTwo(true);
                    setThree(false);
                    setshowInstallationTab(false);
                    // resetInputValue();
                  }
                }}
              >
                {" "}
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            {/* ************************ next tab **************** */}
            <div className="row add-labour-items justify-content-center">
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-removal-disposal w-100 text-dark"
                  value={remove}
                  onChange={(e) => {
                    getSingleCosts(e.target.value);
                    setShowFootTotal(false);
                    setremove(e.target.value);
                    setShowForm(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Removal & Disposal</option>
                  {remove_dis[0]?.children.map((item) => {
                    return (
                      <>
                        <option value={item._id}>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-floor-preparation w-100"
                  value={FloorPre}
                  onChange={(e) => {
                    getSingleCosts(e.target.value);
                    setShowFootTotal(false);
                    setFloorPre(e.target.value);
                    setShowForm(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Floor Preparation</option>
                  {FloorPrepration[0]?.children.map((item) => {
                    return (
                      <>
                        <option value={item._id}>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="col-11 col-md-3 col-lg-2 my-2 my-md-0">
                <select
                  className="add-installation w-100"
                  value={instalation}
                  onChange={(e) => {
                    getSingleinstallCosts(e.target.value);
                    setShowFootTotal(false);
                    setinstalation(e.target.value);
                    setShowForm(false);
                  }}
                  style={{ color: `inherit` }}
                >
                  <option value="">Add Installation</option>
                  {inData.map((item) => {
                    return (
                      <>
                        <option value={item._id}>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>

            {floorPreSingleData.map((item, index) => {
              return (
                <>
                  <div className="row my-4">
                    <div className="col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 text-center text-md-start add-selection-name">
                          <p>Floor Preparation</p>
                        </div>
                        <div className="col-md-5 col-lg-5 name-removal-disposal">
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.price}/ sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.totalPrice)}</strong>
                          </p>
                        </div>
                        <div className="col-md-2 ">
                          <div className="row justify-content-center">
                            {showForm ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="col-7 col-md-9 my-3 my-md-0"
                                  onClick={(e) => {
                                    setFloorPremIndex(index);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal2"
                                  style={{ color: `inherit` }}
                                >
                                  Remove
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {removeSingleData?.map((item, index) => {
              return (
                <>
                  <div className="row my-4">
                    <div className=" col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 col-lg-2 text-center text-md-start add-selection-name">
                          <p>Removal & Disposal</p>
                        </div>
                        <div className="col-md-5 col-lg-5 name-removal-disposal">
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.price} / sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.totalPrice)}</strong>
                          </p>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="row justify-content-center">
                            {showForm ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="col-7 col-md-9 my-3 my-md-0"
                                  onClick={(e) => {
                                    setremoveDisIndex(index);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal1"
                                  style={{ color: `inherit` }}
                                >
                                  Remove
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {installArr.map((item, index) => {
              return (
                <>
                  <div className="row my-4">
                    <div className="col-10 col-md-12 col-lg-11 m-auto removal-disposal">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-2 col-lg-2 text-center text-md-start add-selection-name">
                          <p>Installation</p>
                        </div>
                        <div className="col-md-3 col-lg-3  text-center text-md-start">
                          <p className="product-title-name">
                            {item.name}
                            {"   "}
                            {item.selctedChield ? item.selctedChield : ""}
                          </p>
                        </div>

                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center add-installation-product">
                            <select
                              className="w-100 w-sm-75 "
                              onChange={(e) => {
                                const selected = e.target.value;
                                UpdateInstallObject(
                                  selected,
                                  totalsqfeet,
                                  index
                                );
                              }}
                            >
                              {item.data?.map((item, indexx) => {
                                return (
                                  <>
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <p>${item.singleprice}/ sq.ft &nbsp;</p>
                            <span className="">{totalsqfeet} sq. ft</span>
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 text-center text-md-end">
                          <p>
                            <strong>${formatPrice(item.total)}</strong>
                          </p>
                        </div>

                        <div className="col-md-2 col-lg-2">
                          <div className="row justify-content-center">
                            {showForm ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="col-7 col-md-9 my-3 my-md-0"
                                  onClick={(e) => {
                                    setinstalationIndex(index);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal3"
                                  style={{ color: `inherit` }}
                                >
                                  Remove
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {showFootTotal ? (
              <>
                <p class="text-center pt-5 pb-5 ">
                  No labour item added yet. Please Add from Above Option
                </p>
              </>
            ) : (
              <>
                <div className="row my-4">
                  <div className="col-11 col-md-10 m-auto">
                    <div className="row dislamer-total-price">
                      <div className="col-md-8 product-disclamer">
                        <p>
                          <strong>Disclamer:</strong>&nbsp;All prices estimated
                          here are subject to change without prior notice. While
                          we make every effort to provide you with the most
                          accurate and up-to-date information, it may be
                          possible that one or more items may be incorrectly
                          priced.
                        </p>
                      </div>
                      <div className="col-1 d-none d-md-block"></div>
                      <div className="col-md-1 total-price-cost-estimation-name">
                        <p>
                          <strong>Total</strong>
                        </p>
                      </div>
                      <div className="col-md-2 total-price-cost-estimation">
                        <p>
                          <strong>${formatPrice(parseInt(total))}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!showForm && (
              <>
                <div className="row justify-content-center align-items-center m-auto">
                  <div className="col-sm-4 text-center my-2 my-md-0">
                    <div className="row prev-next-button justify-content-center">
                      <button
                        className="col-11 w-75 text-white text-center"
                        onClick={(e) => {
                          setshowInstallationTab(true);
                          setRemoveSingleData([]);
                          setInstallArr([]);
                          setfloorPreSingleData([]);
                          setOne(true);
                          setTwo(false);
                          setThree(false);
                        }}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-4 text-center my-2 my-md-0">
                    <div className="row prev-next-button justify-content-center justify-content-md-start">
                      <button
                        disabled={
                          total != 0 &&
                          installArr.every((item) => item.total !== 0)
                            ? false
                            : true
                        }
                        style={{
                          backgroundColor:
                            total != 0 &&
                            installArr.every((item) => item.total !== 0)
                              ? false
                              : "gray",
                        }}
                        className="col-11 text-white w-75"
                        onClick={(e) => {
                          if (
                            total != 0 &&
                            installArr.every((item) => item.total !== 0)
                          ) {
                            setShowForm(true);
                            setOne(false);
                            setTwo(false);
                            setThree(true);
                          } else {
                            toast.error("Please Select");
                          }
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {showForm && (
        <>
          <div className="checkout-area mb-90">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-3"></div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="billing-info-wrap">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="billing-info mb-20px">
                          <label>
                            Email Address <sup>Required</sup>
                          </label>
                          <input
                            type="email"
                            onChange={(e) =>
                              setData({ ...data, email: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="billing-info mb-20px">
                          <label>
                            First Name <sup>Required</sup>
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setData({ ...data, firstName: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="billing-info mb-20px">
                          <label>Last Name</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setData({ ...data, lastName: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="billing-info mt-2 mb-20px">
                          <label>
                            Phone <sup>Required</sup>
                          </label>
                          <input
                            type="number"
                            min="0"
                            onChange={(e) =>
                              setData({ ...data, mNo: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="billing-info mb-20px">
                          <label>
                            City <sup>Required</sup>
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setData({ ...data, city: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="billing-info mb-20px">
                          <label>Postal Code</label>
                          <sup>Required</sup>
                          <input
                            type="text"
                            onChange={(e) =>
                              setData({ ...data, postalCode: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="checkout-account mt-5">
                        <input
                          className="checkout-toggle w-auto h-auto me-2"
                          type="checkbox"
                          value={termsCheck}
                          onChange={(e) => setTermsCheck(!termsCheck)}
                        />
                        <label>
                          Yes, I have read and agreed on{" "}
                          <span
                            className="term-condition"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            <u>Terms and Conditions</u>
                          </span>
                        </label>
                        {/* <ConditionModal /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3"></div>
                <div className="row justify-content-center align-items-center m-auto mt-4">
                  <div className="col-sm-4  my-2 my-md-0">
                    <div className=" prev-next-button justify-content-center">
                      <button
                        className="col-11 text-white"
                        onClick={(e) => {
                          setShowForm(false);
                          setOne(false);
                          setTwo(true);
                          setThree(false);
                        }}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-4 my-2 my-md-0">
                    <div className=" prev-next-button justify-content-center justify-content-md-start position-relative">
                      <button
                        className="col-11 text-white"
                        onClick={(e) => {
                          if (!data.email || data.email === "") {
                            toast.error("Email Address cannot be blank.");
                          } else if (!data.firstName || data.firstName === "") {
                            toast.error(" Name cannot be blank.");
                          } else if (
                            !data.mNo ||
                            data.mNo === "" ||
                            isNaN(data.mNo) ||
                            data.mNo.length !== 10
                          ) {
                            toast.error(
                              "Please enter a valid 10-digit Phone No."
                            );
                          } else if (!data.city || data.city === "") {
                            toast.error("City cannot be blank.");
                          } else if (
                            !data.postalCode ||
                            data.postalCode === "" ||
                            isNaN(data.postalCode)
                          ) {
                            toast.error("Please enter a valid Postal Code.");
                          } else if (termsCheck == false) {
                            toast.error("Please Agree Terms And Condition");
                          } else {
                            PlaceOrder();
                          }
                        }}
                      >
                        Save Your Estimation
                      </button>
                    </div>
                    <div className="red-text">
                      <p className="text-danger">
                        If you save your estimation list, you can access any
                        time to review and edit again. We'll also send your link
                        to your email address.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InstallationCostEstimation;
