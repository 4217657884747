import React from "react";
import "./checkout.css";
import WarehouseModal from "./WarehouseModal";
import ConrfirmItsYou from "./ConrfirmItsYou";

function DeliveryOption() {
  return (
    <>
      {/* ************************ */}
      <div className="container">
        <div className="contactinfo">
          <h2>Shipping address</h2>
        </div>
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <div className="para">
              <p>Payment details</p>
              <hr></hr>
              <p>Review</p>
              <hr></hr>
            </div>
          </div>
          {/* ******************************* */}
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-md-30px mt-lm-30px  mb-50">
            <div className="your-order-area  ">
              <div className="your-order-wrap gray-bg-4 pb-3">
                <div className="your-order-product-info">
                  <div className="your-order-top">
                    <ul>
                      <li>Quote Summary</li>
                    </ul>
                  </div>

                  <div className="your-order-middle">
                    {/* ******************************************** */}
                    <ul className="prodNaming">
                      <li>
                        {/* ******** this row needs to go with the brands cause there service for shipping from warehouse is like different brnds have different
                        warehouse so if the 10 products from one brand can go in box */}
                        <div className="row">
                          <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-3">
                            <div className="ProdImg">
                              <img
                                src="assets/images/cost-esimation/2.jpg"
                                alt=""
                                className=" img-fluid rounded-circle prodImgForCheckout"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-sm-9 col-md-10 col-lg-9 col-xl-9">
                            <p className="text-start">
                              {" "}
                              BLauzon Expert Hardwood Decore Natural Fard Maple
                              Select and Better Ultra-Matte 3.25"
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <span className="order-middle-left">
                          Qty: 16sq. ft.{" "}
                        </span>{" "}
                        <span className="order-price">$985.75 </span>{" "}
                      </li>
                    </ul>
                    {/* **************************************** */}
                    <ul className="prodNaming">
                      <li>
                        {/* ******** this row needs to go with the brands cause there service for shipping from warehouse is like different brnds have different
                        warehouse so if the 10 products from one brand can go in box */}
                        <div className="row">
                          <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-3">
                            <div className="ProdImg">
                              <img
                                src="https://cdn.theFlooring Deals.ca/images/products/1e3a8de4-db7b-11eb-bd35-f6968cef729a/5cf32070-532f-11ec-bd35-f6968cef729a"
                                alt=""
                                className=" img-fluid rounded-circle prodImgForCheckout"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-sm-9 col-md-10 col-lg-9 col-xl-9">
                            <p className="text-start">
                              {" "}
                              Biyork Engineered Hardwood Nouveau 6 Breath of
                              Winter 6-1/2" - 3/4"
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <span className="order-middle-left">
                          Qty: 16sq. ft.{" "}
                        </span>{" "}
                        <span className="order-price">$985.75 </span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="your-order-middle">
                    <ul>
                      <li>
                        <span className="order-middle-left">Sub Total</span>{" "}
                        <span className="order-price">$1182.90 </span>{" "}
                      </li>
                      <li>
                        <span className="order-middle-left">Tax (13%) </span>{" "}
                        <span className="order-price"> $153.77</span>
                      </li>
                    </ul>
                  </div>
                  <div className="your-order-top mt-3">
                    <ul>
                      <li>
                        <span className="order-middle-left">Total</span>{" "}
                      </li>
                      <li>
                        {" "}
                        <span className="order-price">
                          {" "}
                          <b>$1366.67</b>
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeliveryOption;
